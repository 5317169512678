import React, { useEffect } from "react";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import "../../assets/css/Home/membershipLanding.css";
import ReactGA from 'react-ga';

function MembershipLanding() {
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  },[])

  return (
    <div className="home-container">
      <div className="banner-wrapper vh-md-100 layer-after app-shape">
        <Container>
          <Row>
            <Col
              xl={7}
              className="col-xxxl-7 vh-md-100 pt-7 pb-7 align-items-center d-flex"
            >
              <Card className="w-100 border-0 bg-transparent">
                <h1 className=" h1 fw-700 text-grey-900 mb-0">
                  Explore <br /> analyze about our{" "}
                  <span className="text-current">awesome</span> feature
                  <i className="feather-slack text-success font-xxl"></i>
                </h1>
                <div className="form-group mt-4 p-3 shadow-lg p-2 bg-white rounded-lg aos-init aos-animate">
                  <Row>
                    <Col lg={7}>
                      <div className="form-group icon-input mb-0">
                        <i className="ti-email font-xs text-grey-400"></i>
                        <input
                          type="text"
                          className="style1-input bg-transparent border-0 pl-5 font-xsss mb-0 text-grey-500 fw-500"
                          placeholder="Enter email address"
                        />
                      </div>
                    </Col>

                    <Col lg={5}>
                      <Button className="w-100 d-block btn bg-current text-white font-xssss fw-600 ls-3 style1-input p-0 border-0 text-uppercase ">
                        Download
                      </Button>
                    </Col>
                  </Row>
                </div>
                <ul className="memberlist mt-4 mb-2 ml-0 ">
                  <li className="w20">
                    <a href="/#">
                      <img
                        src="https://via.placeholder.com/40x40.png"
                        alt="user"
                        className="w40 d-inline-block"
                      />
                    </a>
                  </li>
                  <li className="w20">
                    <a href="/#">
                      <img
                        src="https://via.placeholder.com/40x40.png"
                        alt="user2"
                        className="w40 d-inline-block"
                      />
                    </a>
                  </li>
                  <li className="w20">
                    <a href="/#">
                      <img
                        src="https://via.placeholder.com/40x40.png"
                        alt="user3"
                        className="w40 d-inline-block"
                      />
                    </a>
                  </li>
                  <li className="w20">
                    <a href="/#">
                      <img
                        src="https://via.placeholder.com/40x40.png"
                        alt="user4"
                        className="w40 d-inline-block"
                      />
                    </a>
                  </li>
                  <li className="w20">
                    <a href="/#">
                      <img
                        src="https://via.placeholder.com/40x40.png"
                        alt="user5"
                        className="w40 d-inline-block"
                      />
                    </a>
                  </li>
                  <li className="w20">
                    <a href="/#">
                      <img
                        src="https://via.placeholder.com/40x40.png"
                        alt="user6"
                        className="w40 d-inline-block"
                      />
                    </a>
                  </li>

                  <li className="pl-4 w-auto">
                    <a href="/#" className="fw-500 text-grey-500 font-xssss">
                      Member already downloaded
                    </a>
                  </li>
                </ul>
              </Card>
            </Col>
            <Col
              xl={4}
              className="col-xxxl-5 vh-md-100 align-items-center d-flex "
            >
              <Card className="w-100 border-0 bg-transparent text-center d-block">
                <img
                  src="https://via.placeholder.com/470x700.png"
                  alt="app-bg"
                  className="w-100 d-none d-lg-block "
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="feature-wrapper layer-after pb-lg--7 pb-5">
        <Container>
          <Row className="justify-content-center">
            <Col
              xl={10}
              lg={12}
              className="pb-lg--5"
              style={{ position: "relative", top: "-50px" }}
            >
              <Row>
                <Col lg={3} md={3} className="col-xss-6">
                  <h1 className="h1 fw-700 open-font text-warning mb-0">4.7</h1>
                  <div className="star d-block w-100 text-left">
                    <img src="images/star.png" alt="star1" className="w15" />
                    <img src="images/star.png" alt="star2" className="w15" />
                    <img src="images/star.png" alt="star3" className="w15" />
                    <img src="images/star.png" alt="star4" className="w15" />
                    <img
                      src="images/star-disable.png"
                      alt="star"
                      className="w15"
                    />
                  </div>
                  <h4 className="font-xssss text-grey-900 fw-700 mt-2">
                    433 rating Based
                  </h4>
                </Col>

                <Col lg={3} md={3} className="col-xss-6">
                  <h1 className="h1 fw-700 open-font text-danger mb-0">
                    210<span className="font-xxl">%</span>{" "}
                    <i className="feather-arrow-up-right text-success font-xl"></i>
                  </h1>
                  <h4 className="font-xssss text-grey-500 fw-600 mt-2">
                    {" "}
                    433 rating
                  </h4>
                  <h4 className="font-xssss text-grey-900 fw-700 mt-2">
                    Increase in revenue
                  </h4>
                </Col>

                <Col lg={3} md={3} className="col-xss-6">
                  <h1 className="h1 fw-700 open-font text-success mb-0">
                    20<span className="font-xxl">%</span>{" "}
                    <i className="feather-arrow-down-right text-danger font-xl"></i>
                  </h1>
                  <h4 className="font-xssss text-grey-500 fw-600 mt-2">
                    {" "}
                    433 rating
                  </h4>
                  <h4 className="font-xssss text-grey-900 fw-700 mt-2">
                    Increase in revenue
                  </h4>
                </Col>

                <Col lg={3} md={3} className="col-xss-6">
                  <h1 className="h1 fw-700 open-font text-primary mb-0">
                    43K<span className="font-xxl">+</span>{" "}
                    <i className="feather-arrow-up-right text-success font-xl"></i>
                  </h1>
                  <h4 className="font-xssss text-grey-500 fw-600 mt-2">
                    {" "}
                    433 rating
                  </h4>
                  <h4 className="font-xssss text-grey-900 fw-700 mt-2">
                    Increase in revenue
                  </h4>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={6}>
              <Card className="mb-4 w-100 border-0 pt-4 pb-4 pr-4 pl-7 shadow-xss rounded-lg aos-init aos-animate">
                <i className="feather-layers text-success font-xl position-absolute left-15 ml-2"></i>
                <h2 className="fw-700 font-xss text-grey-900 mt-1">
                  Automatic Updates
                </h2>
                <p className="fw-500 font-xssss lh-24 text-grey-500 mb-0">
                  Praesent porttitor nunc vitae lacus vehicula, nec mollis eros
                  congue.
                </p>
              </Card>
            </Col>

            <Col lg={4} md={6}>
              <Card className="mb-4 w-100 border-0 pt-4 pb-4 pr-4 pl-7 shadow-xss rounded-lg aos-init aos-animate">
                <i className="feather-award text-danger font-xl position-absolute left-15 ml-2"></i>
                <h2 className="fw-700 font-xss text-grey-900 mt-1">
                  Blazing Performance
                </h2>
                <p className="fw-500 font-xssss lh-24 text-grey-500 mb-0">
                  Praesent porttitor nunc vitae lacus vehicula, nec mollis eros
                  congue.
                </p>
              </Card>
            </Col>

            <Col lg={4} md={6}>
              <Card className="mb-4 w-100 border-0 pt-4 pb-4 pr-4 pl-7 shadow-xss rounded-lg aos-init aos-animate">
                <i className="feather-cpu text-info font-xl position-absolute left-15 ml-2"></i>
                <h2 className="fw-700 font-xss text-grey-900 mt-1">
                  Free Updates
                </h2>
                <p className="fw-500 font-xssss lh-24 text-grey-500 mb-0">
                  Praesent porttitor nunc vitae lacus vehicula, nec mollis eros
                  congue.
                </p>
              </Card>
            </Col>

            <Col lg={4} md={6}>
              <Card className="mb-4 w-100 border-0 pt-4 pb-4 pr-4 pl-7 shadow-xss rounded-lg aos-init">
                <i className="feather-hard-drive text-warning font-xl position-absolute left-15 ml-2"></i>
                <h2 className="fw-700 font-xss text-grey-900 mt-1">
                  Unlimited Layouts
                </h2>
                <p className="fw-500 font-xssss lh-24 text-grey-500 mb-0">
                  Praesent porttitor nunc vitae lacus vehicula, nec mollis eros
                  congue.
                </p>
              </Card>
            </Col>

            <Col lg={4} md={6}>
              <Card className="mb-4 w-100 border-0 pt-4 pb-4 pr-4 pl-7 shadow-xss rounded-lg aos-init">
                <i className="feather-lock text-secondary font-xl position-absolute left-15 ml-2"></i>
                <h2 className="fw-700 font-xss text-grey-900 mt-1">
                  Best Sellers
                </h2>
                <p className="fw-500 font-xssss lh-24 text-grey-500 mb-0">
                  Praesent porttitor nunc vitae lacus vehicula, nec mollis eros
                  congue.
                </p>
              </Card>
            </Col>

            <Col lg={4} md={6}>
              <Card
                className="mb-4 w-100 border-0 pt-4 pb-4 pr-4 pl-7 shadow-xss rounded-lg aos-init"
                data-aos="zoom-in"
                data-aos-delay="500"
                data-aos-duration="500"
              >
                <i className="feather-globe text-success font-xl position-absolute left-15 ml-2"></i>
                <h2 className="fw-700 font-xss text-grey-900 mt-1">
                  Branding Feture
                </h2>
                <p className="fw-500 font-xssss lh-24 text-grey-500 mb-0">
                  Praesent porttitor nunc vitae lacus vehicula, nec mollis eros
                  congue.
                </p>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="service-wrapper layer-after">
        <Container>
          <Row className="justify-content-center">
            <Col
              xl={6}
              lg={8}
              md={10}
              className="page-title style1 text-center mb-5"
            >
              <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-xl ls-2 alert-warning d-inline-block text-warning mr-1">
                Feedback
              </span>
              <h1 className="h1 text-grey-900 fw-700 pb-3 mb-0 mt-3 d-block lh-3">
                We help not one, <br />
                But many Companies
              </h1>
              <p className="fw-300 font-xssss lh-28 text-grey-500">
                orem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dol ad minim veniam, quis
                nostrud exercitation
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Card className="w-100 border-0 p-4 text-center d-block shadow-xss rounded-xxl">
                <div className="card-image p-4 bg-lightblue rounded-xxl">
                  <img
                    src="https://via.placeholder.com/225x150.png"
                    alt="image"
                    className="img-fluid p-4"
                  />
                </div>
                <h2 className="font-md fw-700 text-grey-900 mt-4 mb-0">
                  Blazing Performance
                </h2>
                <p className="fw-300 font-xssss lh-28 text-grey-500 p-3">
                  orem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dol ad minim veniam,
                  quis nostrud exercitation
                </p>
              </Card>
            </Col>

            <Col lg={4}>
              <Card className="w-100 border-0 p-4 text-center d-block shadow-xss rounded-xxl">
                <div className="card-image p-0 bg-lightblue rounded-xxl">
                  <img
                    src="https://via.placeholder.com/225x150.png"
                    alt="image2"
                    className="img-fluid p-5"
                  />
                </div>
                <h2 className="font-md fw-700 text-grey-900 mt-4 mb-0">
                  Best Feedback
                </h2>
                <p className="fw-300 font-xssss lh-28 text-grey-500 p-3">
                  orem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dol ad minim veniam,
                  quis nostrud exercitation
                </p>
              </Card>
            </Col>

            <Col lg={4}>
              <Card className="w-100 border-0 p-4 text-center d-block shadow-xss rounded-xxl">
                <div className="card-image p-0 bg-lightblue rounded-xxl">
                  <img
                    src="https://via.placeholder.com/225x150.png"
                    alt="image3"
                    className="img-fluid p-5"
                  />
                </div>
                <h2 className="font-md fw-700 text-grey-900 mt-4 mb-0">
                  Quality Code
                </h2>
                <p className="fw-300 font-xssss lh-28 text-grey-500 p-3">
                  orem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dol ad minim veniam,
                  quis nostrud exercitation
                </p>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="price-wrap pb-lg--7 pt-lg--7 pb-5 pt-5 layer-after ">
        <Container>
          <div className="row justify-content-center">
            <div className="page-title style1 col-xl-6 col-lg-8 col-md-10 text-center mb-5">
              <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-xl ls-2 alert-success d-inline-block text-success mr-1">
                Our Pricing
              </span>
              <h1 className="h1 text-grey-900 fw-700 pb-3 mb-0 mt-3 d-block">
                Choose the plan that's right for your business
              </h1>
              <p className="fw-300 font-xssss lh-28 text-grey-500">
                orem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dol ad minim veniam, quis
                nostrud exercitation
              </p>
              <div className="custom-control custom-switch pl-0">
                <p className="fw-600 font-xssss lh-28 text-grey-900 d-inline-block pr-5 mr-3">
                  Bill Monthly
                </p>
                <input
                  type="checkbox"
                  className="custom-control-input dark-mode-switch"
                  id="darkmodeswitch1"
                />
                <label
                  className="custom-control-label bg-success mt-1"
                  htmlFor="darkmodeswitch1"
                ></label>
                <p className="fw-600 font-xssss lh-28 text-grey-900 d-inline-block">
                  Bill Anually
                </p>
              </div>
            </div>
          </div>
          <Row className="justify-content-center">
            <Col lg={12} xl={10}>
              <Row>
                
                <Col lg={4}>
                  <Card className="border-0 mb-4 bg-white shadow-xss shadow-xss rounded-lg">
                    <Card.Body className="p-5 border-top-lg border-size-lg border-primary">
                      <h1 className="display2-size fw-700">
                        € 49{" "}
                        <span className="font-xssss text-grey-500">
                          {" "}
                          / month
                        </span>
                      </h1>
                      <h2 className=" font-xs fw-700 mt-3 mb-3">Starter</h2>
                      <h4 className=" fw-500 mb-4 lh-24 font-xssss text-grey-500">
                        For anyone validating Framer as a professional
                        prototyping tool.
                      </h4>
                      <h4 className=" font-xssss fw-600 text-grey-500 mb-3 ">
                        <i className="ti-check font-xssss mr-2 btn-round-xs alert-primary text-primary"></i>{" "}
                        Unlimited views
                      </h4>
                      <h4 className=" font-xssss fw-600 text-grey-500 mb-3 ">
                        <i className="ti-check font-xssss mr-2 btn-round-xs alert-primary text-primary"></i>{" "}
                        Everything in Free
                      </h4>
                      <h4 className=" font-xssss fw-600 text-grey-500 mb-4">
                        <i className="ti-check font-xssss mr-2 btn-round-xs alert-primary text-primary"></i>{" "}
                        Private share links
                      </h4>
                      <a
                        href="/#"
                        className="btn btn-block border-0 w-100 bg-primary p-3 text-white fw-600 rounded-lg d-inline-block font-xssss btn-light mt-3"
                      >
                        Subscribe
                      </a>
                    </Card.Body>
                  </Card>
                </Col>

                <Col lg={4}>
                  <Card className=" border-0 mb-4 bg-white shadow-xss shadow-xss rounded-lg">
                    <Card.Body className="p-5 border-top-lg border-size-lg border-success">
                      <h1 className="display2-size fw-700">
                        € 99{" "}
                        <span className="font-xssss text-grey-500">
                          {" "}
                          / month
                        </span>
                      </h1>
                      <h2 className=" font-xs fw-700 mt-3 mb-3">Standard</h2>
                      <h4 className=" fw-500 mb-4 lh-24 font-xssss text-grey-500">
                        For anyone validating Framer as a professional
                        prototyping tool.
                      </h4>
                      <h4 className=" font-xssss fw-600 text-grey-500 mb-3 ">
                        <i className="ti-check font-xssss mr-2 btn-round-xs alert-success text-success"></i>{" "}
                        Unlimited views
                      </h4>
                      <h4 className=" font-xssss fw-600 text-grey-500 mb-3 ">
                        <i className="ti-check font-xssss mr-2 btn-round-xs alert-success text-success"></i>{" "}
                        Everything in Free
                      </h4>
                      <h4 className=" font-xssss fw-600 text-grey-500 mb-4">
                        <i className="ti-check font-xssss mr-2 btn-round-xs alert-success text-success"></i>{" "}
                        Private share links
                      </h4>
                      <a
                        href="/#"
                        className="btn btn-block border-0 w-100 bg-primary p-3 text-white fw-600 rounded-lg d-inline-block font-xssss btn-light mt-3"
                      >
                        Subscribe
                      </a>
                    </Card.Body>
                  </Card>
                </Col>

                <Col lg={4}>
                  <Card className="border-0 mb-4 bg-white shadow-xss shadow-xss rounded-lg">
                    <Card.Body className=" p-5 border-top-lg border-size-lg border-warning">
                      <h1 className="display2-size fw-700">
                        € 199{" "}
                        <span className="font-xssss text-grey-500">
                          {" "}
                          / month
                        </span>
                      </h1>
                      <h2 className=" font-xs fw-700 mt-3 mb-3">Premium</h2>
                      <h4 className=" fw-500 mb-4 lh-24 font-xssss text-grey-500">
                        For anyone validating Framer as a professional
                        prototyping tool.
                      </h4>
                      <h4 className=" font-xssss fw-600 text-grey-500 mb-3 ">
                        <i className="ti-check font-xssss mr-2 btn-round-xs alert-warning text-warning"></i>{" "}
                        Unlimited views
                      </h4>
                      <h4 className=" font-xssss fw-600 text-grey-500 mb-3 ">
                        <i className="ti-check font-xssss mr-2 btn-round-xs alert-warning text-warning"></i>{" "}
                        Everything in Free
                      </h4>
                      <h4 className=" font-xssss fw-600 text-grey-500 mb-4">
                        <i className="ti-check font-xssss mr-2 btn-round-xs alert-warning text-warning"></i>{" "}
                        Private share links
                      </h4>
                      <a
                        href="/#"
                        className="btn btn-block border-0 w-100 bg-primary p-3 text-white fw-600 rounded-lg d-inline-block font-xssss btn-light mt-3"
                      >
                        Subscribe
                      </a>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default MembershipLanding;
