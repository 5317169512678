import React, { useEffect, useState } from "react";
import ZoomComponent from "../../components/ZoomComponent";
import "../../assets/css/Courses/zoomDisplay.css";
import { FaUserCircle, FaCalendar } from "react-icons/fa";
import { Container, Card, Button, Modal, Row } from "react-bootstrap";
import CourseCard from "../../components/CourseCard";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

function WebinarDetail() {
  const [joinMeeting, setJoinMeeting] = useState(false);
  const { id_material } = useParams();
  const [show, setShow] = useState(false);
  const [webinarsDataId, setWebinarsDataId] = useState();
  const webinars = useSelector((state) => state.webinars);

  useEffect(() => {
    if (webinars.webinarsDataId.meeting_id) {
      if (
        moment(moment().format()).isBetween(
          moment(webinars.webinarsDataId.time_starts),
          moment(webinars.webinarsDataId.time_ends)
        )
      )
        // check if current time is between meeting time
        setMeeting();
      setWebinarsDataId(webinars.webinarsDataId);
    }
  }, [webinars]);

  const handleRegistWebinar = () => {
    setShow(true);
  };
  const setMeeting = () => {
    setJoinMeeting(true);
  };
  return (
    <Container>
      {joinMeeting && webinarsDataId ? (
        <ZoomComponent
          passWord={webinarsDataId.passcode}
          meetingNumber={webinarsDataId.meeting_id}
        />
      ) : (
        <>
          <Card className="p-5 card-wrapper__container">
            <div className="webinar-title">Lorem ipsum doler amet</div>
            <div className="wrapper-main__content">
              <div className="instruktor-wrapper">
                <FaUserCircle className="mr-2" />{" "}
                <div className="instruktor-teks">LM Formasi</div>
              </div>
              <div className="date-wrapper">
                <FaCalendar className="mr-2" />
                <div className="date-teks">
                  Mulai Tanggal{" "}
                  {webinarsDataId
                    ? moment(webinarsDataId.time_starts).format(
                        "DD MMMM YYYY HH:MM"
                      )
                    : ""}
                </div>
              </div>
            </div>
            <div className="wrapper-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </div>
            {/* <button
            className="w-25 btn btn-primary button-join"
            onClick={setMeeting}
          >
            Bergabung
          </button> */}
            <button
              className="w-25 btn btn-primary button-join"
              onClick={handleRegistWebinar}
            >
              Daftar
            </button>
          </Card>
          <div className="related-webinar_wrapper">
            <div className="related-webinar__title">Webinar Terkait</div>
            <Row>
              {[...Array(4)].map((item, i) => {
                return <CourseCard key={i} />;
              })}
            </Row>
          </div>
          <div className="related-webinar_wrapper">
            <div className="related-webinar__title">Pelatihan Terkait</div>
            <Row>
              {[...Array(4)].map((item, i) => {
                return <CourseCard key={i} />;
              })}
            </Row>
          </div>
        </>
      )}
      {show ? (
        <Modal show={show}>
          <Modal.Header>
            <Modal.Title>Daftar Webinar</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </Container>
  );
}

export default WebinarDetail;
