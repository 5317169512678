import {
  PAYMENTS_DATA_POST,
  PAYMENTS_DATA_POST_SUCCESS,
  PAYMENTS_DATA_POST_FAIL,
  REMOVE_PAYMENTS_DATA,
} from "../config/PaymentsConfig";

const intialState = {
  paymentsDataPost: [],
  isLoading: false,
};

export const PaymentsReducers = function (state = intialState, action) {
  switch (action.type) {
    case PAYMENTS_DATA_POST:
      return { ...state, isLoading: true };
    case PAYMENTS_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, paymentsDataPost: action.value };
    case PAYMENTS_DATA_POST_FAIL:
      return { ...state, isLoading: false, paymentsDataPost: "fail" };
    case REMOVE_PAYMENTS_DATA:
      return { ...state, isLoading: false, paymentsDataPost: [] };
    default:
      return state;
  }
};
