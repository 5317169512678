import React from "react";
import { Image, Card, Row, Col } from "react-bootstrap";
import "../assets/css/Courses/instructor.css";
function Instructor({ item }) {
  return (
    <>
      {item.course_type === "Sertifikasi" ? (
        <h2 className="instructor-title h2 mb-3">Penguji</h2>
      ) : (
        <h2 className="instructor-title h2 mb-3">Instruktur</h2>
      )}
      <Row>
        <Col>
          <Card className="mb-4 padding-card">
            {" "}
            <div className="d-flex">
              {item.instructors &&
                item.instructors.map((items, index) => (
                  <div key={index} className="my-2 ">
                    <div className=" d-flex mr-3 align-items-center">
                      <div className="d-flex align-self-start">
                        <Image className="avatar-big mr-3" roundedCircle />
                      </div>
                      <div id="ul">
                        <h5 className="instructor-container mr-2  d-inline-block float-right">
                          {items.name}
                        </h5>
                        <div className="tutor_description">Lorem ipsum </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Instructor;
