import React from "react";
import { Card, Button, Accordion } from "react-bootstrap";
import "../assets/css/Personal/learningPathCard.css";
import { Link } from "react-router-dom";

import { BiPlayCircle, BiTime } from "react-icons/bi";
import { FaStar, FaChevronDown } from "react-icons/fa";
function LearningPathCard({ item }) {
  return (
    <>
      <Card className="card_container mt-2">
        <div className="card_thumbnail__learning_path">
          {" "}
          <Card.Img
            src="https://www.sketchappsources.com/resources/source-image/3d-dash-illustration-sumit_choudhary.png"
            className=""
            alt="https://www.sketchappsources.com/resources/source-image/3d-dash-illustration-sumit_choudhary.png"
          />
        </div>
        <div className="card_inside__padding ">
          <div className="">
            <div>
              <div>
                <Accordion defaultActiveKey="0" className="mb-0">
                  <Accordion.Toggle
                    className="p-0"
                    eventKey="0"
                    className="accordion_lp"
                  >
                    <div>
                      <div className="fp-card-title text-left">Judul</div>
                      <div className="d-flex justify-content-between mt-3">
                        <div className="mr-3">
                          <Card.Text className="fp-card-sub-text fs-12">
                            <BiTime className="mr-1" />
                            50 menit tersisa
                          </Card.Text>
                        </div>
                        <div>
                          <Card.Text className="fp-card-sub-text fs-12">
                            <BiPlayCircle className="mr-1" />
                            12 pelajaran
                          </Card.Text>
                        </div>
                      </div>
                    </div>
                    <div>
                      <FaChevronDown />
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <p className="my-2 fp-card-text card-text">
                      Angular adalah platform untuk membangun web seluler dan
                      desktop aplikasi.ing
                    </p>
                    {/* <div className="d-flex justify-content-between align-items-center">
                      <Link
                        to={`/learning-path/`}
                        style={{ textDecoration: "none" }}
                      >
                        <Button className="learning_path__button">Mulai</Button>
                      </Link>
                      <div className="d-flex align-items-center">
                        <Card.Text className="fp-card-text mr-2 mb-0">
                          Rating
                        </Card.Text>
                        {[...Array(5)].map((value, index) => {
                          return (
                            <div className="d-flex" key={index}>
                              <FaStar className="rating_size " />
                            </div>
                          );
                        })}
                      </div>
                    </div> */}
                  </Accordion.Collapse>
                </Accordion>
              </div>
            </div>
          </div>
          {/* <div>
            <div>
              <Card.Text className="fp-card-text">
                Angular adalah platform untuk membangun web seluler dan desktop
                aplikasi.ing
              </Card.Text>
            </div>
          </div>
          <div className="d-flex justify-content-between my-3">
            <div>
              <Card.Text className="fp-card-sub-text fs-12">
                <BiTime className="mr-1" />
                50 menit tersisa
              </Card.Text>
            </div>
            <div>
              <Card.Text className="fp-card-sub-text fs-12">
                <BiPlayCircle className="mr-1" />
                12 pelajaran
              </Card.Text>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <Link to={`/learning-path/`} style={{ textDecoration: "none" }}>
              <Button className="learning_path__button">Mulai</Button>
            </Link>
            <div className="d-flex align-items-center">
              <Card.Text className="fp-card-text mr-2 mb-0">Rating</Card.Text>
              {[...Array(5)].map((value, index) => {
                return (
                  <div className="d-flex" key={index}>
                    <FaStar className="rating_size " />
                  </div>
                );
              })}
            </div>
          </div> */}
        </div>
      </Card>
      {/* <Card className="lp-card" border="dark">
        <Card.Body className="lp-card-body">
          <Row xs={3} className="justify-content-center align-items-center">
            <Col>
              <Card.Img
              alt="https://luma-vue.demo.frontendmatter.com/images/paths/angular_64x64.svg"
                src="https://luma-vue.demo.frontendmatter.com/images/paths/angular_64x64.svg"
                className="lp-card-img"
              />
            </Col>
            <Col>
              <Card.Title className="lp-card-title">
                {item ? item.title : ""}
              </Card.Title>
              <Card.Text className="lp-card-text">
                {item ? item.description : ""}
              </Card.Text>
            </Col>
            <Col>
              <Button variant="primary" className="lp-card-btn" size="sm">
                Lanjutkan
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card> */}
      <div className="popover-hanger">
        <div className="course-hover__container">
          <div className="course-hover__wrapper">
            <div className="course-hover__topInfo">
              <div className="course-hover__logo-wrapper"></div>
              <div className="course-hover__title-wrapper">
                <div className="title">Angular</div>
                <div className="course-hover__tutor-wrapper">pelatihan</div>
              </div>
            </div>
            <div className="course-hover__summary font_lp_card">
              Angular adalah platform untuk membangun web seluler dan desktop
              aplikasi.
            </div>
            <div className="course-hover__bottomInfo">
              <div className="course-hover__additionalInfo">
                <div className="font_lp_card_d">6 jam</div>
                <div className="font_lp_card_d">12 pelajaran</div>
                <div className="font_lp_card_d">Pemula</div>
              </div>
              <div className="d-flex flex-column">
                <Button className="m-2"> Lanjutkan</Button>
                <Button
                  className="m-2"
                  variant="light"
                  style={{ border: "1px solid rgba(91,91,91,0.15)" }}
                >
                  Mulai lagi
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LearningPathCard;
