import { call, put, takeEvery } from "redux-saga/effects";
import { PromiseService } from "../../services/PromiseService";
import {
  PURCHASES_DATA_POST,
  PURCHASES_DATA_LINK,
} from "../config/PurchasesConfig";
import {
  postPurchasesDataSuccess,
  postPurchasesDataFail,
} from "../actions/PurchasesActions";

function* workerPurchasesDataPost(params) {
  const body = params.value;
  try {
    const response = yield call(
      PromiseService.postData,
      PURCHASES_DATA_LINK,
      body
    );
    if (response.status === 201 && response.data) {
      yield put(postPurchasesDataSuccess(response.data));
    } else {
      yield put(postPurchasesDataFail(response));
    }
  } catch (error) {
    yield put(postPurchasesDataFail(error));
  }
}

export const watcherPurchases = [
  takeEvery(PURCHASES_DATA_POST, workerPurchasesDataPost),
];
