import { ZoomMtg } from "@zoomus/websdk";
import { useEffect} from "react";
import { AuthData } from "../helper/auth";

const crypto = require("crypto"); // crypto comes with Node.js

const ZoomComponent = ({ passWord, meetingNumber }) => {
  var apiKey = "MVfVOSCEQwGZNKFD2slZ-w";
  var apiSecret = "dXNpRJHw9Oeme5tqiNEvpBsJ8EfOh0UDBB25";
  var leaveUrl = "http://localhost:3000"; // our redirect url
  var userName = `${AuthData().username}`;
  var userEmail = `${AuthData().username}`;

  // loading zoom libraries before joining on component did mount
  useEffect(() => {
    showZoomDIv();
    ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.8/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();

    ZoomMtg.i18n.load("en-US");
    ZoomMtg.i18n.reload("en-US");
    generateSignature(apiKey, apiSecret, meetingNumber, 0).then((res) => {
      initiateMeeting(res);
    }); // need to generate based on meeting id - using - role by default 0 = javascript
  }, []);

  const showZoomDIv = () => {
    document.getElementById("zmmtg-root").style.display = "block";
  };

  window.addEventListener("beforeunload", (ev) => {
    ev.preventDefault();
    return (ev.returnValue = "Anda yakin akan keluar?");
  });

  const initiateMeeting = (signature) => {
    console.log(passWord, meetingNumber, signature);
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      isSupportAV: true,
      success: (success) => {
        console.log(success);
        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName,
          apiKey: apiKey,
          userEmail: userEmail,
          passWord: passWord,
          success: (success) => {
            console.log(success);
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  };

  return <div className="App">Zoom</div>;
};

export default ZoomComponent;

function generateSignature(apiKey, apiSecret, meetingNumber, role) {
  return new Promise((res, rej) => {
    // Prevent time sync issue between client signature generation and zoom
    const timestamp = new Date().getTime() - 30000;
    const msg = Buffer.from(apiKey + meetingNumber + timestamp + role).toString(
      "base64"
    );
    const hash = crypto
      .createHmac("sha256", apiSecret)
      .update(msg)
      .digest("base64");
    const signature = Buffer.from(
      `${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`
    ).toString("base64");

    res(signature);
  });
}
