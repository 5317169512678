import {
  PAYMENTS_DATA_POST,
  PAYMENTS_DATA_POST_SUCCESS,
  PAYMENTS_DATA_POST_FAIL,
  REMOVE_PAYMENTS_DATA,
} from "../config/PaymentsConfig";

export const postPaymentsData = (value) => ({
  type: PAYMENTS_DATA_POST,
  value,
});
export const postPaymentsDataSuccess = (value) => ({
  type: PAYMENTS_DATA_POST_SUCCESS,
  value,
});
export const postPaymentsDataFail = (value) => ({
  type: PAYMENTS_DATA_POST_FAIL,
  value,
});
export const removePostPaymentsData = (value) => ({
  type: REMOVE_PAYMENTS_DATA,
  value,
});
