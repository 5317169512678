import React from "react";
import { Container } from "react-bootstrap";
import FileUploader from "../../components/FileUploader";

function AssignmentPage() {
  return (
    <Container className="lesson-container">
      <FileUploader />
    </Container>
  );
}

export default AssignmentPage;
