import React, { useEffect } from "react";
import { Container, Card, Row, Col, Button, Form } from "react-bootstrap";
import { AuthIsLogin } from "../../helper/auth";
import { FaClock, FaBookOpen } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import "../../assets/css/Transaction/cardStyling.css";
const shipping = require("shipping-indonesia");
shipping.init("b99bd5793b84df221955932c76d10f83");

function Checkout() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
    shipping.getAllProvince((results) => {
      console.log(results);
    });
  }, []);
  return (
    <Container className="dashboard_page__container">
      <div className="dashboard__title_wrapper">
        <h2 className="h2 dashboard__section_title">Checkout</h2>
      </div>
      <Row>
        <Col md={8}>
          <Card className="cart_container">
            <div className="card_wrapper_instructor">
              <div className="card_title_instructor">LMS Instruktur</div>
            </div>
            <div className="card_wrapper__info">
              <div className="card_content__wrapper">
                <div className="card_content__image"></div>
                <div className="card_content__title">
                  <div className="card_content__title__main">
                    Tutorial Perpajakan
                  </div>
                  <div className="card_content__title__sub">
                    <div className="card_content__course mr-4  text-muted">
                      <FaBookOpen className="mr-2" />
                      12 Pelatihan
                    </div>
                    <div className="card_content__time  text-muted">
                      <FaClock className="mr-2" /> 03j 45m 32s
                    </div>
                  </div>
                  <div className="card_content__title__price">Rp. 300.000</div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="cart_container">
            <div className="card_promo_wrapper">
              <div className="card_title mb-2">Promo</div>
              <Form.Control
                className="satukelas_input py-2"
                type="text"
                placeholder="Masukkan Promo"
              />
            </div>
            <div className="card_border"></div>
            <div className="card_total_wrapper mb-2">
              <div className="card_title">Total Pembayaran</div>
              <div className="card_subtitle mb-2">2 Pelatihan</div>
              <div className="card_price text-right">Rp. 300.000</div>
            </div>
            <div className="card_button ">
              <Button className="w-100 py-2">Pilih Pembayaran</Button>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Checkout;
