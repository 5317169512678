import React from "react";
import { Row, Col, Button, Image, Container, Card } from "react-bootstrap";
import {
  FaClock,
  FaShareAlt,
  FaRegBookmark,
  FaTag,
  FaBookOpen,
  FaStar,
} from "react-icons/fa";
import "./../assets/css/Courses/CourseDetailTitle.css";
import { convertHours } from "../helper/functions";
function CourseDetailTitle({
  id,
  item,
  title,
  titles,
  enrolled,
  enrollCourse,
  purchased,
  statusUser,
  continueCourse,
  anotherAttempt,
}) {
  let tags = "";
  if (item?.learning_paths) {
    for (let i = 0; i < item.learning_paths.length; i++) {
      if (i === 0) tags = item.learning_paths[i].title;
      else tags = tags + ", " + item.learning_paths[i].title;
    }
  }
  return (
    <>
      {id ? (
        <Container>
          <Row className="align-items-baseline">
            <Col md={10}>
              <h1 className="h1 mt-4 course_front_big__title_detail">
                {titles}
              </h1>

              <div
                id="course_detail__title"
                className="course_detail__title_front color-gray mb-4 "
              >
                <Image className="course_detail__tutor_avatar" roundedCircle />
                <span className="text-black">
                  {item?.instructors ? item.instructors[0].name : ""}
                </span>
              </div>
            </Col>
            <Col className="d-flex align-self-center justify-content-end">
              <div className="btn_satukelas_share mr-2">
                <FaShareAlt />
              </div>
              <div className="btn_satukelas_bookmark">
                <FaRegBookmark className="fill-white" />
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <Card
          className="course_detail__container p-0"
          style={{ backgroundImage: `url(${item?.thumbnail})` }}
        >
          <div className="wrapper_course__detail">
            <div className="course_detail__wrapper">
              <div className="course_detail__learning_path__wrapper">
                {item?.learning_paths &&
                  item.learning_paths.map((items, index) => (
                    <div key={index} className="course_detail__learning_path">
                      {items?.title}
                    </div>
                  ))}
              </div>

              <h1 className="course_detail__course_title h1">{item?.title}</h1>
              <div className="course_detail__info__wrapper">
                <div className="course_detail__info__item">
                  <FaClock className="course_detail__icon" />
                  {item?.total_duration === 0
                    ? "-"
                    : convertHours(item?.total_duration)}
                </div>
                <div className="course_detail__info__item">
                  <FaBookOpen className="course_detail__icon" />
                  {item?.lessons_count} Pelajaran
                </div>
                <div className="course_detail__info__item">
                  <FaTag className="course_detail__icon" />
                  {tags}
                </div>
              </div>

              <div className="course_detail__summary__wrapper">
                {item?.summary
                  ? item.summary
                  : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi non quis exercitationem culpa nesciunt nihil aut nostrum explicabo reprehenderit optio amet ab temporibus asperiores quasi cupiditate. Voluptatum ducimus voluptates voluptas?"}
              </div>
              <div className="d-flex justify-content-between">
                <div className="course_detail__tutor__wrapper d-flex">
                  {item?.instructors &&
                    item.instructors.map((item, index) => (
                      <div
                        key={index}
                        className="course_detail__tutor__item mr-3"
                      >
                        <Image
                          className="course_detail__tutor_avatar"
                          roundedCircle
                        />
                        <div className="course_detail__tutor_name">
                          {item.name}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="course_detail__cta_wrapper">
              <div className="d-flex">
                <div className="btn_satukelas_share">
                  <FaShareAlt />
                </div>
                <div className="btn_satukelas_bookmark">
                  <FaRegBookmark className="fill-white" />
                </div>
                {anotherAttempt ? (
                  <Button
                    onClick={continueCourse}
                    className="btn_satukelas_primary font-size__button"
                  >
                    LIHAT PELATIHAN
                  </Button>
                ) : (
                  <Button
                    onClick={continueCourse}
                    className="btn_satukelas_primary font-size__button"
                    disabled={
                      !(
                        (purchased || statusUser === "active") &&
                        statusUser !== "expired" &&
                        enrolled
                      )
                    }
                  >
                    LANJUTKAN PELATIHAN
                  </Button>
                )}
              </div>
              <div className="course_front__rating d-flex">
                {/* {item?.rating?.score > 0 &&
                  [...Array(parseInt(item.rating.score))].map(
                    (value, index) => {
                      return (
                        <div key={index}>
                          <FaStar className="star-size fill-yellow" />
                        </div>
                      );
                    }
                  )} */}
                {/* parseInt(item.rating.score) */}
                {item?.rating?.score > 0 &&
                  [...Array(parseInt(item.rating.score))].map(
                    (value, index) => {
                      return (
                        <div key={index}>
                          <FaStar className="star-size fill-yellow" />
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          </div>
        </Card>
      )}
    </>
  );
}
export default CourseDetailTitle;
