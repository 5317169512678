import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";

function Webstore() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container>
      <h1>Webstore</h1>
    </Container>
  );
}

export default Webstore;
