import {
  COURSE_TYPES_DATA,
  COURSE_TYPES_DATA_SUCCESS,
  COURSE_TYPES_DATA_ID_SUCCESS,
  COURSE_TYPES_DATA_FAIL,
} from "../config/CourseTypesConfig";

export const getCourseTypesData = (value) => ({
  type: COURSE_TYPES_DATA,
  value,
});
export const getCourseTypesDataSuccess = (value) => ({
  type: COURSE_TYPES_DATA_SUCCESS,
  value,
});
export const getCourseTypesDataIdSuccess = (value) => ({
  type: COURSE_TYPES_DATA_ID_SUCCESS,
  value,
});
export const getCourseTypesDataFail = (value) => ({
  type: COURSE_TYPES_DATA_FAIL,
  value,
});
