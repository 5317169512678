import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import LearningPathCard from "../../components/LearningPathCard";
import FeaturedPathCard from "../../components/FeaturedPathCard";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Personal/myCourse.css";

function MyCourse() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container className="dashboard_page__container">
      {/* <div className="dashboard__title_wrapper"> */}
      <h2 className="dashboard__section_title h2">Progress Path</h2>
      {/* </div> */}
      <div>
        <Row className="mb-5 mt-2">
          {[...Array(3)].map((value, index) => {
            return (
              <Col
                key={index}
                xs={12}
                sm={3}
                md={4}
                className="fp-container mb-3 d-flex justify-content-center"
              >
                <FeaturedPathCard key={index} />
              </Col>
            );
          })}
        </Row>
      </div>
      <div>
        <h2 className="h2 dashboard__section_title">
          Rekomendasi Learning Path
        </h2>
        <Row className="mb-4 mt-2">
          {[...Array(6)].map((value, index) => {
            return (
              <Col
                key={index}
                xs={12}
                sm={3}
                md={4}
                className="course-container mb-3"
              >
                <LearningPathCard key={index} />
              </Col>
            );
          })}
        </Row>
      </div>
    </Container>
  );
}

export default MyCourse;
