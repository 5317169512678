import React from "react";
import { FaHome, FaBox } from "react-icons/fa";
import { RiLayout5Fill } from "react-icons/ri";
import { MdLayers } from "react-icons/md";

function StickyNav({ InnerComponent }) {
  return (
    <div className="app-footer border-0 shadow-lg">
      <a href="default.html" className="nav-content-bttn nav-center">
        <FaHome />
      </a>
      <a href="default-follower.html" className="nav-content-bttn">
        <FaBox />
      </a>
      <a
        href="default-live-stream.html"
        className="nav-content-bttn"
        data-tab="chats"
      >
        <RiLayout5Fill />
      </a>
      <a href="/#" className="nav-content-bttn sidebar-layer">
        <MdLayers />
      </a>
      <a href="default-settings.html" className="nav-content-bttn">
        <img
          src="https://via.placeholder.com/50x50.png"
          alt="user"
          className="w30 shadow-xss"
        />
      </a>
    </div>
  );
}

export default StickyNav;
