import {
  //get
  USERS_DATA,
  USERS_DATA_ID_SUCCESS,
  USERS_DATA_SUCCESS,
  //post
  USERS_DATA_POST,
  USERS_DATA_POST_SUCCESS,
  //put
  USERS_DATA_PUT,
  USERS_DATA_PUT_SUCCESS,
  //fail
  USERS_DATA_FAIL,
  //remove user data
  USERS_DATA_ID_REMOVE,
} from "../config/UsersConfig";

//get
export const getUsersData = (value) => ({
  type: USERS_DATA,
  value,
});
export const getUsersDataIdSuccess = (value) => ({
  type: USERS_DATA_ID_SUCCESS,
  value,
});
export const getUsersDataSuccess = (value) => ({
  type: USERS_DATA_SUCCESS,
  value,
});

//post
export const postUsersData = (value) => ({
  type: USERS_DATA_POST,
  value,
});
export const postUsersDataSuccess = (value) => ({
  type: USERS_DATA_POST_SUCCESS,
  value,
});

//put
export const putUsersData = (value) => ({
  type: USERS_DATA_PUT,
  value,
});
export const putUsersDataSuccess = (value) => ({
  type: USERS_DATA_PUT_SUCCESS,
  value,
});

//fail
export const getUsersDataFail = (value) => ({
  type: USERS_DATA_FAIL,
  value,
});

//remove user data
export const removeUsersDataId = (value) => ({
  type: USERS_DATA_ID_REMOVE,
  value,
});
