import { call, put, takeEvery } from "redux-saga/effects";
import { PromiseService } from "../../services/PromiseService";
import {
  QUIZ_DATA_LINK,
  QUIZ_DATA,
  QUIZ_START,
  QUIZ_ATTEMPT,
  QUIZ_SCORE,
} from "../config/QuizConfig";
import {
  //get
  getQuizDataSuccess,
  getQuizDataIdSuccess,
  getQuizDataFail,
  //start
  postStartQuizSuccess,
  postStartQuizFail,
  //attempt
  getQuizAttemptSuccess,
  getQuizAttemptFail,
  //score
  postQuizScoreSuccess,
  postQuizScoreFail,
} from "../actions/QuizActions";

function* workerQuizData(params) {
  try {
    const response = yield call(
      PromiseService.getData,
      `${QUIZ_DATA_LINK}/${params.value}`
    );
    if (response.status === 200 && response.data) {
      yield put(getQuizDataIdSuccess(response.data));
    } else {
      yield put(getQuizDataFail(response));
    }
  } catch (error) {
    yield put(getQuizDataFail(error));
  }
}

function* workerQuizStartData(params) {
  const body = params.value;
  try {
    const response = yield call(
      PromiseService.postData,
      `${QUIZ_DATA_LINK}/start`,
      body
    );
    if (response.status === 200 && response.data) {
      yield put(postStartQuizSuccess(response.data));
    } else {
      yield put(postStartQuizFail(response));
    }
  } catch (error) {
    yield put(postStartQuizFail(error));
  }
}

function* workerQuizAttemptData(params) {
  try {
    const response = yield call(
      PromiseService.getData,
      `${QUIZ_DATA_LINK}/time/${params.value}`
    );
    if (response.status === 200 && response.data) {
      yield put(getQuizAttemptSuccess(response.data));
    } else {
      yield put(getQuizAttemptFail(response));
    }
  } catch (error) {
    yield put(getQuizAttemptFail(error));
  }
}

function* workerQuizScoreData(params) {
  const body = params.value;
  try {
    const response = yield call(
      PromiseService.postData,
      `${QUIZ_DATA_LINK}/score`,
      body
    );
    if (response.status === 200 && response.data) {
      yield put(postQuizScoreSuccess(response.data));
    } else {
      yield put(postQuizScoreFail(response));
    }
  } catch (error) {
    yield put(postQuizScoreFail(error));
  }
}

export const watcherQuiz = [
  takeEvery(QUIZ_DATA, workerQuizData),
  takeEvery(QUIZ_START, workerQuizStartData),
  takeEvery(QUIZ_ATTEMPT, workerQuizAttemptData),
  takeEvery(QUIZ_SCORE, workerQuizScoreData),
];
