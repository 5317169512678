import {
    TOPIC_DATA,
    TOPIC_DATA_POST,
    TOPIC_DATA_POST_SUCCESS,
    TOPIC_DATA_PUT,
    TOPIC_DATA_PUT_SUCCESS,
    TOPIC_DATA_DELETE,
    TOPIC_DATA_DELETE_SUCCESS,
    TOPIC_DATA_SUCCESS,
    TOPIC_DATA_FAIL,
    TOPIC_DATA_FAIL_PUT,
    TOPIC_DATA_ID_SUCCESS,
} from "../config/TopicConfig";

const intialState = {
    topicDataPut: [],
    topicDataPost: [],
    topicDataDelete: [],
    topicData: [],
    topicDataId: [],
    isLoading: false,
};

export const TopicReducers = function (state = intialState, action) {
    switch (action.type) {
        case TOPIC_DATA_POST:
            return { ...state, isLoading: true };
        case TOPIC_DATA_POST_SUCCESS:
            return { ...state, isLoading: false, topicDataPost: action.value };
        case TOPIC_DATA_DELETE:
            return { ...state, isLoading: true };
        case TOPIC_DATA_DELETE_SUCCESS:
            return { ...state, isLoading: false, topicDataDelete: action.value };
        case TOPIC_DATA_PUT:
            return { ...state, isLoading: true };
        case TOPIC_DATA_PUT_SUCCESS:
            return { ...state, isLoading: false, topicDataPut: action.value };
        case TOPIC_DATA:
            return { ...state, isLoading: true };
        case TOPIC_DATA_SUCCESS:
            return { ...state, isLoading: false, topicData: action.value };
        case TOPIC_DATA_ID_SUCCESS:
            return { ...state, isLoading: false, topicDataId: action.value };
        case TOPIC_DATA_FAIL:
            return { ...state, isLoading: false, topicDataPost: "error" };
        case TOPIC_DATA_FAIL_PUT:
            return { ...state, isLoading: false, topicDataPut: "error" };
        default:
            return state;
    }
};
