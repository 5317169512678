import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  Tabs,
  Tab,
  Carousel,
  Button,
  Form,
} from "react-bootstrap";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import SearchBoxInside from "../../components/SearchBoxInside";
import CourseCard from "../../components/CourseCard";
import "../../assets/css/Home/homePage.css";
import { Link } from "react-router-dom";
import { categories, testimoni, blog, membership } from "../../helper/constant";
import ReactGA from "react-ga";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

function HomePage() {
  const [memberships, setMemberships] = useState(false);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const handleChangeMembership = () => {
    setMemberships(!memberships);
  };
  return (
    <div>
      <div className="wrapper-hero">
        <div
          className="wrapper-hero"
          style={{
            backgroundImage: 'url("./bg-homepage.webp")',
            height: "80vh",
          }}
        >
          <Container className="px-4 h-100">
            <Row className="h-inherit">
              <div className="col-xl-8 col-lg-8  align-items-center d-flex sm-mt-7">
                <div className="w-100 wrapper-hero z-main border-0  d-block sm-mt-7">
                  {/* <span className="badge-home pl-3 pr-3 py-2  rounded-xl alert-primary d-inline-block text-primary mb-3">
                    Blog
                  </span> */}
                  <h1 className="title-home__wrapper h1 display4-md-size mb-0 text-white">
                    Temukan Pelatihan dan Webinar yang Tepat Disini
                  </h1>
                  <div className="">
                    {/* <h4 className="title-home__subtitle text-white mt-3 ">
                      {" "}
                      Quite simply the best theme we’ve ever purchased. The
                      customisation and flexibility are superb. Speed is
                      awesome. Not a criticism we can make. Fun to use the
                      theme, easy installation, super easy to use. Excellent
                      work.”
                    </h4>
                    <a
                      href="/#"
                      className="btn border-0 w200 bg-primary p-3 text-white  read_more__button rounded-lg d-inline-block btn-light mt-1 "
                    >
                      Read More
                    </a> */}
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </div>
        <div className="overlay-bg__home"></div>
      </div>
      <Container className="home-search__container">
        <Row>
          <Col lg={12} className="mb-4">
            <div className="shadow-box__search">
              <SearchBoxInside />
            </div>
          </Col>
        </Row>
      </Container>
      <div className="pb-5">
        <Container className="pr-0">
          <Row>
            <Col lg={12} md={12} xl={12}>
              <h2 className="page_title h2">
                Ikuti Pelatihan Sesuai Alur Belajar
              </h2>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="mt-4">
              <div className=" category-card overflow-visible ">
                <div>
                  <Splide
                    options={{
                      rewind: true,
                      perPage: 8,
                      perMove: 1,
                      gap: "1.5rem",
                      pagination: false,
                    }}
                  >
                    {categories.map((item, index) => (
                      <SplideSlide key={index}>
                        <div style={{ width: "auto", marginRight: "10px" }}>
                          <div className="item">
                            <Card className="category-card__list card_border__item p-4 mr-1 text-center bg-card__category card_min-height card_width__category">
                              <Card.Body className="p-1">
                                <div className="image-category"></div>
                                <div className="text-category mt-3">
                                  {item.name}
                                  {/* <span className="text-subtitle mt-2">
                                    32 Course
                                  </span> */}
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                        </div>
                      </SplideSlide>
                    ))}
                  </Splide>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="justify-content-center text-center pb-5">
          <Col md={10} lg={6} className="style1 text-center">
            <h2 className="h2 home_container__title-section pb-3 mb-0 d-block">
              Pelatihan Populer
            </h2>
          </Col>
          <Col lg={12}>
            <Tabs
              defaultActiveKey="manajemen"
              transition={false}
              id="noanim-tab-example"
              className="mt-4 mb-5 justify-content-center"
            >
              <Tab
                id="tab-home"
                tabClassName="text-blacks"
                eventKey="manajemen"
                title="Manajemen SDM"
              >
                <Container fluid>
                  <Row>
                    {[...Array(8)].map((value, index) => {
                      return <CourseCard key={index} id="card_populer" />;
                    })}
                  </Row>
                </Container>
              </Tab>
              <Tab
                id="tab-home"
                tabClassName="text-blacks"
                eventKey="pemasaran"
                title="Pemasaran"
              >
                <Container fluid>
                  <Row>
                    {[...Array(8)].map((value, index) => {
                      return <CourseCard key={index} />;
                    })}
                  </Row>
                </Container>
              </Tab>
              <Tab
                id="tab-home"
                tabClassName="text-blacks"
                eventKey="pengembangan"
                title="Pengembangan Diri"
              >
                {" "}
                <Container fluid>
                  <Row>
                    {[...Array(8)].map((value, index) => {
                      return <CourseCard key={index} />;
                    })}
                  </Row>
                </Container>
              </Tab>
              <Tab
                id="tab-home"
                tabClassName="text-blacks"
                eventKey="keuangan"
                title="Keuangan"
              >
                {" "}
                <Container fluid>
                  <Row>
                    {[...Array(8)].map((value, index) => {
                      return <CourseCard key={index} />;
                    })}
                  </Row>
                </Container>
              </Tab>
              <Tab
                id="tab-home"
                tabClassName="text-blacks"
                eventKey="perpajakan"
                title="Perpajakan"
              >
                {" "}
                <Container fluid>
                  <Row>
                    {[...Array(8)].map((value, index) => {
                      return <CourseCard key={index} />;
                    })}
                  </Row>
                </Container>
              </Tab>
              <Tab
                id="tab-home"
                tabClassName="text-blacks"
                eventKey="akuntansi"
                title="Akuntansi"
              >
                {" "}
                <Container fluid>
                  <Row>
                    {[...Array(8)].map((value, index) => {
                      return <CourseCard key={index} />;
                    })}
                  </Row>
                </Container>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
      <div className="blog-page pb-5">
        <Container>
          <Row className="justify-content-center">
            <Col
              lg={8}
              xl={7}
              md={10}
              className="page-title style1 text-center mb-4"
            >
              <span className="badge-home py-2 pl-3 pr-3 text-uppercase rounded-xl alert-warning d-inline-block mr-1">
                Blog
              </span>
              <h2 className="home_container__title-section h2 pb-3 mb-0 mt-3 d-block lh-3">
                Baca Artikel Terbaru Kami
              </h2>
              <p className="home_container__subtitle">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dol ad minim veniam, quis
                nostrud exercitation
              </p>
            </Col>
          </Row>{" "}
          <Row>
            {blog.map((item, index) => (
              <Col lg={12} xs={12} key={index}>
                <article className="d-flex post-article mb-3 p-0 border-0 shadow-xss rounded-lg overflow-hidden ">
                  <a href="blog-single.html">
                    <img
                      src="https://via.placeholder.com/370x150.png"
                      alt="blog-image"
                      className="w-100 h-100"
                    />
                  </a>
                  <div className="post-content px-4 py-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h6 className="mb-0 font-xsss text-success fw-600 float-left">
                          Terbaru
                        </h6>
                        <h6 className="mb-0 font-xssss text-grey-500 fw-600 ml-3 float-left">
                          <i className="ti-time mr-2"></i> 24 Mei 2020
                        </h6>
                        <h6 className="mb-0 font-xssss text-grey-500 fw-600 ml-3 float-left">
                          <i className="ti-user mr-2"></i> Admin Satukelas
                        </h6>
                      </div>
                      <div>
                        <a
                          href="/#"
                          className="rounded-xl text-white bg-current w125 p-2 font-xsss text-center fw-500 d-inline-block mb-0"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <h4 className="h4 fw-700 post-title mt-2 mb-2 pr-3">
                      {item.title}
                    </h4>
                    <p className="fontnotepa-xsss fw-400 text-grey-500 lh-26 mt-0 mb-2 pr-3">
                      Human coronaviruses are common and are typically
                      associated with mild illnesses, similar to the common
                      cold. We are digital agency.
                    </p>
                  </div>
                </article>
              </Col>
            ))}
            <Col>
              <a className="separator d-flex flex-row-reverse separate-size">
                Lihat Semua
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Carousel controls={false}>
          {testimoni.map((item, index) => (
            <Carousel.Item key={index} id={index + 1} interval={1000}>
              <div className="carousel_height">
                <Carousel.Caption>
                  <h3 className="mb-5 line-height__testimoni">
                    {item.testimoni}
                  </h3>
                  <p class="testimonial__people">{item.name}</p>
                </Carousel.Caption>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
      <div className="price-wrap pt-lg--7 pt-5 layer-after  mb-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7} className=" mb-3 text-center  ">
              <span className="badge-home pl-3 pr-3 text-uppercase rounded-xl py-2 alert-success d-inline-block text-success mr-1">
                Membership
              </span>
              <h2 className="home_container__title-section h2 pb-3 mb-0 mt-3 d-block lh-3">
                Pilih membership yang tepat untuk anda
              </h2>
              <p className="home_container__subtitle">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dol ad minim veniam, quis
                nostrud exercitation
              </p>
              <div className="custom-control custom-switch pl-0 d-flex justify-content-center">
                <p className="home_status d-inline-block pr-4 mr-0">Personal</p>
                <Form.Check
                  onChange={handleChangeMembership}
                  type="switch"
                  id="custom-switch"
                  value={memberships}
                />

                <p className="home_status d-inline-block pl-3">Korporasi</p>
              </div>
            </Col>
            <Col lg={12} className="offset-lg-1">
              <Row>
                {/* {memberships ? : } */}
                {membership.map((item) => (
                  <Col lg={4}>
                    <Card className="p-4 mb-4 bg-primary border-0 shadow-xss rounded-lg">
                      <Card.Body>
                        <div className="membership_plan__white text-white">
                          {item.membership_name}
                        </div>
                        <div className="price_card__price text-white">
                          {item.price}
                        </div>
                        <h4 className="price_card__subtitle mb-4 text-white">
                          {item.jumlah_member}
                        </h4>
                        {item.benefit.map((items) => (
                          <div className="price_card__price_sub text-white">
                            {items.benefit_info}
                          </div>
                        ))}

                        <Link
                          to="/membership"
                          className="btn btn-block border-0 w-100 bg-white p-3 text-primary button_subscribe rounded-lg d-inline-block btn-light"
                        >
                          Langganan
                        </Link>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default HomePage;
