import React, { useEffect } from "react";
import {
  Row,
  Col,
  Table,
  FormControl,
  Button,
  Container,
  Card,
} from "react-bootstrap";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import { FaTwitter } from "react-icons/fa";
import "../../assets/css/Personal/peraturanPage.css";

function PeraturanDetail() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container className="py-4">
      <Row>
        <Col md={12}>
          <div className="peraturan-detail">
            <h4 className="peraturan-detail__title">Metadata Dokumen</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={6} lg={6}>
          <Card className="card-main">
            <Card.Body className="card-content">
              <div className="card-content__title">Nama</div>
              <div className="card-content__info">
                Perubahan Ketiga Atas Undang-Undang Nomor 8 Tahun 1983 Tentang
                Pajak Pertambahan Nilai Barang Dan Jasa Dan Pajak Penjualan Atas
                Barang Mewah
              </div>
              <div className="card-content__title">Jenis</div>
              <div className="card-content__info">Undang Undang</div>
              <div className="card-content__wrapper">
                <div className="card-content__wrapper-inside">
                  <div className="card-content__title">Nomor</div>
                  <div className="card-content__info">42</div>
                </div>
                <div className="card-content__wrapper-inside">
                  <div className="card-content__title">Tahun</div>
                  <div className="card-content__info">2009</div>
                </div>

                <div className="card-content__wrapper-inside">
                  <div className="card-content__title">Pasal</div>
                  <div className="card-content__info">21 Pasal</div>
                </div>
              </div>
              <div className="card-content__title">LN</div>
              <div className="card-content__info">
                Lembaran Negara Republik Indonesia Tahun 2009 Nomor 150
              </div>
              <div className="card-content__title">TLN</div>
              <div className="card-content__info">-</div>
              <div className="card-content__wrapper">
                <div className="card-content__wrapper-inside">
                  <div className="card-content__title">Disahkan</div>
                  <div className="card-content__info">15 Oktober 2009</div>
                </div>

                <div className="card-content__wrapper-inside">
                  <div className="card-content__title">Diundangkan</div>
                  <div className="card-content__info">15 Oktober 2009</div>
                </div>
              </div>

              <div className="card-content__title">Kompilasi</div>
              <div className="card-content__info">
                <div className="card-content__info-wrapper">
                  <div className="card_content__blue_wrap mr-3">
                    <a className="card-content__blue_wrap__link">
                      Hukum Perdata
                    </a>
                  </div>
                  <div className="card_content__blue_wrap mr-3">
                    <a className="card-content__blue_wrap__link">
                      Hukum Perdagangan
                    </a>
                  </div>
                  <div className="card_content__blue_wrap mr-3">
                    <a className="card-content__blue_wrap__link">Hukum Pajak</a>
                  </div>
                </div>
              </div>
              <div className="card-content__title">Kodifikasi</div>
              <div className="card-content__info">
                <div className="card-content__info-wrapper">
                  <div className="card_content__blue_wrap mr-3">
                    <a className="card-content__blue_wrap__link">
                      Code 17 Agustus
                    </a>
                  </div>
                  <div className="card_content__blue_wrap mr-3">
                    <a className="card-content__blue_wrap__link">
                      Code 20.C Infrastruktur
                    </a>
                  </div>
                  <div className="card_content__blue_wrap mr-3">
                    <a className="card-content__blue_wrap__link">
                      Code 20.D Jasa Keuangan
                    </a>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <Table className="table-wrapper border-0">
            <thead>
              <tr className="table-wrapper__head">
                <th className="table-wrapper__content border-radius__left">
                  Status
                </th>
                <th className="table-wrapper__content border-radius__right">
                  Peraturan
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="table-wrapper__table-content">mengubah</td>
                <td className="table-wrapper__table-content">
                  <a>
                    undang-undang Nomor 8 Tahun 1983 Tentang Pajak Pertambahan
                    Nilai Barang Dan Jasa Dan Pajak Penjualan Atas Barang Mewah
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>

          <div className="share-content">
            <div className="share-content__title">Bagikan Peraturan</div>
            <div className="share-content__form">
              <FormControl
                className="share-content__form-input"
                id="inlineFormInputGroup"
                placeholder="Link"
              />
              <Button className="share-content__form-button">Salin</Button>
            </div>

            <div className="share-content__social">
              {[...Array(4)].map((item, i) => {
                return (
                  <div key={i} className="share-content__social-wrapper mr-3">
                    <FaTwitter className="sosbtn" />
                  </div>
                );
              })}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default PeraturanDetail;
