import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FaDatabase, FaPlus, FaTrophy } from "react-icons/fa";
import { GiTrophyCup } from "react-icons/gi";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Personal/LeaderboardUser.css";
import { useSelector } from "react-redux";

function LeaderboardUser({ item }) {
  const history = useHistory();
  const users = useSelector((state) => state.users);
  const [trigger, setTrigger] = useState(true);
  const [myLeaderboard, setMyLeaderboard] = useState();

  useEffect(() => {
    AuthIsLogin(history);
  }, []);

  useEffect(() => {
    const leaderboard = users.usersDataId.leaderboard;
    console.log(leaderboard);
    if (leaderboard && trigger) {
      setTrigger(false);
      setMyLeaderboard(leaderboard[0]);
    }
  }, [users.usersDataId]);
  return (
    <>
      <Container className="dashboard_page__container">
        <Row>
          <Col sm={12} md={6}>
            <h3 className="h3 leaderboard__section_title mb-0">
              Peringkat Pengguna
            </h3>
            <div className="rank_top">5 Peringkat Teratas</div>
            {[...Array(3)].map((value, index) => {
              return (
                <div key={index} className="rank__wrapper highlight">
                  <div className="rank_img__wrapper">
                    <div className="rank_num">{index + 1}</div>
                  </div>
                  <div>
                    <div className="rank__username">John Doe</div>
                    <div className="rank__join_date">Bergabung: 02/06/2020</div>
                  </div>
                  <div className="rank_poin__wrapper">
                    <div className="rank__total_poin">Total Poin</div>
                    <div className="rank__total_poin_num">20,901</div>
                  </div>
                </div>
              );
            })}

            {[...Array(2)].map((value, index) => {
              return (
                <div key={index} className="rank__wrapper">
                  <div className="rank_img__wrapper">
                    <div className="rank_num">1</div>
                  </div>
                  <div>
                    <div className="rank__username">John Doe</div>
                    <div className="rank__join_date">Bergabung: 02/06/2020</div>
                  </div>
                  <div className="rank_poin__wrapper">
                    <div className="rank__total_poin">Total Poin</div>
                    <div className="rank__total_poin_num">20,901</div>
                  </div>
                </div>
              );
            })}
          </Col>
          <Col sm={12} md={6}>
            {myLeaderboard ? (
              <div className="your_info__wrapper">
                <div className="your_info__title">Info Kamu</div>
                <div className="your_info___card_wrapper">
                  <div className="your_info__icon_wrapper">
                    <GiTrophyCup />
                  </div>
                  <div className="your_info__detail_wrapper">
                    <div className="your_info__detail_title">
                      Peringkat Anda
                    </div>
                    <div className="yout_info__detail_num">1/50</div>
                  </div>
                </div>
                <div className="your_info___card_wrapper">
                  <div className="your_info__icon_wrapper">
                    <FaDatabase />
                  </div>
                  <div className="your_info__detail_wrapper">
                    <div className="your_info__detail_title">Total Poin</div>
                    <div className="yout_info__detail_num">
                      {myLeaderboard.points}
                    </div>
                  </div>
                </div>
                <div className="your_info___card_wrapper">
                  <div className="your_info__icon_wrapper">
                    <FaPlus />
                  </div>
                  <div className="your_info__detail_wrapper">
                    <div className="your_info__detail_title">
                      Tambahan Poin Bulan Ini
                    </div>
                    <div className="yout_info__detail_num">
                      {myLeaderboard.points}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {/*  */}

            <div className="dashboard__section_title">Pencapaian Kamu</div>

            <div className="dashboard__section_subtitle">
              Pencapaian Anda di Bulan Ini
            </div>
            <div className="your_info__achievement_card">
              <div className="your_info__achievement__icon_wrapper">
                <FaTrophy />
              </div>
              <div>
                <div className="your_info__achievement__title">Flinto</div>
                <div className="your_info__achievement__subtitle">
                  Pengenalan Desain Aplikasi
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LeaderboardUser;
