import { call, put, takeEvery } from "redux-saga/effects";
import { PromiseService } from "../../services/PromiseService";
import { WEBINARS_DATA, WEBINARS_DATA_LINK } from "../config/WebinarsConfig";
import {
  getWebinarsDataSuccess,
  getWebinarsDataIdSuccess,
  getWebinarsDataFail,
} from "../actions/WebinarsActions";

function* workerWebinarsData(params) {
  try {
    const response = yield call(
      PromiseService.getData,
      `${WEBINARS_DATA_LINK}/${params.value}`
    );
    if (response.status === 200 && response.data) {
      yield put(getWebinarsDataIdSuccess(response.data));
    } else {
      yield put(getWebinarsDataFail(response));
    }
  } catch (error) {
    yield put(getWebinarsDataFail(error));
  }
}

export const watcherWebinars = [takeEvery(WEBINARS_DATA, workerWebinarsData)];
