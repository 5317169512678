import storage from "redux-persist/lib/storage";
import createEncryptor from "redux-persist-transform-encrypt";
import { createBlacklistFilter } from "redux-persist-transform-filter";

const saveAuthSubsetBlacklistFilter = createBlacklistFilter();

const REDUX_PERSIST = {
  active: true,
  reducerVersion: "1.0",
  storeConfig: {
    key: "root",
    storage,
    whitelist: ["layout"],
    transforms: [saveAuthSubsetBlacklistFilter],
  },
};

export default REDUX_PERSIST;
