import React from "react";
import "../../assets/css/Courses/zoomDisplay.css";
import { Container } from "react-bootstrap";
import JamBoard from "../../components/JamBoard";

function JamBoardDetail() {
  return (
    <Container>
      <JamBoard />
    </Container>
  );
}

export default JamBoardDetail;
