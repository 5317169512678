import React, { useEffect, useState } from "react";
import { Card, Button, Dropdown } from "react-bootstrap";
import { FaBookOpen, FaClock, FaInfoCircle, FaBookmark } from "react-icons/fa";
import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import { getCoursesData } from "../redux/actions/CoursesActions";
import { useDispatch, useSelector } from "react-redux";
import ProgressCourse from "./ProgressCourse";
import { convertHours, slugify } from "../helper/functions";
import "../assets/css/Courses/courseCard.css";

function CourseCard({
  item,
  id,
  border,
  task,
  selectedValue,
  progress,
  assId,
  status,
  className,
  changeProgress,
}) {
  // const history = useHistory();
  const [param, setParam] = useState(id);
  const [membershipsStatus, setMembershipsStatus] = useState("none");
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courses);
  const users = useSelector((state) => state.users);
  // const slug = slugify(item?.title);

  useEffect(() => {
    if (assId) {
      dispatch(getCoursesData({ type: "id", param: `/${item.course_id}` }));
    }
  }, []);

  useEffect(() => {
    const memberships = users.usersDataId.memberships;
    if (memberships) {
      if (memberships.length > 0) setMembershipsStatus(memberships[0].status);
    }
  }, [users]);

  useEffect(() => {
    if (assId) {
      if (courses.coursesDataId.topics) {
        let topicIndex = 0;
        let materialIndex = 0;
        const topics = courses.coursesDataId.topics;
        for (let i = 0; i < topics.length; i++) {
          const topic = topics[i];
          for (let j = 0; j < topic.materials.length; j++) {
            const material = topic.materials[j];
            if (material.assignment_id === assId) {
              topicIndex = i;
              materialIndex = j;
              break;
            }
          }
        }
        setParam(`${id}/assignments/${assId}_${topicIndex}_${materialIndex}`);
      }
    }
  }, [courses]);

  return (
    <Card className={`${className} course-card course_card__wrapper`}>
      {/* Condition in task page */}
      {item?.assignment === undefined ? (
        <>
          <Link to={`/course/${param}/`} style={{ textDecoration: "none" }}>
            <div className="course_card__thumbnail_wrapper">
              <img
                style={{ border: `${border}` }}
                className="course_card__thumbnail "
                src={
                  item?.thumbnail
                    ? item.thumbnail
                    : "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/4270648989474070.jpeg"
                }
                // width="255px"
                height="153px"
                alt={
                  item?.thumbnail
                    ? item.thumbnail
                    : "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/4270648989474070.jpeg"
                }
              />
              {membershipsStatus === "active" ? (
                ""
              ) : (
                <div className="course_card__wishlist">
                  <FaBookmark className="fill-red" />
                </div>
              )}

              {membershipsStatus === "active" ? (
                ""
              ) : (
                <div className="course_card__learningpath_wrapper">
                  {/* {item?.learning_paths
              ? item.learning_paths.map((items) => items.title)
              : null} */}
                  Member
                </div>
              )}

              <div className="course_card__infocourse_wrapper">
                <FaInfoCircle className="fill-white" />
              </div>
            </div>
          </Link>
          <div className="course_card__info_wrapper">
            <div className="course_card__info_top">
              <div className="course_card__lesson_num">
                <FaBookOpen style={{ marginRight: "8px" }} />
                {status === undefined
                  ? item?.lessons_count
                  : item?.total_materials}{" "}
                Pelajaran
              </div>
              <div className="course_card__lesson_hours">
                <FaClock style={{ marginRight: "8px" }} />
                {item?.total_duration
                  ? convertHours(item?.total_duration)
                  : "-"}
              </div>
            </div>
            <div className="course_card__wrapper_two__section">
              <h4 className="h4 course_card__lesson_title">
                {status === undefined ? item?.title : item?.course}
              </h4>
              {progress === undefined ? (
                ""
              ) : (
                <div id="progress-course">
                  <ProgressCourse now={item?.progress} />
                </div>
              )}

              <div className="course_card__lp_price__wrapper">
                <label className="course_card__learning_path">
                  {/* {status === undefined
                    ? item?.learning_paths
                      ? item.learning_paths.map((items) => items.title)
                      : null
                    : item?.status} */}
                  {item?.learning_paths
                    ? item.learning_paths.map((items, index) => (
                        <div key={index}>{items.title}</div>
                      ))
                    : null}
                </label>
                {membershipsStatus === "active" ? (
                  <FaBookmark className="fill-red" />
                ) : (
                  <div className="course_card__lesson_price">{item?.price}</div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex flew-row">
          <Link
            to={`/course/${param}/`}
            style={{ textDecoration: "none", flex: 9 }}
          >
            <div className="course_card__wrapper_two__section p-4">
              <h4 className="course_card__lesson_title h4">
                {item?.assignment === undefined ? "" : item?.assignment}
              </h4>
              {progress === undefined ? (
                ""
              ) : (
                <div id="progress-course">
                  <ProgressCourse now={item?.progress} />
                </div>
              )}

              <div className="course_card__lp_price__wrapper">
                {membershipsStatus === "active" ? (
                  <FaBookmark className="fill-red" />
                ) : (
                  <div className="course_card__lesson_price">{item?.price}</div>
                )}
              </div>
            </div>
          </Link>
          <div
            style={{
              flex: 1,
              backgroundColor: "#00000011",
              borderRadius: "16px",
            }}
            onClick={() =>
              changeProgress(item?.assignment, item?.assignment_id)
            }
          >
            <div className="course_card__learning_path vertical-text-wrapper">
              <p className="vertical-text">{item?.status}</p>
            </div>
          </div>
        </div>
      )}
      {item?.assignment === undefined ? (
        <div className="course-hover__container">
          <div className="course-hover__wrapper">
            <div className="course-hover__topInfo">
              <div className="course-hover__logo-wrapper">
                <img
                  className="course-hover__thumbnail"
                  src={item?.thumbnail}
                  alt={item?.thumbnail}
                />
              </div>
              <div className="course-hover__title-wrapper">
                <div className="title">{item?.title}</div>
                <div className="course-hover__tutor-wrapper">
                  dengan{" "}
                  <span className="tutor-name">
                    {/* {item?.instructors.map((items) => items?.name)} */}
                  </span>
                </div>
              </div>
            </div>
            <div className="course-category__hover">
              <label className="course-hover__tutor-wrapper course-hover__container-category ">
                {item?.learning_paths
                  ? item.learning_paths.map((items, index) => (
                      <div key={index}>{items.title}</div>
                    ))
                  : null}
              </label>
            </div>
            <div className="course-hover__summary">Tujuan Pembelajaran</div>

            <div className="course-hover__objectives">
              <ul>
                {/* {item?.objectives.map((items) => (
                  <li>{items}</li>
                ))} */}
              </ul>
            </div>
            <div className="course-hover__bottomInfo">
              <div className="course-hover__additionalInfo">
                <div>
                  {" "}
                  {item?.total_duration
                    ? convertHours(item?.total_duration)
                    : "-"}
                </div>
                <div>{item ? item.lessons_count : 0} Pelajaran</div>
                <div>{item?.level}</div>
              </div>
              <Button
                href={`/course/${item?.course_id}`}
                className="learning_path__button"
              >
                Lihat Course
              </Button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Card>
  );
}

export default CourseCard;
