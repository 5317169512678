import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TodoList from "../../components/ToDoList";
import InProgress from "../../components/InProgress";
import Done from "../../components/Done";
import { AuthIsLogin, AuthData } from "../../helper/auth";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { startAssigment } from "../../redux/actions/AssignmentActions";
import { getUsersData } from "../../redux/actions/UsersActions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function MyTask() {
  const history = useHistory();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const assignments = useSelector((state) => state.assignments);
  const [trigger, setTrigger] = useState(true);
  const [triggerRedux, setTriggerRedux] = useState(false);
  const [myTask, setMyTask] = useState({
    todo: [],
    ongoing: [],
    completed: [],
  });

  useEffect(() => {
    AuthIsLogin(history);
  }, []);

  useEffect(() => {
    if (triggerRedux && assignments.assignmentDataStart === "success") {
      setTriggerRedux(false);
      setTrigger(true);
      dispatch(getUsersData(AuthData().userId));
    }
  }, [assignments]);

  useEffect(() => {
    console.log(users.usersDataId.my_tasks, "masuk");
    const tasks = users.usersDataId.my_tasks;
    const myTaskNew = { todo: [], ongoing: [], completed: [] };
    if (tasks && trigger) {
      for (let i = 0; i < tasks.length; i++) {
        if (tasks[i].status === "todo") {
          myTaskNew.todo.push(tasks[i]);
        }
        if (tasks[i].status === "ongoing") {
          myTaskNew.ongoing.push(tasks[i]);
        }
        if (tasks[i].status === "completed") {
          myTaskNew.completed.push(tasks[i]);
        }
      }
      setTrigger(false);
      setMyTask(myTaskNew);
    }
  }, [users.usersDataId]);

  function changeProgress(title, id) {
    displayPopup(
      true,
      `Ubah status tugas ${title} menjadi 'Sedang Dilakukan'?`,
      true,
      id
    );
  }

  function displayPopup(cancel, title, confirm, id) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      cancelButtonText: "Batal",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: "Oke",
    }).then((value) => {
      if (value.isConfirmed) {
        const payload = {
          assignment_id: id,
          user_id: AuthData().userId,
        };
        setTriggerRedux(true);
        dispatch(startAssigment(payload));
      }
    });
  }

  return (
    <Container className="dashboard_page__container">
      <h2 className="h2 dashboard__section_title mb-3">Tugas Saya</h2>
      <Row>
        <Col lg={6} xl={4} md={6} className=" mb-2 mt-2">
          <TodoList data={myTask.todo} changeProgress={changeProgress} />
        </Col>
        <Col lg={6} xl={4} md={6} className="mb-2 mt-2">
          <InProgress data={myTask.ongoing} />
        </Col>
        <Col lg={6} xl={4} md={6} className="mb-2 mt-2">
          <Done data={myTask.completed} />
        </Col>
      </Row>
    </Container>
  );
}

export default MyTask;
