import React from "react";
import { Card, Row } from "react-bootstrap";
import "../assets/css/Personal/myTask.css";
import CourseCard from "./CourseCard";
function ToDoList({ data, changeProgress }) {
  function changeProgressTodo(title, id) {
    changeProgress(title, id);
  }
  return (
    <Card className="p-0 bg-white card_round__task  shadow-xs border-0">
      <Card.Body className="p-3 border-top-lg border-size-lg border-primary p-0">
        <h4 className="h4 container_done__task mt-2 d-inline-block">
          Belum Dilakukan
        </h4>
      </Card.Body>
      <Row id="task">
        {data.map((value, index) => {
          return (
            <CourseCard
              className="blue"
              key={index}
              task={1}
              item={value}
              id={value.course_id}
              assId={value.assignment_id}
              changeProgress={changeProgressTodo}
            />
          );
        })}
      </Row>
    </Card>
  );
}
export default ToDoList;
