export const USERS_DATA_LINK = "users";

//get
export const USERS_DATA = "USERS_DATA";
export const USERS_DATA_ID_SUCCESS = "USERS_DATA_ID_SUCCESS";
export const USERS_DATA_SUCCESS = "USERS_DATA_SUCCESS";

//post
export const USERS_DATA_POST = "USERS_DATA_POST";
export const USERS_DATA_POST_SUCCESS = "USERS_DATA_POST_SUCCESS";

//put
export const USERS_DATA_PUT = "USERS_DATA_PUT";
export const USERS_DATA_PUT_SUCCESS = "USERS_DATA_PUT_SUCCESS";

//fail
export const USERS_DATA_FAIL = "USERS_DATA_FAIL";

//remove userdata
export const USERS_DATA_ID_REMOVE = "USERS_DATA_ID_REMOVE";
