import AudioPlayer from "react-h5-audio-player";
import { Container } from "react-bootstrap";
import "react-h5-audio-player/lib/styles.css";
import "../assets/css/Courses/audioloader.css";

function AudioLoader({ url }) {
  return (
    <Container className="audio-loader-main-div lesson-container">
      <AudioPlayer src={url} autoPlay={false} loop={false} />
    </Container>
  );
}

export default AudioLoader;
