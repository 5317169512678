import { call, put, takeEvery } from "redux-saga/effects";
import { PromiseService } from "../../services/PromiseService";
import {
  ASSIGNMENT_DATA_LINK,
  //get
  ASSIGNMENT_DATA,
  //post
  ASSIGNMENT_DATA_POST,
  // put
  ASSIGNMENT_DATA_PUT,
  // delete
  ASSIGNMENT_DATA_DELETE,
  // start
  ASSIGNMENT_DATA_START,
} from "../config/AssignmentConfig";
import {
  //get
  getAssignmentDataSuccess,
  getAssignmentDataIdSuccess,
  getAssignmentDataFail,
  //post
  postAssignmentDataSuccess,
  postAssignmentDataFail,
  // put
  putAssignmentDataSuccess,
  putAssignmentDataFail,
  // delete
  deleteAssignmentDataSuccess,
  deleteAssignmentDataFail,
  // start
  startAssigmentSuccess,
  startAssigmentFail,
} from "../actions/AssignmentActions";

function* workerAssignmentDataPost(params) {
  const body = params.value;
  try {
    const response = yield call(
      PromiseService.postData,
      ASSIGNMENT_DATA_LINK + "/submit",
      body
    );
    if (response.status === 201 && response.data) {
      yield put(postAssignmentDataSuccess(response.data));
    } else {
      yield put(postAssignmentDataFail(response));
    }
  } catch (error) {
    yield put(postAssignmentDataFail(error));
  }
}

function* workerAssignmentDataStart(params) {
  const body = params.value;
  try {
    const response = yield call(
      PromiseService.postData,
      ASSIGNMENT_DATA_LINK + "/start",
      body
    );
    if (response.status === 201 && response.data) {
      yield put(startAssigmentSuccess(response.data));
    } else {
      yield put(startAssigmentFail(response));
    }
  } catch (error) {
    yield put(startAssigmentFail(error));
  }
}

function* workerAssignmentData(params) {
  if (params.value) {
    let url = `${ASSIGNMENT_DATA_LINK}/${params.value}`;
    if (params.value.param)
      url = `${ASSIGNMENT_DATA_LINK}${params.value.param}`;
    try {
      const response = yield call(PromiseService.getData, url);
      if (response.status === 200 && response.data) {
        if (params.value.param)
          yield put(getAssignmentDataSuccess(response.data));
        else yield put(getAssignmentDataIdSuccess(response.data));
      } else {
        yield put(getAssignmentDataFail(response));
      }
    } catch (error) {
      yield put(getAssignmentDataFail(error));
    }
  } else {
    try {
      const response = yield call(PromiseService.getData, ASSIGNMENT_DATA_LINK);
      if (response.status === 200 && response.data) {
        yield put(getAssignmentDataSuccess(response.data));
      } else {
        yield put(getAssignmentDataFail(response));
      }
    } catch (error) {
      yield put(getAssignmentDataFail(error));
    }
  }
}

// put
function* workerAssignmentDataPut(params) {
  const body = params.value.body;
  const id = params.value.id;
  try {
    const response = yield call(
      PromiseService.putData,
      `${ASSIGNMENT_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      yield put(putAssignmentDataSuccess(response.data));
    } else {
      yield put(putAssignmentDataFail(response));
    }
  } catch (error) {
    yield put(putAssignmentDataFail(error));
  }
}

// delete
function* workerAssignmentDelete(params) {
  const id = params.value;
  try {
    const response = yield call(
      PromiseService.deleteData,
      `${ASSIGNMENT_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      yield put(deleteAssignmentDataSuccess(response.data));
    } else {
      yield put(deleteAssignmentDataFail(response));
    }
  } catch (error) {
    yield put(deleteAssignmentDataFail(error));
  }
}

export const watcherAssignment = [
  takeEvery(ASSIGNMENT_DATA_POST, workerAssignmentDataPost),
  takeEvery(ASSIGNMENT_DATA, workerAssignmentData),
  takeEvery(ASSIGNMENT_DATA_PUT, workerAssignmentDataPut),
  takeEvery(ASSIGNMENT_DATA_DELETE, workerAssignmentDelete),
  takeEvery(ASSIGNMENT_DATA_START, workerAssignmentDataStart),
];
