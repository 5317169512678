import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Card, Table } from "react-bootstrap";
import QuizDetails from "../../components/QuizDetails";
import QuizQuestion from "../../components/QuizQuestion";
// import QuizResultAttempts from "../../components/QuizResultAttempts";
import QuizResult from "../../components/QuizResult";
import "../../assets/css/Courses/quiz.css";
import { useParams } from "react-router-dom";
import { GoKebabVertical } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuizData,
  getQuizAttempt,
  postStartQuiz,
  postQuizScore,
  removeAttemptScoreQuiz,
} from "../../redux/actions/QuizActions";
import { AuthIsLogin, AuthData } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getCoursesData } from "../../redux/actions/CoursesActions";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function QuizPage() {
  const { id_material, course_id } = useParams();
  const [minimizeValue, setMinimizeValue] = useState(false);
  const [count, setCount] = useState(0);
  const [allAnswer, setAllAnswer] = useState([]);
  const [attempts, setAttempts] = useState(["empty"]);
  const [attempt, setAttempt] = useState();
  const [quizScore, setQuizScore] = useState([]);
  const [quizDataId, setQuizDataId] = useState([]);
  const [doneQuiz, setDoneQuiz] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [displayResult, setDisplayResult] = useState(false);
  const quizzes = useSelector((state) => state.quizzes);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    AuthIsLogin(history);
    dispatch(getQuizData(id_material.split("_")[0]));
    if (localStorage.getItem(`_${AuthData().userId + id_material}`)) {
      setAllAnswer(
        JSON.parse(localStorage.getItem(`_${AuthData().userId + id_material}`))
      );
    }
  }, []);

  useEffect(() => {
    if (quizzes.quizDataId) {
      setQuizDataId(quizzes.quizDataId);
    }
    if (quizzes.quizDataId?.attempts) {
      setAttempts(quizzes.quizDataId.attempts);
    }
    if (quizzes.quizAttemptData?.attempt_id) {
      setAttempt(quizzes.quizAttemptData);
      setIsDisplay(true);
    }
    if (quizzes.quizScoreData?.attempt_id && doneQuiz) {
      setQuizScore(quizzes.quizScoreData);
      setDisplayResult(true);
      setDoneQuiz(false);
      setCount(0);
      setAllAnswer([]);
      getCoursesDataId();
      dispatch(removeAttemptScoreQuiz());
      dispatch(getQuizData(id_material.split("_")[0]));
    }
  }, [quizzes]);

  function getCoursesDataId() {
    dispatch(getCoursesData({ type: "id", param: `/${course_id}` }));
  }

  useEffect(() => {
    resetFlags();
    dispatch(getQuizData(id_material.split("_")[0]));
  }, [location]);

  // useEffect(() => {
  //   dispatch(getQuizData(id_material.split("_")[0]));
  // }, [attempts]);

  const resetFlags = () => {
    setIsDisplay(false);
    setDisplayResult(false);
    setDoneQuiz(false);
  };
  const resultHistory = (newQuizScore) => {
    setIsDisplay(true);
    setDisplayResult(true);
    setQuizScore(newQuizScore);
  };

  useEffect(() => {
    if (doneQuiz) {
      let payload = {
        answers: allAnswer,
        attempt_id: attempts[0].attempt_id,
      };
      dispatch(postQuizScore(payload));
      localStorage.removeItem(`_${AuthData().userId + id_material}`);
    }
  }, [doneQuiz]);

  const handleMinimize = (minimizeValue) => {
    setMinimizeValue(minimizeValue);
  };

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      cancelButtonText: "Tidak",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (value.isConfirmed) {
        const payload = {
          quiz_id: id_material,
          user_id: AuthData().userId,
        };
        dispatch(postStartQuiz(payload));
        setIsDisplay(true);
      }
    });
  }
  window.addEventListener("beforeunload", (ev) => {
    ev.preventDefault();
    return (ev.returnValue = "Anda yakin akan keluar?");
  });
  function startAttempt() {
    if (attempts[0]?.status === "ongoing") {
      dispatch(getQuizAttempt(attempts[0].attempt_id));
    } else if (attempts[0]?.status === "completed" || attempts.length <= 0) {
      displayPopup(true, "Mulai percobaan kuis baru?", true, "Ya");
    }
  }
  return (
    <Container className="quiz-card lesson-container">
      {/* <div className="video-loader-title">{quizDataId.title ? quizDataId.title : ""}</div> */}

      {/* <Card.Body className="quiz-card-body"> */}

      {isDisplay ? (
        <Row className="quiz-parent-row align-items-center justify-content-center">
          {displayResult ? (
            <>
              <QuizResult
                quizScore={quizScore}
                displayResult={displayResult}
                resetFlags={resetFlags}
                getCoursesDataId={getCoursesDataId}
              />
            </>
          ) : (
            <>
              {/* <Button
              onClick={() => handleMinimize(!minimizeValue)}
              className="button_minimize"
            >
              {minimizeValue ? "Maximize" : "Minimize"}
            </Button> */}
              <QuizDetails
                minimizevalue={minimizeValue}
                quizTime={
                  attempt?.remaining_time
                    ? attempt.remaining_time.toFixed(0) * 1000
                    : quizDataId.duration
                    ? quizDataId.duration * 1000
                    : 99999
                }
                setDoneQuiz={setDoneQuiz}
                title={quizDataId.title ? quizDataId.title : ""}
              />
              <QuizQuestion
                questData={
                  quizDataId.questions ? quizDataId.questions[count] : data[0]
                }
                minimizevalue={minimizeValue}
                count={count}
                total={quizDataId.questions ? quizDataId.questions.length : 0}
                setCount={setCount}
                allAnswer={allAnswer}
                setAllAnswer={setAllAnswer}
                setDoneQuiz={setDoneQuiz}
                quizKey={AuthData().userId + id_material}
              />
            </>
          )}
        </Row>
      ) : (
        <Col
          xs={11}
          className="quiz-quest-main-col d-flex flex-column justify-content-start align-items-start"
        >
          <Button disabled variant="info" style={{ width: "100%" }}>
            Percobaan Sebelumnya
          </Button>
          <div
            className="quiz-quest-wrapper-scrollbar"
            style={{ width: "100%", height: "80vh", overflow: "auto" }}
          >
            <Table responsive striped hover>
              <thead>
                <tr>
                  {/*<th>Tipe</th>*/}
                  <th>No</th>
                  <th>Tanggal Dimulai</th>
                  <th>Nilai</th>
                  <th>Hasil</th>
                </tr>
              </thead>
              <tbody>
                {quizzes.quizDataId?.attempts
                  ? quizzes.quizDataId.attempts.map((value, index) => {
                      return (
                        <tr
                          style={{ cursor: "pointer" }}
                          onClick={() => resultHistory(value)}
                        >
                          {/*<td>
                          <div className="text-center ">
                            <FaCheck className="fill-success" />
                          </div>
                        </td>*/}
                          <td>{index + 1}</td>
                          <td>
                            {moment
                              .utc(value.created_at)
                              .format("YYYY-MM-DD HH:mm")}
                          </td>
                          <td>
                            {value.status === "ongoing"
                              ? "Sedang Berlangsung"
                              : value.earned_mark}
                          </td>
                          <td>
                            {value.status === "ongoing" ? (
                              <Button
                                size="sm"
                                disabled
                                variant="info"
                                style={{ width: "100%", fontSize: "0.7rem" }}
                              >
                                Sedang Berlangsung
                              </Button>
                            ) : (
                              <Button
                                size="sm"
                                disabled
                                variant={`${
                                  value.result === "Pass" ? "success" : "danger"
                                }`}
                                style={{ width: "100%" }}
                              >
                                {value.result === "Pass" ? "Lulus" : "Gagal"}
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </Table>
          </div>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "100%", width: "100%" }}
          >
            <Button onClick={startAttempt}>
              {attempts[0]?.status === "ongoing" ? "Lanjutkan" : "Mulai"} Ujian
            </Button>
          </div>
        </Col>
      )}
      {/* </Card.Body> */}
    </Container>
  );
}

export default QuizPage;

const data = [
  {
    choice_a: "null",
    choice_b: "null",
    choice_c: "null",
    choice_d: "null",
    correct_choice: "null",
    course_id: "null",
    created_at: "null",
    question: "null",
    quiz_id: "null",
    theme_id: "null",
    title: "null",
  },
];
