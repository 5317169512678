import React, { useState } from "react";
import { Tabs, Tab, Form, Button, Container } from "react-bootstrap";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../assets/css/Courses/TabsCourseDetail.css";
import RelatedFileSection from "../components/RelatedFileSection";
function TabsCourseDetail({ content, item }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  // console.log(editorState.getCurrentContent().getPlainText());
  console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  return (
    <Container>
      <Tabs
        defaultActiveKey="overview"
        transition={false}
        id="noanim-tab-example"
        className=""
      >
        <Tab tabClassName="" eventKey="overview" title="Overview">
          <div className="inside my-5">
            <div className="info mt-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            </div>
          </div>
        </Tab>
        {/* <Tab tabClassName="text-blacks" eventKey="qna" title="Q&A">
          <div className="inside my-5">
            <div className="info mt-3">
              <h5 className="mb-4">Tanya Sebuah Pertanyaan</h5>
              <form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    placeholder="Masukkan Judul Pertanyaan"
                  />
                </Form.Group>
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                />
                <textarea
                  disabled
                  value={draftToHtml(
                    convertToRaw(editorState.getCurrentContent())
                  )}
                />
                <div className="text-right">
                  <Button className="mt-3 px-4 text-right">Kirim</Button>
                </div>
              </form>
            </div>
          </div>
        </Tab>
        <Tab tabClassName="text-blacks" eventKey="notebook" title="Notebook">
          <div className="inside my-5">
            <div className="info mt-3">
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Tulis Catatan</Form.Label>
                  <Form.Control as="textarea" rows={5} />
                </Form.Group>
              </Form>
            </div>
          </div>
        </Tab>
        <Tab tabClassName="text-blacks" eventKey="transcript" title="Transcript">
          <div className="inside my-5">
            <div className="info mt-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </div>
          </div>
        </Tab> */}
        <Tab tabClassName="text-blacks" eventKey="handout" title="HandOut">
          <div className="inside my-5">
            <div className="info mt-3">
              <RelatedFileSection />
            </div>
          </div>
        </Tab>
      </Tabs>
    </Container>
  );
}
export default TabsCourseDetail;
