import { call, put, takeEvery } from "redux-saga/effects";
import { PromiseService } from "../../services/PromiseService";
import {
  USERS_DATA,
  USERS_DATA_POST,
  USERS_DATA_PUT,
  USERS_DATA_LINK,
} from "../config/UsersConfig";
import {
  getUsersDataSuccess,
  getUsersDataIdSuccess,
  postUsersDataSuccess,
  putUsersDataSuccess,
  getUsersDataFail,
} from "../actions/UsersActions";

//get
function* workerUsersData(params) {
  if (params.value) {
    try {
      const response = yield call(
        PromiseService.getData,
        `${USERS_DATA_LINK}/${params.value}`
      );
      if (response.status === 200 && response.data) {
        yield put(getUsersDataIdSuccess(response.data));
      } else {
        yield put(getUsersDataFail(response));
      }
    } catch (error) {
      yield put(getUsersDataFail(error));
    }
  } else {
    try {
      const response = yield call(PromiseService.getData, USERS_DATA_LINK);
      if (response.status === 200 && response.data) {
        yield put(getUsersDataSuccess(response.data));
      } else {
        yield put(getUsersDataFail(response));
      }
    } catch (error) {
      yield put(getUsersDataFail(error));
    }
  }
}

//post
function* workerUsersDataPost(params) {
  const body = params.value;
  try {
    const response = yield call(
      PromiseService.postData,
      USERS_DATA_LINK,
      body,
      true
    );
    if (response.status === 201 && response.data) {
      yield put(postUsersDataSuccess(response.data));
    } else {
      yield put(getUsersDataFail(response));
    }
  } catch (error) {
    yield put(getUsersDataFail(error));
  }
}

// put
function* workerUsersDataPut(params) {
  const body = params.value.body;
  const id = params.value.id;
  try {
    const response = yield call(
      PromiseService.putData,
      `${USERS_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      yield put(putUsersDataSuccess(response.data));
    } else {
      yield put(getUsersDataFail(response));
    }
  } catch (error) {
    yield put(getUsersDataFail(error));
  }
}

export const watcherUsers = [
  takeEvery(USERS_DATA, workerUsersData),
  takeEvery(USERS_DATA_POST, workerUsersDataPost),
  takeEvery(USERS_DATA_PUT, workerUsersDataPut),
];
