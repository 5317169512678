import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import "../assets/css/Personal/discussionCard.css";

function DiscussionCard() {
  return (
    <Card className="dc-card">
      <Card.Body className="dc-card-body dc-card-body-top">
        <Row className="dc-head">
          <Col sm={3} md={3} className="pr-0 d-flex align-items-center">
            <Col xs={6}>
              <Card.Img
                src="https://luma-vue.demo.frontendmatter.com/images/paths/angular_64x64.svg"
                className="dc-card-img"
                alt="https://luma-vue.demo.frontendmatter.com/images/paths/angular_64x64.svg"
              />
            </Col>
            <Col xs={6}>
              <Card.Title className="dc-card-title">Name</Card.Title>
              <Card.Text className="dc-card-text">2 days ago</Card.Text>
            </Col>
          </Col>
          <Col
            className="d-flex flex-column justify-content-center dc-align-item"
            md={7}
            sm={5}
          >
            <Card.Text variant="primary" className="dc-card-text">
              Using Angular HttpClientModule instead of HttpModule
            </Card.Text>
            <Button variant="outline-dark" className="dc-card-btn" size="md">
              Angular Fundumental
            </Button>
          </Col>
          <Col
            className="d-flex flex-column justify-content-center dc-align-item-ans"
            md={2}
            xs={12}
          >
            <Card.Title className="dc-card-title">2</Card.Title>
            <Card.Text className="dc-card-text">Answer</Card.Text>
          </Col>
        </Row>
      </Card.Body>
      <Card.Body className="dc-card-body dc-card-body-bot">
        <Row className="dc-head">
          <Col sm={3} md={3} className="pr-0 d-flex align-items-center">
            <Col xs={6}>
              <Card.Img
                alt="https://luma-vue.demo.frontendmatter.com/images/paths/angular_64x64.svg"
                src="https://luma-vue.demo.frontendmatter.com/images/paths/angular_64x64.svg"
                className="dc-card-img"
              />
            </Col>
            <Col xs={6}>
              <Card.Title className="dc-card-title">Name</Card.Title>
              <Card.Text className="dc-card-text">3 days ago</Card.Text>
            </Col>
          </Col>
          <Col
            className="d-flex flex-column justify-content-center dc-align-item"
            md={7}
            sm={5}
          >
            <Card.Text variant="primary" className="dc-card-text">
              Why am I getting an error when trying to install
              angular/http@2.4.2
            </Card.Text>
            <Button variant="outline-dark" className="dc-card-btn" size="md">
              Angular
            </Button>
          </Col>
          <Col
            className="d-flex flex-column justify-content-center text-center dc-align-item-ans"
            md={2}
            xs={12}
          >
            <Card.Title className="dc-card-title">1</Card.Title>
            <Card.Text className="dc-card-text">Answer</Card.Text>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
export default DiscussionCard;
