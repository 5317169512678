import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import ReactECharts from "echarts-for-react";
import { useSelector } from "react-redux";
import "../../assets/css/Personal/progressPage.css";
import MyProgressCourse from "../../components/MyProgressCourse";
function ProgressPage() {
  const users = useSelector((state) => state.users);
  const [trigger, setTrigger] = useState(true);
  const [myCourses, setMyCourses] = useState({
    todo: [],
    ongoing: [],
    completed: [],
  });
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  useEffect(() => {
    const courses = users.usersDataId.my_courses;
    const myCoursesNew = { ...myCourses };
    if (courses && trigger) {
      for (let i = 0; i < courses.length; i++) {
        if (courses[i].status === "ongoing") {
          console.log("======================>", courses[i].progress);
          if (courses[i].progress > 0) myCoursesNew.ongoing.push(courses[i]);
          else myCoursesNew.todo.push(courses[i]);
        }
        if (courses[i].status === "completed") {
          myCoursesNew.completed.push(courses[i]);
        }
      }
      setTrigger(false);
      setMyCourses(myCoursesNew);
    }
  }, [users.usersDataId]);
  const [count, setCount] = useState(0);
  const [progress, setProgress] = useState("todo");
  const option = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "horizontal",
      left: "left",
      data: ["Sedang Berlangsung", "Sudah Selesai", "Belum Dimulai"],
    },
    series: [
      {
        name: "Kursus",
        type: "pie",
        radius: "55%",
        center: ["50%", "60%"],
        data: [
          { value: myCourses.ongoing.length, name: "Sedang Berlangsung" },
          { value: myCourses.completed.length, name: "Sudah Selesai" },
          { value: myCourses.todo.length, name: "Belum Dimulai" },
        ],
        // itemStyle: {
        //   emphasis: {
        //     shadowBlur: 10,
        //     shadowOffsetX: 0,
        //     shadowColor: "rgba(0, 0, 0, 0.5)",
        //   },
        // },
      },
    ],
  };
  // function onChartReady(echarts) {
  //   console.log("echarts is ready", echarts);
  // }
  function onChartClick(param, echarts) {
    console.log(param, echarts);
    setCount(count + 1);
  }
  function onChartLegendselectchanged(param, echarts) {
    console.log(param, echarts);
  }
  const handleChange = (e) => {
    let valueProgress = e.target.value;
    setProgress(valueProgress);
  };
  console.log(myCourses.completed);
  return (
    <Container className="dashboard_page__container">
      <div className="dashboard__main_content">
        <Row>
          <Col md={8}>
            <div className="dashboard__title_wrapper mb-0 d-flex justify-content-between">
              <div>
                <h2 className="h2 dashboard__section_title mb-">Progress Saya</h2>
                <div className="dashboard__section_subtitle">
                  {progress === "completed"
                    ? myCourses.completed.length
                    : progress === "todo"
                    ? myCourses.todo.length
                    : progress === "ongoing"
                    ? myCourses.ongoing.length
                    : 0}{" "}
                  Pelatihan di Temukan
                </div>
              </div>
              <div className="dashboard_filter__section">
                {" "}
                <Form.Control
                  onChange={handleChange}
                  className="py-0 style1-input "
                  as="select"
                >
                  <option className="pl-1" value="todo">
                    Belum Dimulai
                  </option>
                  <option className="pl-1" value="ongoing">
                    Sedang Berlangsung
                  </option>

                  <option className="pl-1" value="completed">
                    Sudah Selesai
                  </option>
                </Form.Control>
              </div>
            </div>
            {progress === "completed"
              ? myCourses.completed.map((value, index) => {
                  return (
                    <div key={index}>
                      <MyProgressCourse
                        item={value}
                        id={value.course_id}
                        progress={myCourses.completed}
                        status={myCourses.completed}
                      />
                    </div>
                  );
                })
              : progress === "todo"
              ? myCourses.todo.map((value, index) => {
                  return (
                    <div key={index}>
                      <MyProgressCourse
                        item={value}
                        id={value.course_id}
                        progress={myCourses.todo}
                        status={myCourses.todo}
                      />
                    </div>
                  );
                })
              : myCourses.ongoing.map((value, index) => {
                  return (
                    <div key={index}>
                      <MyProgressCourse
                        item={value}
                        id={value.course_id}
                        progress={myCourses.ongoing}
                        status={myCourses.ongoing}
                      />
                    </div>
                  );
                })}
          </Col>
          <Col md={4}>
            <div className="dashboard__title_wrapper">
              <h2 className="dashboard__section_title h2">Persentase</h2>
            </div>
            <Card className="cart_container">
              <ReactECharts
                option={option}
                style={{ height: 280 }}
                onEvents={{
                  click: onChartClick,
                  legendselectchanged: onChartLegendselectchanged,
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default ProgressPage;
