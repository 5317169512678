import React from "react";
import "../assets/css/Courses/SearchBoxMain.css";
import SearchBoxInside from "./SearchBoxInside";

function SearchBoxMain({
  searchBtnHandler,
  dataType,
  dataTypeHandler,
  titleMain,
  searchBoxHandler,
  keywords,
}) {
  return (
    <div className="search_box__wrapper">
      <h1 className="h1 search_box__title">
        Temukan {titleMain} yang <br />
        Tepat Untuk Anda!
      </h1>

      <SearchBoxInside
        searchBtnHandler={searchBtnHandler}
        dataType={dataType}
        dataTypeHandler={dataTypeHandler}
        searchBoxHandler={searchBoxHandler}
        keywords={keywords}
      />
    </div>
  );
}

export default SearchBoxMain;
