import { call, put, takeEvery } from "redux-saga/effects";
import { PromiseService } from "../../services/PromiseService";
import {
  ASSETS_DATA,
  ASSETS_DATA_POST,
  ASSETS_DATA_LINK,
} from "../config/AssetsConfig";
import {
  getAssetsDataSuccess,
  postAssetsDataSuccess,
  getAssetsDataFail,
} from "../actions/AssetsActions";

function* workerAssetsData(params) {
  try {
    const response = yield call(
      PromiseService.getData,
      `${ASSETS_DATA_LINK}${params.value}`
    );
    if (response.status === 200 && response.data) {
      yield put(getAssetsDataSuccess(response.data));
    } else {
      yield put(getAssetsDataFail(response));
    }
  } catch (error) {
    yield put(getAssetsDataFail(error));
  }
}
function* workerAssetsDataPost(params) {
  const body = params.value.body;
  const url = params.value.url;
  try {
    const response = yield call(
      PromiseService.postFormData,
      `${ASSETS_DATA_LINK}${url}`,
      body
    );
    if (response.status === 200 && response.data) {
      yield put(postAssetsDataSuccess(response.data));
    } else {
      yield put(getAssetsDataFail(response));
    }
  } catch (error) {
    yield put(getAssetsDataFail(error));
  }
}

export const watcherAssets = [
  takeEvery(ASSETS_DATA, workerAssetsData),
  takeEvery(ASSETS_DATA_POST, workerAssetsDataPost),
];
