import React from "react";
import ReactEChart from "echarts-for-react";
import { Row, Col, Card, Dropdown } from "react-bootstrap";
import "../assets/css/Personal/overviewGraphRadar.css";

function OverviewGraphRadar() {
  return (
    <Card className="ogr-card">
      <Card.Body className="ogr-card-body">
        <Row className="ogr-head">
          <Col xs={2} className="pr-0 d-flex align-items-center">
            <Card.Title variant="primary" className="ogr-card-title">
              266
            </Card.Title>
          </Col>
          <Col className="align-items-center mb-1" xs={3}>
            <Card.Text variant="primary" className="ogr-card-text">
              Angular
            </Card.Text>
            <Card.Text variant="primary" className="ogr-card-text">
              Skor Terbaik
            </Card.Text>
          </Col>
          <Col className="d-flex align-items-center justify-content-end">
            <Dropdown>
              <Dropdown.Toggle variant="outline-dark" size="sm">
                Topik Popular
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">
                  Topik Popular</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Desain Web</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <div className="graph-div">
          <ReactEChart option={option()} style={{ height: "100%" }} />
        </div>
      </Card.Body>
    </Card>
  );
}
export default OverviewGraphRadar;

function option() {
  return {
    tooltip: {
      trigger: "axis",
    },
    radar: {
      indicator: [
        { name: "JavaScript", max: 50 },
        { name: "Angular", max: 50 },
        { name: "CSS", max: 50 },
        { name: "Principle", max: 50 },
        { name: "Sketch", max: 50 },
        { name: "Vue.js", max: 50 },
        { name: "Flinto", max: 50 },
        { name: "HTML", max: 50 },
      ],
    },
    series: [
      {
        name: "Points",
        type: "radar",
        tooltip: {
          trigger: "item",
        },
        data: [
          {
            value: [30, 36, 32, 34, 37, 33, 33, 31],
            name: "Points",
          },
        ],
      },
    ],
  };
}
