import {
  PURCHASES_DATA_POST,
  PURCHASES_DATA_POST_SUCCESS,
  PURCHASES_DATA_POST_FAIL,
} from "../config/PurchasesConfig";

export const postPurchasesData = (value) => ({
  type: PURCHASES_DATA_POST,
  value,
});
export const postPurchasesDataSuccess = (value) => ({
  type: PURCHASES_DATA_POST_SUCCESS,
  value,
});
export const postPurchasesDataFail = (value) => ({
  type: PURCHASES_DATA_POST_FAIL,
  value,
});
