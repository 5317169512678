import React from "react";
function CardCoveredSkill({ skillcover }) {
  return (
    <div className="course_front__deskripsi__wrapper">
      <h2 className="course_front__inner_title h2">Keterampilan Tercakup</h2>
      <div className="course_front__deskripsi">{skillcover} </div>
    </div>
  );
}

export default CardCoveredSkill;
