import {
  COURSE_TYPES_DATA,
  COURSE_TYPES_DATA_SUCCESS,
  COURSE_TYPES_DATA_FAIL,
  COURSE_TYPES_DATA_ID_SUCCESS,
} from "../config/CourseTypesConfig";

const intialState = {
  courseTypesData: [],
  courseTypesDataId: [],
  isLoading: false,
};

export const CourseTypesReducers = function (state = intialState, action) {
  switch (action.type) {
    case COURSE_TYPES_DATA:
      return { ...state, isLoading: true };
    case COURSE_TYPES_DATA_SUCCESS:
      return { ...state, isLoading: false, courseTypesData: action.value };
    case COURSE_TYPES_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, courseTypesDataId: action.value };
    case COURSE_TYPES_DATA_FAIL:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
