import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { Accordion, Button } from "react-bootstrap";
import {
  FaChevronDown,
  FaChevronUp,
  FaAngleLeft,
  FaCheck,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { SanitizeMaterialTypeId } from "../helper/functions";

import styled from "styled-components";
import "./../assets/css/Courses/AccordionCourse.css";

function AccordionCourse({
  courseTitle,
  id,
  topics,
  courseId,
  activeKey,
  selectedIndex,
  previousAcc,
}) {
  const [idx, setIdx] = useState([]);
  const history = useHistory();
  const handleOnClick = useCallback(
    (param) => {
      history.push(`/course/${courseId}/${param}`);
    },
    [history]
  );
  const handleShow = (index) => {
    if (idx.includes(index)) setIdx(idx.filter((i) => i !== index));
    else setIdx([...idx, index]);
  };

  return (
    <StyledWrapperMain>
      {id ? (
        <h4 className="h4 course_front__kurikulum_title">
          <Link to={`/course/${courseId}`} style={{ textDecoration: "none" }}>
            <FaAngleLeft className="mr-2" />
          </Link>
          {courseTitle}
        </h4>
      ) : (
        <h2 className="course_front__kurikulum_title h2">Kurikulum</h2>
      )}

      <div className="course_front__kurikulum_wrapper__accordion">
        {topics &&
          topics.map((topic, index) => (
            <Accordion
              key={`${index}`}
              defaultActiveKey={`${activeKey}`}
              className="course_front__kurikulum_wrapper"
            >
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey={`${index}`}
                className="course_front__kurikulum_wrapper__title"
                onClick={() => handleShow(index)}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="course_front__kurikulum__title">
                    {topic.title}
                  </div>
                  <span className="float-right">
                    {idx.includes(index) ? (
                      <FaChevronUp className="course_front__kurikulum__icon" />
                    ) : (
                      <FaChevronDown className="course_front__kurikulum__icon" />
                    )}
                  </span>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={`${index}`}>
                <div className="course_front__material_wrapper">
                  {topic.materials.map((material, indexL) => {
                    const sanitizedMaterial = SanitizeMaterialTypeId(material);
                    const checked = material.completed;
                    return (
                      <div
                        key={`material_${index}${indexL}`}
                        className={`accordion-course-main-div d-flex justify-content-between cursor-pointer ${
                          parseInt(selectedIndex) ===
                          parseInt(`${index}${indexL}`)
                            ? "accordion-course-main-div-active"
                            : ""
                        }`}
                        onClick={() =>
                          handleOnClick(
                            `${sanitizedMaterial.type}/${sanitizedMaterial.id}_${index}_${indexL}`
                          )
                        }
                      >
                        <div className="d-flex align-items-center w80p">
                          <div className="blue-background mr-3 box p-1 rounded text-center font-weight-bold white-font">
                            {checked === 1 ? (
                              <div className="checklist-done">
                                <FaCheck className="done-svg" />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="course_front__material_title">
                            {material.title}
                          </div>
                        </div>
                        <div className="course_front__duration">
                          {sanitizedMaterial.short}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Accordion.Collapse>
            </Accordion>
          ))}
      </div>
    </StyledWrapperMain>
  );
}
export default AccordionCourse;

const StyledWrapperMain = styled.section`
  .btn-circle {
    display: none;
  }
`;
