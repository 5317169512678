import React from "react";
function CardReqruitment({ reqruitment }) {
  return (
    <div className="requirement__wrapper">
      <h2 className="course_front__inner_title h2">Persyaratan</h2>
      <div className="course_front__persyaratan">
        <ul className="">
          {reqruitment &&
            reqruitment.map((listing, index) => <li key={index}>{listing}</li>)}
        </ul>
      </div>
    </div>
  );
}
export default CardReqruitment;
