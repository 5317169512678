export const ENDPOINT = "https://api.satukelas.space/api/";
export const WEB_URL = "https://student.satukelas.space/";
export const categories = [
  { name: "Keuangan" },
  { name: "Perpajakan" },
  { name: "Akuntansi" },
  { name: "Pemasaran" },
  { name: "Penjualan" },
  { name: "Manajemen SDM" },
  { name: "Pengembangan Diri" },
  { name: "Manajemen Risiko" },
  { name: "Manajemen Aset" },
  { name: "Pengembangan Bisnis" },
  { name: "Legal" },
  { name: "Hubungan Eksternal" },
  { name: "Soft Skill" },
];
export const testimoni = [
  {
    name: "Kevin Situmeang, Risk Management PGN",
    testimoni:
      "Mengikuti pelatihan di satukelas sangat membantu saya dalam memahami banyak hal yang terkait dengan pekerjaan saya, terutama di bidang perpajakan dan keuangan. Selain itu, dengan membership satukelas saya dapat belajar banyak hal sekaligus tanpa keluar banyak uang.",
  },
  {
    name: "Mister John, blu blu",
    testimoni:
      "Saya mengikutsertakan belasan karyawan di kantor saya untuk mengikuti pelatihan di satukelas karena model membership yang memungkinkan karyawan saya untuk mengikuti pelatihan apapun, kapanpun, dan dimanapun. Satukelas juga memberikan saya kemudahan dalam melakukan monitoring kepada karyawan sehingga kami bisa tahu progress pelatihannya dan juga dampak terhadap pekerjaannya.",
  },
  {
    name: "Miss Jane, blu blu",
    testimoni:
      "Karena pandemi, pelatihan tahunan yang biasa diberikan kantor saya dapatkan lewat platform satukelas. Saya sangat suka dengan tampilannya dan kemudahannya dalam mengakses. Materinya dibagi dengan sederhana dan mudah diikuti sehingga mudah untuk dipraktekan ketika bekerja di kantor.",
  },
];
export const blog = [
  { title: "Mengenal Learning Agility" },
  {
    title: "Mengapa Personalized Learning penting?",
  },
  { title: "Masa Depan Tenaga Kerja Indonesia" },
  // {
  //   title: "Bagaimana Cara Bekerja dengan Generasi Z?",
  // },
  // { title: "3 hal yang akan benar-benar berubah karena pandemi Covid-19" },
];
export const membership = [
  {
    membership_name: "Coba Gratis",
    jumlah_member: "Satu User",
    price: "Gratis",
    benefit: [
      { benefit_info: "Tes Kompetensi Kerja" },
      { benefit_info: "Rekomendasi Pelatihan" },
      { benefit_info: "Video Pelatihan" },
      { benefit_info: "Live Consultation" },
      { benefit_info: "5x Webinar" },
    ],
    status_member: 'personal'
  },
  {
    membership_name: "Premium",
    jumlah_member: "Satu User",
    price: "100rb /bulan",
    benefit: [
      { benefit_info: "Tes Kompetensi Kerja" },
      { benefit_info: "Analisis Kompetensi Kerja" },
      { benefit_info: "Rekomendasi Pelatihan" },
      { benefit_info: "Akses seluruh Pelatihan On Demand" },
      { benefit_info: "Video Pelatihan" },
      { benefit_info: "Modul Pelatihan" },
      { benefit_info: "Pre/Post Test" },
      { benefit_info: "Akses seluruh Pelatihan Live " },
      { benefit_info: "Live Consultation" },
      { benefit_info: "Webinar " },
      { benefit_info: "Akses Sertifikasi" },
      { benefit_info: "Kursus Persiapan Sertifikasi" },
      { benefit_info: "Assessment Sertifikasi" },
    ],
    status_member: 'personal'
  },
  {
    membership_name: "Bisnis",
    jumlah_member: "5 User",
    price: "3.6jt /tahun",
    benefit: [
      { benefit_info: "Tes Kompetensi Kerja" },
      { benefit_info: "Analisis Kompetensi Kerja" },
      { benefit_info: "Rekomendasi Pelatihan" },
      { benefit_info: "Akses seluruh Pelatihan On Demand" },
      { benefit_info: "Video Pelatihan" },
      { benefit_info: "Modul Pelatihan" },
      { benefit_info: "Pre/Post Test" },
      { benefit_info: "Akses seluruh Pelatihan Live " },
      { benefit_info: "Live Consultation" },
      { benefit_info: "Webinar " },
      { benefit_info: "Akses Sertifikasi" },
      { benefit_info: "Kursus Persiapan Sertifikasi" },
      { benefit_info: "Assessment Sertifikasi" },
    ],
    status_member: 'korporasi'
  },
  {
    membership_name: "Enterprise",
    jumlah_member: "25 User",
    price: "Mulai dari 30jt /tahun",
    benefit: [
      { benefit_info: "Tes Kompetensi Kerja" },
      { benefit_info: "Analisis Kompetensi Kerja" },
      { benefit_info: "Rekomendasi Pelatihan" },
      { benefit_info: "Akses seluruh Pelatihan On Demand" },
      { benefit_info: "Video Pelatihan" },
      { benefit_info: "Modul Pelatihan" },
      { benefit_info: "Pre/Post Test" },
      { benefit_info: "Akses seluruh Pelatihan Live " },
      { benefit_info: "Live Consultation" },
      { benefit_info: "Webinar " },
      { benefit_info: "Akses Sertifikasi" },
      { benefit_info: "Kursus Persiapan Sertifikasi" },
      { benefit_info: "Assessment Sertifikasi" },
      { benefit_info: "Dashboard Perusahaan" },
      { benefit_info: "Pengaturan Jadwal & Peserta Pelatihan" },
      { benefit_info: "Komunikasi Internal" },
      { benefit_info: "Monitoring Progress Pelatihan & Sertifikasi" },
      { benefit_info: "Custom Materi Pelatihan" },
      { benefit_info: "Custom Warna Tampilan dan Logo" },
    ],
    status_member: 'korporasi'
  },
];
