import { watcherLogin } from "./thunks/LoginThunks";
import { watcherCourses } from "./thunks/CoursesThunks";
import { watcherLessons } from "./thunks/LessonsThunks";
import { watcherQuiz } from "./thunks/QuizThunks";
import { watcherUsers } from "./thunks/UsersThunks";
import { watcherCourseTypes } from "./thunks/CourseTypesThunks";
import { watcherLearningPaths } from "./thunks/LearningPathsThunks";
import { watcherTopic } from "./thunks/TopicThunks";
import { watcherOrders } from "./thunks/OrdersThunks";
import { watcherWebinars } from "./thunks/WebinarsThunks";
import { watcherAssets } from "./thunks/AssetsThunks";
import { watcherAssignment } from "./thunks/AssignmentThunks";
import { watcherPurchases } from "./thunks/PurchasesThunks";
import { watcherPayments } from "./thunks/PaymentsThunks";

export default [
  ...watcherLogin,
  ...watcherCourses,
  ...watcherLessons,
  ...watcherQuiz,
  ...watcherUsers,
  ...watcherCourseTypes,
  ...watcherLearningPaths,
  ...watcherTopic,
  ...watcherOrders,
  ...watcherWebinars,
  ...watcherAssets,
  ...watcherAssignment,
  ...watcherPurchases,
  ...watcherPayments,
];
