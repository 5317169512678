import {
  //get
  COURSES_DATA,
  COURSES_DATA_SUCCESS,
  COURSES_DATA_ID_SUCCESS,
  COURSES_DATA_FAIL,
  //post enroll
  COURSES_DATA_ENROLL,
  COURSES_DATA_ENROLL_SUCCESS,
  COURSES_DATA_ENROLL_FAIL,
  //complete material
  COURSES_COMPLETE_MATERIAL,
  COURSES_COMPLETE_MATERIAL_SUCCESS,
  COURSES_COMPLETE_MATERIAL_FAIL,
  //complete course
  COURSES_COMPLETE_COURSE,
  COURSES_COMPLETE_COURSE_SUCCESS,
  COURSES_COMPLETE_COURSE_FAIL,
  //complete remove
  COURSES_REMOVE_COMPLETE_STATE,
} from "../config/CoursesConfig";

//get
export const getCoursesData = (value) => ({
  type: COURSES_DATA,
  value,
});
export const getCoursesDataSuccess = (value) => ({
  type: COURSES_DATA_SUCCESS,
  value,
});
export const getCoursesDataIdSuccess = (value) => ({
  type: COURSES_DATA_ID_SUCCESS,
  value,
});
export const getCoursesDataFail = (value) => ({
  type: COURSES_DATA_FAIL,
  value,
});

//post enroll
export const postEnrollCourse = (value) => ({
  type: COURSES_DATA_ENROLL,
  value,
});
export const postEnrollCourseSuccess = (value) => ({
  type: COURSES_DATA_ENROLL_SUCCESS,
  value,
});
export const postEnrollCourseFail = (value) => ({
  type: COURSES_DATA_ENROLL_FAIL,
  value,
});

//complete material
export const postCompleteMaterial = (value) => ({
  type: COURSES_COMPLETE_MATERIAL,
  value,
});
export const postCompleteMaterialSuccess = (value) => ({
  type: COURSES_COMPLETE_MATERIAL_SUCCESS,
  value,
});
export const postCompleteMaterialFail = (value) => ({
  type: COURSES_COMPLETE_MATERIAL_FAIL,
  value,
});

//complete course
export const postCompleteCourse = (value) => ({
  type: COURSES_COMPLETE_COURSE,
  value,
});
export const postCompleteCourseSuccess = (value) => ({
  type: COURSES_COMPLETE_COURSE_SUCCESS,
  value,
});
export const postCompleteCourseFail = (value) => ({
  type: COURSES_COMPLETE_COURSE_FAIL,
  value,
});

//complete remove

export const removeCompleteState = (value) => ({
  type: COURSES_REMOVE_COMPLETE_STATE,
  value,
});
