import React, { useEffect, useState } from "react";
import { FaDatabase, FaMinus, FaPlus} from "react-icons/fa";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Personal/MyPoint.css";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

function MyPoint() {
  const history = useHistory();
  const users = useSelector((state) => state.users);
  const [trigger, setTrigger] = useState(true);
  const [myPoint, setMyPoint] = useState();

  useEffect(() => {
    AuthIsLogin(history);
  }, []);

  useEffect(() => {
    const points = users.usersDataId.points;
    console.log(points);
    if ((points || points === 0) && trigger) {
      setTrigger(false);
      setMyPoint(points);
    }
  }, [users.usersDataId]);

  return (
    <Container className="dashboard_page__container">
      <Row className="my_poin__wrapper">
        <Col sm={12} md={6}>
          <h2 className="dashboard__section_title mb-3 h2">Poin Saya</h2>
          <div className="my_poin__left_item__wrapper">
            <div className="my_poin__small_wrapper">
              <div className="my_poin_icon_wrapper plus">
                <FaPlus />
              </div>
              <div>
                <div className="my_poin__small_section__title">
                  Poin Bulan Ini
                </div>
                <div className="my_poin__small_section__num">{myPoint}</div>
              </div>
            </div>
            <div className="my_poin__small_wrapper minus">
              <div className="my_poin_icon_wrapper minus">
                <FaMinus />
              </div>
              <div className="my_poin__small_section__title">Poin Terpakai</div>
              <div className="my_poin__small_section__num">{myPoint}</div>
            </div>
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="my_poin__right_item__wrapper">
            <div className="my_poin_icon_wrapper coin">
              <FaDatabase />
            </div>
            <div className="sisa_poin_text">Sisa Poin Anda</div>
            <div className="sisa_poin_num">{myPoint}</div>

            <div className="gunakan_text">Gunakan Poin Anda</div>
            <div>
              <div className="satukelas_btn my_poin__redeem_btn">Redeem</div>
              <div className="satukelas_btn my_poin__tukar_promo_btn">
                Tukar Promo
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default MyPoint;
