import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Modal } from "react-bootstrap";
import { useParams, useHistory, useLocation, Link } from "react-router-dom";
import CourseDetailTitle from "../../components/CourseDetailTitle";
import AccordionCourse from "../../components/AccordionCourse";
import TabsCourseDetail from "../../components/TabsCourseDetail";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import CardChat from "../../components/CardChat";
import PDFLoader from "../../components/PDFLoader";
import VideoLoader from "../../components/VideoLoader";
import AudioLoader from "../../components/AudioLoader";
// import TextLesson from "../../components/TextLesson";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useDispatch, useSelector } from "react-redux";
import { getLessonsData } from "../../redux/actions/LessonsActions";
import {
  getCoursesData,
  postCompleteMaterial,
  postCompleteCourse,
  removeCompleteState,
} from "../../redux/actions/CoursesActions";
import { getAssignmentData } from "../../redux/actions/AssignmentActions";
import { getWebinarsData } from "../../redux/actions/WebinarsActions";
import { getUsersData } from "../../redux/actions/UsersActions";
import { AuthIsLogin, AuthData } from "../../helper/auth";
import WebinarDetail from "../Courses/WebinarDetail";
import AssignmentPage from "../Courses/AssignmentPage";
import QuizPage from "../../pages/Courses/QuizPage";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactGA from "react-ga";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";

const MySwal = withReactContent(Swal);

function CourseDetail() {
  const { course_id, id_material, material_type } = useParams();
  // const [numPages, setNumPages] = useState(null);
  // const [previous, setPrevious] = useState("");
  const [onNextClicked, setOnNextClicked] = useState(false);
  const [disclaimer, setDisclaimer] = useState(true);
  const [quizPass, setQuizPass] = useState(true);
  // const [pageNumber, setPageNumber] = useState(1);
  const [detailData, setDetailData] = useState();
  const [material, setMaterial] = useState(id_material.split("_"));
  const [lessonType, setLessonType] = useState([false, false, false]); // video|audio|pdf, else text
  const lessons = useSelector((state) => state.lessons);
  const courses = useSelector((state) => state.courses);
  const users = useSelector((state) => state.users);
  const assignments = useSelector((state) => state.assignments);
  const webinars = useSelector((state) => state.webinars);
  const quizzes = useSelector((state) => state.quizzes);
  const dispatch = useDispatch();
  const course = courses.coursesDataId;
  const lesson = lessons.lessonsDataId;
  const assignment = assignments.assignmentDataId;
  const webinar = webinars.webinarsDataId;
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    AuthIsLogin(history);
    if (material_type === "lessons") {
      dispatch(getLessonsData(id_material.split("_")[0]));
    }
    if (material_type === "assignments") {
      dispatch(getAssignmentData(id_material.split("_")[0]));
    }
    if (material_type === "webinars") {
      dispatch(getWebinarsData(id_material.split("_")[0]));
    }
    if (onNextClicked) {
      setOnNextClicked(false);
    } else {
      setMaterial(id_material.split("_"));
      dispatch(getCoursesData({ type: "id", param: `/${course_id}` }));
    }
    setQuizPass(true);
  }, [, location]);
  useEffect(() => {
    const myCourses = users.usersDataId.my_courses;
    const memberships = users.usersDataId.memberships;
    let enrolled = false;
    if (courses.coursesDataId.price !== "Free") {
      if (memberships) {
        if (memberships.length > 0) {
          if (memberships[0].status !== "active") {
            displayPopup(false, "Membership Expired!", "Oke");
            history.push(`/course/${course_id}`);
          }
        } else {
          displayPopup(false, "Pelatihan Belum Dibeli!", "Oke");
          history.push(`/course/${course_id}`);
        }
      }
    }
    if (course_id && myCourses) {
      for (let i = 0; i < myCourses.length; i++) {
        if (myCourses[i].course_id === course_id) {
          enrolled = true;
        }
      }
      if (!enrolled) {
        displayPopup(false, "Pelatihan Belum Dimulai!", "Oke");
        history.push(`/course/${course_id}`);
      }
    }
  }, [users]);

  useEffect(() => {
    if (lesson.url_video) {
      let newLessonType = lessonType.splice(0, 1, true);
      setLessonType((state) => [...state, newLessonType]);
    }
    if (lesson.url_audio) {
      let newLessonType = lessonType.splice(1, 1, true);
      setLessonType((state) => [...state, newLessonType]);
    }
    if (lesson.url_pdf) {
      let newLessonType = lessonType.splice(2, 1, true);
      setLessonType((state) => [...state, newLessonType]);
    }
    if (lesson) setDetailData(lesson);
  }, [lesson]);

  useEffect(() => {
    if (assignment) setDetailData(assignment);
  }, [assignment]);

  useEffect(() => {
    if (webinar) setDetailData(webinar);
  }, [webinar]);

  useEffect(() => {
    if (quizzes.quizDataId?.attempts) {
      let newQuizPass = false;
      const Pass = quizzes.quizDataId.attempts.some(
        (attempt) => attempt.result === "Pass"
      );
      if (Pass) {
        newQuizPass = true;
      }
      setQuizPass(newQuizPass);
    }
  }, [quizzes]);

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }
  // const handleOnClick = useCallback(
  //   (param) => {
  //     history.push(`/course/${courseId}/${param}`);
  //   },
  //   [history]
  // );.

  function onComplete() {
    setOnNextClicked(true);
    const myCourses = users.usersDataId.my_courses;
    let ongoing = false;
    if (course_id && myCourses) {
      for (let i = 0; i < myCourses.length; i++) {
        if (myCourses[i].course_id === course_id) {
          if (myCourses[i].status === "ongoing") ongoing = true;
        }
      }
    }
    // if (ongoing) {
    const payload = {
      material_id: id_material.split("_")[0],
      user_id: AuthData().userId,
    };
    const arrTopics = course.topics;
    let i = parseInt(id_material.split("_")[1]);
    let j = parseInt(id_material.split("_")[2]);
    dispatch(postCompleteMaterial(payload));
    if (
      arrTopics.length - 1 == i &&
      arrTopics[arrTopics.length - 1].materials.length - 1 == j &&
      ongoing
    ) {
      const payloadC = {
        course_id: course_id,
        user_id: AuthData().userId,
      };
      dispatch(postCompleteCourse(payloadC));
    }
    // } else {
    //   setMaterial(id_material.split("_"));
    //   dispatch(getCoursesData({ type: "id", param: `/${course_id}` }));
    // }
  }

  useEffect(() => {
    if (
      courses.completeMaterialData === "success" ||
      courses.completeCourseData === "success"
    ) {
      dispatch(getUsersData(AuthData().userId));
      dispatch(removeCompleteState());
    }
    setMaterial(id_material.split("_"));
    dispatch(getCoursesData({ type: "id", param: `/${course_id}` }));
  }, [courses.completeMaterialData, courses.completeCourseData]);

  const getNextLink = (current) => {
    const arrTopics = course.topics;
    let i = parseInt(current[1]);
    let j = parseInt(current[2]);

    // check if next item exist
    let result;
    let next = arrTopics[i].materials[j + 1];
    if (
      arrTopics.length - 1 === i &&
      arrTopics[arrTopics.length - 1].materials.length - 1 === j
    ) {
      return "/";
    }
    if (next) {
      if (next.type === "lessons") {
        result = "/lessons/" + next.lesson_id;
      } else if (next.type === "quizzes") {
        result = "/quiz/" + next.quiz_id;
      } else if (next.type === "assignments") {
        result = "/assignments/" + next.assignment_id;
      } else if (next.type === "webinars") {
        result = "/webinars/" + next.webinar_id;
      }
      result += `_${i}_${j + 1}`;
      return result;
    } else {
      j = 0;
      next = arrTopics[i + 1];
      if (next) {
        next = arrTopics[i + 1].materials[j];
        if (next) {
          if (next.type === "lessons") {
            result = "/lessons/" + next.lesson_id;
          } else if (next.type === "quizzes") {
            result = "/quiz/" + next.quiz_id;
          } else if (next.type === "assignments") {
            result = "/assignments/" + next.assignment_id;
          } else if (next.type === "webinars") {
            result = "/webinars/" + next.webinar_id;
          }
          result += `_${i + 1}_${j}`;
          return result;
        } else return false;
      } else return false;
    }
  };
  const getPreviousLink = (current) => {
    const arrTopics = course.topics;
    let i = parseInt(current[1]);
    let j = parseInt(current[2]);
    // check if next item exist
    let result;

    let previous = arrTopics[i].materials[j - 1];
    if (previous) {
      if (previous.type === "lessons") {
        result = "lessons/" + previous.lesson_id;
      } else if (previous.type === "quizzes") {
        result = "quiz/" + previous.quiz_id;
      } else if (previous.type === "assignments") {
        result = "assignments/" + previous.assignment_id;
      } else if (previous.type === "webinars") {
        result = "webinars/" + previous.webinar_id;
      }
      result += `_${i}_${j - 1}`;
      return result;
    } else {
      // j = 6;
      previous = arrTopics[i - 1];
      if (previous) {
        i = i - 1;
        j = previous.materials.length - 1;
        previous = arrTopics[i].materials[j];
        if (previous.type === "lessons") {
          result = "lessons/" + previous.lesson_id;
        } else if (previous.type === "quizzes") {
          result = "quiz/" + previous.quiz_id;
        } else if (previous.type === "assignments") {
          result = "assignments/" + previous.assignment_id;
        } else if (previous.type === "webinars") {
          result = "webinars/" + previous.webinar_id;
        }
        result += `_${i}_${j}`;
        return result;
      } else {
        return false;
      }
    }
  };
  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {});
  }
  return (
    <div className="middle-sidebar-bottom">
      <Container fluid="xs" className="dashboard_page__container">
        <Row className="no-gutters-xs">
          <Col
            xs={{ span: 12, order: 2 }}
            sm={{ span: 12, order: 2 }}
            md={{ span: 3, order: 1 }}
            lg={3}
          >
            {/* show the lesson list on the left sidebar */}
            <AccordionCourse
              courseTitle={course.title}
              id={lesson}
              topics={course.topics}
              courseId={course_id}
              activeKey={material[1]}
              selectedIndex={`${material[1]}${material[2]}`}
            />
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            sm={{ span: 12, order: 1 }}
            md={{ span: 9, order: 2 }}
            lg={9}
          >
            {/* when material type is lesson */}
            {material_type === "lessons" && (
              <>
                {/* load this component when lesson type is video h5p or video dyntube */}
                {lesson.material_type === "video_dyntube" ||
                lesson.material_type === "video_h5p" ? (
                  <VideoLoader
                    url={lesson.material_url}
                    type={lesson.material_type}
                  />
                ) : null}
                {/* load this component when lesson type is audio */}
                {lesson.material_type === "audio" ? (
                  <AudioLoader title={lesson.title} url={lesson.material_url} />
                ) : null}
                {/* load this component when lesson type is pdf */}
                {lesson.material_type === "pdf" ? (
                  <PDFLoader url={lesson.material_url} />
                ) : null}
                {/* load this component in all lesson types */}
                {/* <TextLesson content={lesson.content} /> */}
              </>
            )}
            {/* load this component when material type is webinar */}
            {material_type === "webinars" && <WebinarDetail />}
            {/* load this component when material type is assignment */}
            {material_type === "assignments" && <AssignmentPage />}
            {/* load this component when material type is quiz */}
            {material_type === "quiz" && <QuizPage />}
            {/* load this component in all material types*/}
            {/* <JamBoard />
             */}
            {course.topics && (
              <Col className="d-flex justify-content-between">
                <Link
                  to={`/course/${course_id}/${getPreviousLink(material)}
                `}
                  className="previous-button"
                >
                  {getPreviousLink(material) && (
                    <>
                      <FaAngleDoubleLeft className="mr-2" />
                      Materi Sebelumnya
                    </>
                  )}
                </Link>
                <Link
                  to={`/course/${course_id}${getNextLink(material)}`}
                  onClick={onComplete}
                  className="next-button"
                >
                  {getNextLink(material) && quizPass && (
                    <>
                      Selesai & Lanjutkan
                      <FaAngleDoubleRight className="ml-2" />
                    </>
                  )}
                </Link>
              </Col>
            )}
            <CourseDetailTitle
              id={detailData}
              item={course}
              titles={detailData?.title}
            />
            <TabsCourseDetail item={course} content={lesson.content} />
            {/* <JamBoard /> */}
            <CardChat />
          </Col>

          {/* <Col xs={{ span: 12, order: 3 }} md={4}>
            <CardChat />
          </Col> */}
        </Row>
      </Container>
      {course.course_type === "Sertifikasi" ? (
        <Modal show={disclaimer}>
          <Modal.Header>
            <Modal.Title>Persetujuan Pengguna</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
              <div className="py-3">
                <Form.Check
                  onClick={() => setDisclaimer(false)}
                  type="checkbox"
                  id="default-checkbox"
                  label="Saya setuju dengan persetujuan diatas"
                />
              </div>
            </Form>
          </Modal.Body>
          {/* <Modal.Footer>
          <Button variant="primary" onClick={() => setDisclaimer(false)}>
            Saya Setuju
          </Button>
        </Modal.Footer> */}
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
}

export default CourseDetail;
