import {
  WEBINARS_DATA,
  WEBINARS_DATA_ID_SUCCESS,
  WEBINARS_DATA_FAIL,
} from "../config/WebinarsConfig";

const intialState = {
  webinarsDataId: [],
  isLoading: false,
};

export const WebinarsReducers = function (state = intialState, action) {
  switch (action.type) {
    case WEBINARS_DATA:
      return { ...state, isLoading: true };
    case WEBINARS_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, webinarsDataId: action.value };
    case WEBINARS_DATA_FAIL:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
