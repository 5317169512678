import React from "react";
import { Card, Row } from "react-bootstrap";
import CourseCard from "./CourseCard";
function InProgress({ data }) {
  function changeProgressInProgress(title, id) {}
  return (
    <Card className="p-0 bg-white  card_round__task border-0">
      <Card.Body className=" p-3 border-top-lg border-size-lg border-warning p-0">
        <h4 className="h4 container_done__task mt-2 d-inline-block">
          Sedang dilakukan
        </h4>
      </Card.Body>
      <Row id="task">
        {data.map((value, index) => {
          return (
            <CourseCard
              key={index}
              margin={"32"}
              border={"12"}
              item={value}
              id={value.course_id}
              assId={value.assignment_id}
              changeProgress={changeProgressInProgress}
            />
          );
        })}
      </Row>
    </Card>
  );
}
export default InProgress;
