import React from "react";
import '../assets/css/Courses/RelatedMaterialSection.css';

function RelatedMaterialSection({ title }) {
    return (
        <div className="related_material__container">
            <h3 className="related_material__title h3">{title}</h3>
            {[...Array(3)].map((item, i) => {
                return (
                    <div className="related_material__item_wrapper" key={i}>
                        <div className="related_material__img_wrapper">

                        </div>
                        <div className="">
                            <div className="related_material__item_title">Akuntansi</div>
                            <div className="related_material__course_num">32 Course</div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}
export default RelatedMaterialSection;
