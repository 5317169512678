import React, { useState, useRef, useEffect } from "react";
import { SketchField, Tools } from "react-sketch2";
import {
  Dropdown,
  Button,
  Modal,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { ColorPicker, useColor } from "react-color-palette";
import Toggle from "react-toggle";
import "react-color-palette/lib/css/styles.css";
import "react-toggle/style.css";
import "../assets/css/Courses/jamboard.css";
import {
  FaRedoAlt,
  FaSave,
  FaSearchMinus,
  FaSearchPlus,
  FaTrash,
  FaUndoAlt,
  FaPencilAlt,
  FaGripLines,
  FaRegHandPaper,
} from "react-icons/fa";
import { BiRectangle } from "react-icons/bi";
import { BsCircle } from "react-icons/bs";
import { GiArrowCursor } from "react-icons/gi";
import { RiBrush2Line } from "react-icons/ri";
import { MdViewHeadline, MdCheckBoxOutlineBlank } from "react-icons/md";
function JamBoard() {
  useEffect(() => {}, []);
  const [controlledValue, setControlledValue] = useState();
  const [canvasTool, setCanvasTool] = useState(Tools.Pencil);
  const [lineWidth, setLineWidth] = useState(4);
  const [text, setText] = useState("");
  const [lineColor, setLineColor] = useColor("hex", "#000");
  const [fillColor, setFillColor] = useColor("hex", "#000");
  const [colorType, setColorType] = useState("line");
  const [fillWithColor, setFillWithColor] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const canvasRef = useRef(null);
  function onSketchChange() {
    // let prev = canUndo;
    // let now = canvasRef.current.canUndo();
    // if (prev !== now) {
    //   console.log(now);
    //   setCanUndo(now);
    // }
  }
  const handleClose = () => setShowColorPicker(false);
  const handleShow = (param) => {
    setColorType(param);
    setShowColorPicker(true);
  };
  const addText = () => canvasRef.current.addText(text);

  useEffect(() => {
    document.addEventListener("keydown", onKeyPressed, false);
  }, []);
  function onKeyPressed(e) {
    const keyCode = e.keyCode;
    if (keyCode === 46 || keyCode === 8) {
      _removeSelected();
    }
    if (keyCode === 90 && e.ctrlKey && e.shiftKey) {
      _redo();
    }
    if (keyCode === 90 && e.ctrlKey && !e.shiftKey) {
      _undo();
    }
    if (keyCode === 67 && e.ctrlKey && !e.shiftKey) {
      _copy();
    }
    if (keyCode === 86 && e.ctrlKey && !e.shiftKey) {
      _paste();
    }
  }

  function onChangeDropdown(_state, value) {
    switch (_state) {
      case "tools":
        setCanvasTool(toolRevertTranslate(value));
        break;
      case "linewidth":
        setLineWidth(value);
        break;
      default:
        break;
    }
  }
  function _removeSelected() {
    canvasRef.current.removeSelected();
  }
  function _undo() {
    if (canvasRef.current.canUndo()) {
      canvasRef.current.undo();
    }
  }

  function _redo() {
    if (canvasRef.current.canRedo()) {
      canvasRef.current.redo();
    }
  }
  function _copy() {
    canvasRef.current.copy();
  }
  function _paste() {
    canvasRef.current.paste();
  }
  function _clear() {
    canvasRef.current.clear();
    setControlledValue(null);
  }
  function _addImage() {
    canvasRef.current.addImg("http://uitheme.net/elomoas/images/v-1.png");
  }
  function _zoomIn() {
    canvasRef.current.zoom(1.25);
  }
  function _zoomOut() {
    canvasRef.current.zoom(0.75);
  }
  // console.log(fillWithColor, lineWidth);
  return (
    <div className="col-12 mt-3 mb-3">
      <div className="jamboard__container">
        <SketchField
          name="sketch"
          className="canvas-area"
          ref={canvasRef}
          lineColor={lineColor.hex}
          lineWidth={lineWidth}
          fillColor={fillWithColor ? fillColor.hex : "transparent"}
          backgroundColor={"white"}
          width={null}
          height={null}
          value={controlledValue}
          forceValue
          onChange={onSketchChange}
          tool={canvasTool}
        />
        <div className="jamboard-toolsbox-main-div">
          <div className="jamboard_toolsbox__top">
            {/* save */}
            <Button
              onClick={() => console.log(canvasRef.current.toJSON())}
              className="jamboard_icon_wrapper"
              variant="primary"
            >
              <FaSave className="jamboard_icon" />
            </Button>

            {/* undo */}
            <Button
              className="jamboard_icon_wrapper"
              onClick={_undo}
              variant="secondary"
            >
              <FaUndoAlt className="jamboard_icon" />
            </Button>

            {/* redo */}
            <Button
              className="jamboard_icon_wrapper"
              onClick={_redo}
              variant="secondary"
            >
              <FaRedoAlt className="jamboard_icon" />
            </Button>

            {/* delete */}
            <Button
              className="jamboard_icon_wrapper"
              onClick={_clear}
              variant="danger"
            >
              <FaTrash className="jamboard_icon" />
            </Button>

            {/* cari file */}
            <Button className="jamboard_btn_wrapper" onClick={_addImage}>
              Muat Gambar
            </Button>
            <Button
              className="jamboard_btn_wrapper"
              onClick={() => setControlledValue(data())}
            >
              Muat File
            </Button>

            {/* zoom in */}
            <Button className="jamboard_icon_wrapper" onClick={_zoomIn}>
              <FaSearchPlus className="jamboard_icon" />
            </Button>

            {/* zoom out */}
            <Button className="jamboard_icon_wrapper" onClick={_zoomOut}>
              <FaSearchMinus className="jamboard_icon" />
            </Button>
          </div>

          <div className="jamboard_toolsbox__middle">
            {/* alat */}
            <Dropdown
              onSelect={(key, el) =>
                onChangeDropdown("tools", el.target.innerText)
              }
            >
              <Dropdown.Toggle
                variant="info"
                className="satukelas_jamboard_dropdown"
              >
                {canvasTool === Tools.Pencil ? (
                  <>
                    <FaPencilAlt className="mr-2" />
                    {toolTranslate(canvasTool)}
                  </>
                ) : canvasTool === Tools.Line ? (
                  <>
                    {" "}
                    <FaGripLines className="mr-2" />
                    {toolTranslate(canvasTool)}
                  </>
                ) : canvasTool === Tools.Rectangle ? (
                  <>
                    <BiRectangle className="mr-2" />
                    {toolTranslate(Tools.Rectangle)}
                  </>
                ) : canvasTool === Tools.Circle ? (
                  <>
                    <BsCircle className="mr-2" />
                    {toolTranslate(Tools.Circle)}
                  </>
                ) : canvasTool === Tools.Select ? (
                  <>
                    <GiArrowCursor className="mr-2" />
                    {toolTranslate(Tools.Select)}
                  </>
                ) : canvasTool === Tools.Pan ? (
                  <>
                    <FaRegHandPaper className="mr-2" />
                    {toolTranslate(Tools.Pan)}
                  </>
                ) : (
                  <>
                    <FaPencilAlt className="mr-2" />
                    {toolTranslate(canvasTool)}
                  </>
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  {" "}
                  <FaPencilAlt className="mr-2" />
                  {toolTranslate(Tools.Pencil)}
                </Dropdown.Item>
                <Dropdown.Item>
                  <FaGripLines className="mr-2" />
                  {toolTranslate(Tools.Line)}
                </Dropdown.Item>
                <Dropdown.Item>
                  <BiRectangle className="mr-2" />
                  {toolTranslate(Tools.Rectangle)}
                </Dropdown.Item>
                <Dropdown.Item>
                  <BsCircle className="mr-2" />
                  {toolTranslate(Tools.Circle)}
                </Dropdown.Item>
                <Dropdown.Item>
                  <GiArrowCursor className="mr-2" />
                  {toolTranslate(Tools.Select)}
                </Dropdown.Item>
                <Dropdown.Item>
                  <FaRegHandPaper className="mr-2" />
                  {toolTranslate(Tools.Pan)}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* Garis */}
            <Dropdown
              onSelect={(key, el) =>
                onChangeDropdown("linewidth", parseInt(el.target.innerText))
              }
            >
              <Dropdown.Toggle
                variant="info"
                className="satukelas_jamboard_dropdown"
              >
                <MdViewHeadline className="mr-2" />
                {lineWidth}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {lineWidthArray.map((value, index) => {
                  return <Dropdown.Item key={index}>{value}</Dropdown.Item>;
                })}
              </Dropdown.Menu>
            </Dropdown>

            {/* warna garis */}
            <Button
              className="satukelas_jamboard_dropdown"
              variant="info"
              onClick={() => handleShow("line")}
            >
              <MdCheckBoxOutlineBlank className="mr-2" />
              Warna Garis
            </Button>

            {/* warna isi */}
            <Button
              className="satukelas_jamboard_dropdown"
              variant="info"
              onClick={() => handleShow("fill")}
            >
              <RiBrush2Line className="mr-2" />
              Warna Isi
            </Button>
          </div>

          <InputGroup className="mb-3">
            <FormControl
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Masukan Text"
              aria-label="Masukan Text"
              className="satukelas_jamboard__input"
            />
            <Button variant="primary" onClick={addText}>
              Masukan
            </Button>
          </InputGroup>
        </div>
        <Modal show={showColorPicker} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Pilih Warna</Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex justify-content-center align-items-center">
            <ColorPicker
              color={colorType === "line" ? lineColor : fillColor}
              onChange={colorType === "line" ? setLineColor : setFillColor}
              hideHSV
              dark
              width={456}
              height={228}
            />
          </Modal.Body>
          <Modal.Footer>
            {colorType === "fill" ? (
              <>
                <p>Fill</p>
                <label>
                  <Toggle
                    defaultChecked={fillWithColor}
                    icons={false}
                    onChange={() => setFillWithColor(!fillWithColor)}
                  />
                </label>
              </>
            ) : null}
            <Button variant="success" onClick={handleClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default JamBoard;

const lineWidthArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const toggleFillArray = [true, false];

function toolTranslate(toolName) {
  switch (toolName) {
    case "pencil":
      return "Pensil";
    case "line":
      return "Garis";
    case "rectangle":
      return "Persegi";
    case "circle":
      return "Lingkaran";
    case "select":
      return "Pilih";
    case "pan":
      return "Pan";
    default:
      return toolName;
  }
}
function toolRevertTranslate(toolName) {
  switch (toolName) {
    case "Pensil":
      return "pencil";
    case "Garis":
      return "line";
    case "Persegi":
      return "rectangle";
    case "Lingkaran":
      return "circle";
    case "Pilih":
      return "select";
    case "Pan":
      return "pan";
    default:
      return toolName;
  }
}

function data() {
  return {
    objects: [
      {
        type: "path",
        version: "2.4.3",
        originX: "left",
        originY: "top",
        left: 218.99599999999998,
        top: 284,
        width: 147,
        height: 82,
        fill: null,
        stroke: "#000000",
        strokeWidth: 4,
        strokeDashArray: null,
        strokeLineCap: "round",
        strokeLineJoin: "round",
        strokeMiterLimit: 10,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        flipX: false,
        flipY: false,
        opacity: 1,
        shadow: null,
        visible: true,
        clipTo: null,
        backgroundColor: "",
        fillRule: "nonzero",
        paintFirst: "fill",
        globalCompositeOperation: "source-over",
        transformMatrix: null,
        skewX: 0,
        skewY: 0,
        path: [
          ["M", 279.996, 259],
          ["Q", 280, 259, 283.5, 259],
          ["Q", 287, 259, 290, 259],
          ["Q", 293, 259, 297, 260],
          ["Q", 301, 261, 306.5, 263.5],
          ["Q", 312, 266, 316, 268.5],
          ["Q", 320, 271, 323.5, 273.5],
          ["Q", 327, 276, 329.5, 278.5],
          ["Q", 332, 281, 334, 283],
          ["Q", 336, 285, 337, 288],
          ["Q", 338, 291, 338, 295],
          ["Q", 338, 299, 338.5, 303.5],
          ["Q", 339, 308, 339, 312],
          ["Q", 339, 316, 338, 321],
          ["Q", 337, 326, 333.5, 329.5],
          ["Q", 330, 333, 325.5, 336],
          ["Q", 321, 339, 316, 340],
          ["Q", 311, 341, 305, 341],
          ["Q", 299, 341, 293.5, 341],
          ["Q", 288, 341, 281.5, 340.5],
          ["Q", 275, 340, 268, 337.5],
          ["Q", 261, 335, 253.5, 331],
          ["Q", 246, 327, 239.5, 323.5],
          ["Q", 233, 320, 225, 315.5],
          ["Q", 217, 311, 213.5, 308.5],
          ["Q", 210, 306, 205, 303.5],
          ["Q", 200, 301, 197, 299],
          ["Q", 194, 297, 193, 296],
          ["L", 191.996, 294.996],
        ],
      },
      {
        type: "path",
        version: "2.4.3",
        originX: "left",
        originY: "top",
        left: 132,
        top: 366.996,
        width: 86,
        height: 65.01,
        fill: null,
        stroke: "#000000",
        strokeWidth: 4,
        strokeDashArray: null,
        strokeLineCap: "round",
        strokeLineJoin: "round",
        strokeMiterLimit: 10,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        flipX: false,
        flipY: false,
        opacity: 1,
        shadow: null,
        visible: true,
        clipTo: null,
        backgroundColor: "",
        fillRule: "nonzero",
        paintFirst: "fill",
        globalCompositeOperation: "source-over",
        transformMatrix: null,
        skewX: 0,
        skewY: 0,
        path: [
          ["M", 135.004, 368.996],
          ["Q", 135, 369, 134.5, 371],
          ["Q", 134, 373, 134, 375],
          ["Q", 134, 377, 134, 379.5],
          ["Q", 134, 382, 134, 384],
          ["Q", 134, 386, 134, 387.5],
          ["Q", 134, 389, 135, 391],
          ["Q", 136, 393, 138, 395],
          ["Q", 140, 397, 142, 398.5],
          ["Q", 144, 400, 146.5, 402],
          ["Q", 149, 404, 151, 405.5],
          ["Q", 153, 407, 156, 409],
          ["Q", 159, 411, 162, 413],
          ["Q", 165, 415, 170.5, 418],
          ["Q", 176, 421, 182.5, 423],
          ["Q", 189, 425, 197, 428],
          ["Q", 205, 431, 212.5, 432.5],
          ["L", 220.004, 434.004],
        ],
      },
      {
        type: "path",
        version: "2.4.3",
        originX: "left",
        originY: "top",
        left: 408.996,
        top: 155.99599999999998,
        width: 144.01,
        height: 130.01,
        fill: null,
        stroke: "#8b1956",
        strokeWidth: 4,
        strokeDashArray: null,
        strokeLineCap: "round",
        strokeLineJoin: "round",
        strokeMiterLimit: 10,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        flipX: false,
        flipY: false,
        opacity: 1,
        shadow: null,
        visible: true,
        clipTo: null,
        backgroundColor: "",
        fillRule: "nonzero",
        paintFirst: "fill",
        globalCompositeOperation: "source-over",
        transformMatrix: null,
        skewX: 0,
        skewY: 0,
        path: [
          ["M", 410.996, 157.996],
          ["Q", 411, 158, 412.5, 159.5],
          ["Q", 414, 161, 416.5, 164],
          ["Q", 419, 167, 420, 168.5],
          ["Q", 421, 170, 424, 172.5],
          ["Q", 427, 175, 430, 177],
          ["Q", 433, 179, 436.5, 181.5],
          ["Q", 440, 184, 443, 185.5],
          ["Q", 446, 187, 450, 189],
          ["Q", 454, 191, 457, 193.5],
          ["Q", 460, 196, 463, 198],
          ["Q", 466, 200, 469.5, 203],
          ["Q", 473, 206, 476, 208.5],
          ["Q", 479, 211, 483, 213.5],
          ["Q", 487, 216, 490, 218.5],
          ["Q", 493, 221, 495.5, 223.5],
          ["Q", 498, 226, 499, 226.5],
          ["Q", 500, 227, 502, 229],
          ["Q", 504, 231, 506.5, 233],
          ["Q", 509, 235, 511, 237],
          ["Q", 513, 239, 514.5, 241],
          ["Q", 516, 243, 517, 244.5],
          ["Q", 518, 246, 518.5, 247],
          ["Q", 519, 248, 521, 250],
          ["Q", 523, 252, 523.5, 254],
          ["Q", 524, 256, 525.5, 257.5],
          ["Q", 527, 259, 528, 260],
          ["Q", 529, 261, 530, 263],
          ["Q", 531, 265, 532.5, 266.5],
          ["Q", 534, 268, 535, 269],
          ["Q", 536, 270, 538, 272],
          ["Q", 540, 274, 541, 275],
          ["Q", 542, 276, 543, 277.5],
          ["Q", 544, 279, 546, 280.5],
          ["Q", 548, 282, 549, 283.5],
          ["Q", 550, 285, 551.5, 285.5],
          ["Q", 553, 286, 554, 287],
          ["L", 555.004, 288.004],
        ],
      },
      {
        type: "circle",
        version: "2.4.3",
        originX: "left",
        originY: "center",
        left: 125,
        top: 169,
        width: 126.91,
        height: 126.91,
        fill: "#2d48bf",
        stroke: "#8b1956",
        strokeWidth: 4,
        strokeDashArray: null,
        strokeLineCap: "butt",
        strokeLineJoin: "miter",
        strokeMiterLimit: 4,
        scaleX: 1,
        scaleY: 1,
        angle: 30.81,
        flipX: false,
        flipY: false,
        opacity: 1,
        shadow: null,
        visible: true,
        clipTo: null,
        backgroundColor: "",
        fillRule: "nonzero",
        paintFirst: "fill",
        globalCompositeOperation: "source-over",
        transformMatrix: null,
        skewX: 0,
        skewY: 0,
        radius: 63.45470825714984,
        startAngle: 0,
        endAngle: 6.283185307179586,
      },
      {
        type: "path",
        version: "2.4.3",
        originX: "left",
        originY: "top",
        left: 329.996,
        top: 325,
        width: 147,
        height: 82,
        fill: null,
        stroke: "#000000",
        strokeWidth: 4,
        strokeDashArray: null,
        strokeLineCap: "round",
        strokeLineJoin: "round",
        strokeMiterLimit: 10,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        flipX: false,
        flipY: false,
        opacity: 1,
        shadow: null,
        visible: true,
        clipTo: null,
        backgroundColor: "",
        fillRule: "nonzero",
        paintFirst: "fill",
        globalCompositeOperation: "source-over",
        transformMatrix: null,
        skewX: 0,
        skewY: 0,
        path: [
          ["M", 279.996, 259],
          ["Q", 280, 259, 283.5, 259],
          ["Q", 287, 259, 290, 259],
          ["Q", 293, 259, 297, 260],
          ["Q", 301, 261, 306.5, 263.5],
          ["Q", 312, 266, 316, 268.5],
          ["Q", 320, 271, 323.5, 273.5],
          ["Q", 327, 276, 329.5, 278.5],
          ["Q", 332, 281, 334, 283],
          ["Q", 336, 285, 337, 288],
          ["Q", 338, 291, 338, 295],
          ["Q", 338, 299, 338.5, 303.5],
          ["Q", 339, 308, 339, 312],
          ["Q", 339, 316, 338, 321],
          ["Q", 337, 326, 333.5, 329.5],
          ["Q", 330, 333, 325.5, 336],
          ["Q", 321, 339, 316, 340],
          ["Q", 311, 341, 305, 341],
          ["Q", 299, 341, 293.5, 341],
          ["Q", 288, 341, 281.5, 340.5],
          ["Q", 275, 340, 268, 337.5],
          ["Q", 261, 335, 253.5, 331],
          ["Q", 246, 327, 239.5, 323.5],
          ["Q", 233, 320, 225, 315.5],
          ["Q", 217, 311, 213.5, 308.5],
          ["Q", 210, 306, 205, 303.5],
          ["Q", 200, 301, 197, 299],
          ["Q", 194, 297, 193, 296],
          ["L", 191.996, 294.996],
        ],
      },
      {
        type: "i-text",
        version: "2.4.3",
        originX: "left",
        originY: "top",
        left: 247.53,
        top: 37.4,
        width: 209.94,
        height: 45.2,
        fill: "rgb(0,0,0)",
        stroke: null,
        strokeWidth: 1,
        strokeDashArray: null,
        strokeLineCap: "butt",
        strokeLineJoin: "miter",
        strokeMiterLimit: 4,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        flipX: false,
        flipY: false,
        opacity: 1,
        shadow: null,
        visible: true,
        clipTo: null,
        backgroundColor: "",
        fillRule: "nonzero",
        paintFirst: "fill",
        globalCompositeOperation: "source-over",
        transformMatrix: null,
        skewX: 0,
        skewY: 0,
        text: "Ini Jamboard",
        fontSize: 40,
        fontWeight: "normal",
        fontFamily: "Times New Roman",
        fontStyle: "normal",
        lineHeight: 1.16,
        underline: false,
        overline: false,
        linethrough: false,
        textAlign: "left",
        textBackgroundColor: "",
        charSpacing: 0,
        styles: {},
      },
    ],
  };
}
