import React, { useState, useEffect } from "react";
import { Card, Modal, Image, Button, Table } from "react-bootstrap";
import { FaDownload, FaEye, FaRegFileAlt } from "react-icons/fa";
import { putUsersData, getUsersData } from "../redux/actions/UsersActions";
import { AuthData } from "../helper/auth";
import { KeepSpesificKey } from "../helper/functions";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function CertificateCard({ viewlist, data }) {
  const [previewCertificate, setPreviewCertificate] = useState(false);
  const [fail, setFail] = useState(false);
  const [download, setDownload] = useState("");
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [trigger, setTrigger] = useState(false);

  const handleDownload = (value) => {
    setPreviewCertificate(!previewCertificate);
    setDownload(value);
  };
  const handlePreview = () => {
    setFail(!fail);
  };
  const addToCart = () => {
    let cart = KeepSpesificKey([...users.usersDataId.cart]);
    let req = {};
    if (!cart) cart = [];
    if (!cart.includes(data.certificate_id)) {
      cart.push(data.certificate_id);
      req.body = { cart: cart };
      req.id = AuthData().userId;
      console.log(cart);
      setTrigger(true);
      dispatch(putUsersData(req));
    } else displayPopup(true, "Barang sudah di keranjang!", false);
  };

  useEffect(() => {
    if (users.usersDataPut === "fail" && trigger) {
      setTrigger(false);
      displayPopup(
        true,
        "Terjadi masalah saat Menambahkan ke Keranjang",
        false
      );
    }
    if (users.usersDataPut === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil Menambahkan ke Keranjang", true, "Ok");
    }
  }, [users]);

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (confirm) {
        dispatch(getUsersData(AuthData().userId));
      }
    });
  }
  return (
    <>
      {viewlist === "list" ? (
        <>
          {" "}
          <Card
            id="quiz-result"
            className="course-card course_card__wrapper  list certificate_card__wrapper"
          >
            <div className="d-flex ">
              <div className=" course_card__thumbnail_wrapper listing_card mb-0">
                <img
                  className="course_card__thumbnail"
                  src={data?.certificate_url}
                  width="255px"
                  height="153px"
                  alt={data?.certificate_url}
                />
                <div className="label_sertifikat satukelas_flex success">
                  {" "}
                  Lulus{" "}
                </div>
                <div className="label_tab satukelas_flex">Partisipan </div>
                {/* <div className="label_sertifikat satukelas_flex failed"> Gagal </div> */}
              </div>{" "}
              <div className="course_card__info_wrapper card_height d-flex flex-column align-self-center mb-0">
                <h4 className="course_card__lesson_title list h4">
                  {data?.course}
                </h4>
                <div className="d-flex justify-content-between">
                  <div className="satukelas_flex btn">
                    <FaRegFileAlt
                      className="mr-2 size-icon"
                      onClick={addToCart}
                    />
                    Pesan Sertifikat Fisik
                  </div>
                  <div
                    onClick={() => handleDownload(data?.certificate_url)}
                    className="satukelas_flex btn transparent p-0"
                  >
                    <FaEye className="mr-2 size-icon" />
                    Digital
                  </div>
                </div>
              </div>
            </div>
          </Card>
          {/*<Card className="course-card course_card__wrapper list certificate_card__wrapper">
            <div className="d-flex ">
              <div className="course_card__thumbnail_wrapper  listing_card  mb-0">
                <img
                  className="course_card__thumbnail"
                  src={"http://uitheme.net/elomoas/images/v-1.png"}
                  width="255px"
                  height="153px"
                  alt="http://uitheme.net/elomoas/images/v-1.png"
                />

                <div className="label_sertifikat satukelas_flex failed">
                  {" "}
                  Gagal{" "}
                </div>
                <div className="label_tab satukelas_flex">Partisipasi</div>
              </div>
              <div className="course_card__info_wrapper  d-flex flex-column align-self-center mb-0">
                <h4 className="course_card__lesson_title list h4">
                  {data?.course}
                </h4>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <div className="score-item mr-2">
                      <GrScorecard />
                      Nilai 40
                    </div>
                    <div className="repeat-item ">
                      <MdRepeat />
                      Diulang 4x
                    </div>
                  </div>
                  <div>
                    <GrCircleInformation onClick={() => handlePreview()} />
                  </div>
                </div>
              </div>
            </div>
          </Card> */}
        </>
      ) : (
        <>
          <Card className="course-card course_card__wrapper certificate_card__wrapper">
            <div className="course_card__thumbnail_wrapper">
              <img
                className="course_card__thumbnail"
                src={
                  "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/2343093016375348.jpeg"
                }
                width="255px"
                height="153px"
                alt="https://satukelas-thumbnails.ap-south-1.linodeobjects.com/2343093016375348.jpeg"
              />
              <div className="label_sertifikat satukelas_flex success">
                {" "}
                Lulus{" "}
              </div>
              <div className="label_tab satukelas_flex">Partisipasi</div>
              {/* <div className="label_sertifikat satukelas_flex failed"> Gagal </div> */}
            </div>
            <div className="course_card__info_wrapper card_height">
              <h4 className="course_card__lesson_title list h4">
                {data?.course}
              </h4>
              <div className="d-flex justify-content-between mb-2">
                <div className="satukelas_flex btn">
                  <FaRegFileAlt
                    className="mr-2 size-icon"
                    onClick={addToCart}
                  />
                  Pesan Sertifikat
                </div>
                <div
                  onClick={() => handleDownload(data?.certificate_url)}
                  className="satukelas_flex btn transparent p-0"
                >
                  <FaEye className="mr-2 size-icon" />
                  Digital
                </div>
              </div>
            </div>
          </Card>
          {/*<Card className="course-card course_card__wrapper certificate_card__wrapper">
            <div className="course_card__thumbnail_wrapper">
              <img
                className="course_card__thumbnail"
                src={"http://uitheme.net/elomoas/images/v-1.png"}
                width="255px"
                height="153px"
                alt="http://uitheme.net/elomoas/images/v-1.png"
              />

              <div className="label_sertifikat satukelas_flex failed">
                {" "}
                Gagal{" "}
              </div>
              <div className="label_tab satukelas_flex">Partisipasi</div>
            </div>
            <div className="course_card__info_wrapper">
              <h4 className="course_card__lesson_title list h4">
                  {data?.course}
                </h4>
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <div className="score-item mr-2">
                    <GrScorecard />
                    Nilai 40
                  </div>
                  <div className="repeat-item ">
                    <MdRepeat />
                    Diulang 4x
                  </div>
                </div>
                <div>
                  <GrCircleInformation onClick={() => handlePreview(1)} />
                </div>
              </div>
            </div>
          </Card>*/}
        </>
      )}

      <Modal
        size="lg"
        onHide={() => setPreviewCertificate(false)}
        show={previewCertificate}
      >
        {/* {id === 1 ? ( */}
        {/* <div>
              <div>History</div>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Nama</th>
                    <th>Diulang</th>
                    <th>Nilai</th>
                    <th>Tanggal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Mark</td>
                    <td>3x</td>
                    <td>50</td>
                    <td>21 Januari 2020</td>
                  </tr>
                  <tr>
                    <td>Jensen</td>
                    <td>6x</td>
                    <td>50</td>
                    <td>5 Maret 2020</td>
                  </tr>
                  <tr>
                    <td>Joubia</td>
                    <td>1x</td>
                    <td>20</td>
                    <td>21 Januari 2020</td>
                  </tr>
                </tbody>
              </Table>
            </div> */}
        {/* ) : ( */}
        <>
          <Button href={download} className="download-button">
            <FaDownload />
          </Button>
          <Image src={download} />{" "}
        </>
        {/* )} */}
      </Modal>
      <Modal size="lg" onHide={() => setFail(false)} show={fail}>
        <div>
          <div>History</div>
          <Table striped hover>
            <thead>
              <tr>
                <th>Nama</th>
                <th>Diulang</th>
                <th>Nilai</th>
                <th>Tanggal</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mark</td>
                <td>3x</td>
                <td>50</td>
                <td>21 Januari 2020</td>
              </tr>
              <tr>
                <td>Jensen</td>
                <td>6x</td>
                <td>50</td>
                <td>5 Maret 2020</td>
              </tr>
              <tr>
                <td>Joubia</td>
                <td>1x</td>
                <td>20</td>
                <td>21 Januari 2020</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal>
    </>
  );
}

export default CertificateCard;
