import { HTTP_SERVICE } from "./HTTPService";

export const PromiseService = {
  getData(url, request) {
    return new Promise((resolve, reject) => {
      HTTP_SERVICE.get(url, request).then(
        (response) => {
          if (response) {
            resolve(response);
          } else {
            reject("error");
          }
        },
        function (error) {
          const errorLog = {};
          errorLog.error = error;
          reject(errorLog);
        }
      );
    });
  },
  postData(url, request, login) {
    return new Promise((resolve, reject) => {
      HTTP_SERVICE.post(url, request, login).then(
        (response) => {
          if (response) {
            resolve(response);
          } else {
            reject("error");
          }
        },
        function (error) {
          const errorLog = {};
          errorLog.error = error;
          reject(errorLog);
        }
      );
    });
  },
  putData(url, request) {
    return new Promise((resolve, reject) => {
      HTTP_SERVICE.put(url, request).then(
        (response) => {
          if (response) {
            resolve(response);
          } else {
            reject("error");
          }
        },
        function (error) {
          const errorLog = {};
          errorLog.error = error;
          reject(errorLog);
        }
      );
    });
  },
  postFormData(url, request) {
    return new Promise((resolve, reject) => {
      HTTP_SERVICE.postFormData(url, request).then(
        (response) => {
          if (response) {
            resolve(response);
          } else {
            reject("error");
          }
        },
        function (error) {
          const errorLog = {};
          errorLog.error = error;
          reject(errorLog);
        }
      );
    });
  },
};
