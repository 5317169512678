import { call, put, takeEvery } from "redux-saga/effects";
import { PromiseService } from "../../services/PromiseService";
import { LESSONS_DATA, LESSONS_DATA_LINK } from "../config/LessonsConfig";
import {
  getLessonsDataSuccess,
  getLessonsDataIdSuccess,
  getLessonsDataFail,
} from "../actions/LessonsActions";

function* workerLessonsData(params) {
  try {
    const response = yield call(
      PromiseService.getData,
      `${LESSONS_DATA_LINK}/${params.value}`
    );
    if (response.status === 200 && response.data) {
      yield put(getLessonsDataIdSuccess(response.data));
    } else {
      yield put(getLessonsDataFail(response));
    }
  } catch (error) {
    yield put(getLessonsDataFail(error));
  }
}

export const watcherLessons = [takeEvery(LESSONS_DATA, workerLessonsData)];
