import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Tabs,
  Tab,
  Form,
  Table,
  Button,
  Container,
} from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import { postUsersData } from "../../redux/actions/UsersActions";
import { SkValidate } from "../../components/common/SkValidate";
const MySwal = withReactContent(Swal);
function Setting() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const history = useHistory();
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    address: "",
    password: "",
  });
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };
  useEffect(() => {
    if (users.usersDataPost === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat submit data", "Ok");
    } else {
      // close modal and refresh the page
    }
  }, [users]);
  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      console.log(value, "value");
    });
  }
  const handleSubmitForm = (e) => {
    e.preventDefault();
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      setTrigger(true);
      dispatch(postUsersData(data));
    }
  };

  const constraints = {
    name: {
      presence: true,
      length: {
        minimum: 3,
        maximum: 30,
      },
    },
    email: {
      presence: true,
      length: {
        maximum: 20,
      },
      email: true,
    },
    address: {
      presence: true,
      length: {
        minimum: 3,
        maximum: 100,
      },
    },
    password: {
      presence: true,
      length: {
        minimum: 6,
        maximum: 100,
      },
    },
  };
  return (
    <Container className="dashboard_page__container">
      <h2 className="h2 dashboard__section_title mb-4">Pengaturan Saya</h2>
      <Card className="p-4">
        <Tabs
          defaultActiveKey="akunsaya"
          transition={false}
          id="noanim-tab-example"
          className="px-3"
        >
          <Tab tabClassName="" eventKey="akunsaya" title="Akun Saya">
            <Row className="my-5">
              <Col md={3}>
                <div className="image_profile"></div>
                <Button className="w-100 profil_padding">Ubah Foto</Button>
                <div className="image_profile__text py-3">
                  Besar file: maksimum 10.000.000 bytes (10 Megabytes). Ekstensi
                  file yang diperbolehkan: .JPG .JPEG .PNG
                </div>
              </Col>
              <Col md={9}>
                <div className="form_ubah_data">
                  <Form onSubmit={handleSubmitForm}>
                    <Row>
                      <Col>
                        <div className="form_profil mb-4">
                          <div className="title_input mb-3">Ubah Data Diri</div>
                          <Form.Group>
                            <Form.Label className="label_input__sub mb-2">
                              Nama Lengkap
                            </Form.Label>
                            <Form.Control
                              onChange={handleChange}
                              className="mb-1 br-8 input_setting"
                              name="name"
                              id="name"
                              type="text"
                              value={data.name}
                            />
                            <div className="messages"></div>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label className="label_input__sub mb-2">
                              Alamat
                            </Form.Label>
                            <Form.Control
                              onChange={handleChange}
                              className="mb-1 br-8 input_setting"
                              name="address"
                              id="address"
                              type="text"
                              value={data.address}
                            />
                            <div className="messages"></div>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col>
                        <div className="form_akun">
                          <div className="title_input mb-3">
                            Ubah Informasi Akun
                          </div>
                          <Form.Group>
                            <Form.Label className="label_input__sub mb-2">
                              Email
                            </Form.Label>
                            <Form.Control
                              onChange={handleChange}
                              className="mb-1 br-8 input_setting"
                              name="email"
                              id="email"
                              type="text"
                              value={data.email}
                            />
                            <div className="messages"></div>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label className="label_input__sub mb-2">
                              Password
                            </Form.Label>
                            <Form.Control
                              className="mb-1 br-8 input_setting"
                              onChange={handleChange}
                              name="password"
                              id="password"
                              type="password"
                              value={data.password}
                            />
                            <div className="messages"></div>
                          </Form.Group>
                        </div>
                      </Col>
                    </Row>
                    <div className="text-right">
                      <Button className="button_save__setting" type="submit">
                        Simpan
                      </Button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Tab>

          {/* <Tab
            tabClassName="text-blacks"
            eventKey="percakapan"
            title="Percakapan"
          >
            <Row className="my-5">
              <Col>
                <div className="title_input mb-3">Pengaturan Chat</div>
                <div className="label_input__sub mb-3">
                  Tidak menerima chat dari admin
                </div>
                <div className="label_input__sub mb-3">Tampilkan nama asli</div>

                <div className="label_input__sub mb-3">
                  Tidak menerima chat dari user lain
                </div>
              </Col>
            </Row>
          </Tab> */}
          {/* <Tab
            tabClassName="text-blacks"
            eventKey="notifikasi"
            title="Notifikasi"
          >
            <Row className="my-5">
              <Col>
                <Table>
                  <thead>
                    <th className="title_input mb-3 w-100">Pilih Notifikasi</th>
                    <th></th>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="label_input__sub bt-simp">
                        Assign Task Baru
                      </td>
                      <td>
                        <Form.Check className="mb-4" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td className="label_input__sub bt-simp">Tugas Baru</td>
                      <td>
                        <Form.Check className="mb-4" type="checkbox" />
                      </td>
                    </tr>
                    <tr>
                      <td className="label_input__sub bt-simp">
                        Tampilkan nama asli
                      </td>{" "}
                      <td>
                        <Form.Check className="mb-4" type="checkbox" />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Tab>
         */}
        </Tabs>
      </Card>
    </Container>
  );
}

export default Setting;
