import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "./Loading";
import Layout from "./Layout";
import LayoutNoSidebar from "./LayoutNoSidebar";
import { useLocation } from "react-router-dom";
import { getUsersData } from "../../redux/actions/UsersActions";
import { AuthIsLogin, AuthData } from "../../helper/auth";
// import { useHistory } from "react-router-dom";
import "../../assets/css/Layout/template_main.css";

function Template({ InnerComponent, isSidebar, isHeader }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const courses = useSelector((state) => state.courses.isLoading);
  const course_types = useSelector((state) => state.course_types.isLoading);
  const learning_paths = useSelector((state) => state.learning_paths.isLoading);
  const lessons = useSelector((state) => state.lessons.isLoading);
  const login = useSelector((state) => state.login.isLoading);
  const logins = useSelector((state) => state.login);
  const quizzes = useSelector((state) => state.quizzes.isLoading);
  const users = useSelector((state) => state.users.isLoading);
  const orders = useSelector((state) => state.orders.isLoading);
  const assets = useSelector((state) => state.assets.isLoading);
  const assignments = useSelector((state) => state.assignments.isLoading);
  const purchases = useSelector((state) => state.purchases.isLoading);
  const payments = useSelector((state) => state.payments.isLoading);

  useEffect(() => {
    if (AuthIsLogin("", true)) dispatch(getUsersData(AuthData().userId));
  }, []);

  useEffect(() => {
    if (logins.loginData) dispatch(getUsersData(logins.loginData.data.user_id));
  }, [logins.loginData]);

  useEffect(() => {}, [location]);

  useEffect(() => {
    if (
      !courses &&
      !course_types &&
      !learning_paths &&
      !lessons &&
      !login &&
      !quizzes &&
      !users &&
      !orders &&
      !assets &&
      !assignments &&
      !purchases &&
      !payments
    )
      setIsLoading(false);
    else setIsLoading(true);
  }, [
    courses,
    course_types,
    learning_paths,
    lessons,
    login,
    quizzes,
    users,
    orders,
    assets,
    assignments,
    purchases,
    payments,
  ]);

  return (
    <>
      <Loading isLoading={isLoading} />
      {isSidebar ? (
        <Layout isSidebar={isSidebar} InnerComponent={InnerComponent} />
      ) : isHeader ? (
        <LayoutNoSidebar InnerComponent={InnerComponent} />
      ) : (
        <InnerComponent />
      )}
    </>
  );
}

export default Template;
