import { call, put, takeEvery } from "redux-saga/effects";
import { PromiseService } from "../../services/PromiseService";
import {
  COURSE_TYPES_DATA,
  COURSE_TYPES_DATA_LINK,
} from "../config/CourseTypesConfig";
import {
  getCourseTypesDataSuccess,
  getCourseTypesDataIdSuccess,
  getCourseTypesDataFail,
} from "../actions/CourseTypesActions";

function* workerCourseTypesData(params) {
  if (params.value) {
    try {
      const response = yield call(
        PromiseService.getData,
        `${COURSE_TYPES_DATA_LINK}/${params.value}`
      );
      if (response.status === 200 && response.data) {
        yield put(getCourseTypesDataIdSuccess(response.data));
      } else {
        yield put(getCourseTypesDataFail(response));
      }
    } catch (error) {
      yield put(getCourseTypesDataFail(error));
    }
  } else {
    try {
      const response = yield call(
        PromiseService.getData,
        COURSE_TYPES_DATA_LINK
      );
      if (response.status === 200 && response.data) {
        yield put(getCourseTypesDataSuccess(response.data));
      } else {
        yield put(getCourseTypesDataFail(response));
      }
    } catch (error) {
      yield put(getCourseTypesDataFail(error));
    }
  }
}

export const watcherCourseTypes = [
  takeEvery(COURSE_TYPES_DATA, workerCourseTypesData),
];
