import React, { useState } from "react";
import { Button, Row, Col, Container, Form, Card } from "react-bootstrap";
import "../../assets/css/Account/index.css";
import { SkValidate } from "../../components/common/SkValidate";
import { FaUserAlt } from "react-icons/fa";
function ForgotPassword() {
  const [data, setData] = useState({
    email: "",
  });
  function onSubmitHandler(e) {
    e.preventDefault();
    const form = e.target;

    const isValid = SkValidate(form, constraints);
    if (isValid) {
      // dispatch(getLoginData(body));
      // console.log(body);
    }
  }
  const onChangeHandler = (e) => {
    const newData = { ...data };
    newData[e.target.id] =
      e.target.id === "price" ? parseInt(e.target.value) : e.target.value;
    setData(newData);
  };
  const constraints = {
    email: {
      presence: true,
      length: {
        maximum: 30,
      },
      email: true,
    },
  };
  return (
    <Container className="height-full">
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={10} sm={10} md={4} lg={5}>
          <div className="text-center">
            <div className="login-title">Lupa Password</div>
          </div>
          <div>
            <Card className="p-5">
              <Form onSubmit={onSubmitHandler}>
                <Form.Group className="mb-5">
                  <div className="position-absolute p-3 d-flex">
                    <FaUserAlt />
                  </div>
                  <Form.Control
                    className="mb-3 input100"
                    type="text"
                    placeholder="Masukkan Email Anda"
                    id="email"
                    name="email"
                    value={data.email}
                    onChange={onChangeHandler}
                  />
                  <div className="messages"></div>
                </Form.Group>

                <Button type="submit" className="w-100 login-button mb-3">
                  <div className="login-text">Kirim</div>
                </Button>
                <h5
                  id="emailHelp"
                  className="form-text text-center login-register-text"
                >
                  <span>
                    <a
                      href="/login"
                      className="text-decoration-none color-primary"
                    >
                      Lupakan ini, saya ingin masuk
                    </a>
                  </span>
                </h5>
              </Form>
            </Card>
            <Form></Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPassword;
