import moment from "moment";

export function CreateResult(data, type, id) {
  // function that use for multiselect result
  let result = [];
  for (let i = 0; i < data.length; i++) {
    if (type === "array") result.push(data[i]);
    else if (type === "json") result.push({ [`${id}_id`]: data[i] });
  }
  return result;
}

export function CreateSelected(data, id) {
  // function that use for multiselect selected
  let result = [];
  if (data) {
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        result.push(data[i][`${id}_id`]);
      }
    }
  }
  return result;
}

export function CreateOption(items, type, id, label) {
  // function that use for multiselect option
  let object = [];
  for (let i = 0; i < items.length; i++) {
    const cond = items[i].role === "instructor" ? true : false;
    const condition = type !== "instructors" ? true : cond;
    if (condition) object.push({ key: items[i][id], label: items[i][label] });
  }
  return object;
}

export function CheckIfEmpty(data) {
  // check wheter object have empty key value
  for (var key in data) {
    var value = data[key];
    if (value === []) return true;
    if (value === [""]) return true;
    if (value === "") return true;
    if (value) {
      if (value.length <= 0) return true;
    } else return true;
  }
  return false;
}
export function Clone(obj) {
  //clone object without change the original object
  if (null === obj || "object" !== typeof obj) return obj;
  var copy = obj.constructor();
  for (var attr in obj) {
    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy;
}

export function Capitalize(s) {
  //used for capitalized first letter
  return s[0].toUpperCase() + s.slice(1);
}

export function ValidateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function convertHours(number) {
  number = Number(number);
  var h = Math.floor(number / 3600);
  var m = Math.floor((number % 3600) / 60);
  var s = Math.floor((number % 3600) % 60);

  var hDisplay = h > 0 ? h + "j" : " ";
  var mDisplay = m > 0 ? m + "m" : " ";
  var sDisplay = s > 0 ? s + "s" : " ";
  return hDisplay + " " + mDisplay + " " + sDisplay;
}

export function KeepSpesificKey(data) {
  let newItem = [];
  let keyToKeep = ["course_id", "certificate_id"];
  const result = (array) =>
    array.map((o) =>
      keyToKeep.reduce((acc, curr) => {
        if (o[curr]) newItem.push(o[curr]);
        console.log(newItem);
        return newItem;
      }, {})
    );
  return result(data)[0];
}

export function FormatCurrency(number) {
  const formatter = new Intl.NumberFormat(["ban", "id"], {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  });
  return formatter.format(number);
}

export function SanitizeMaterialTypeId(material) {
  // get special properties based on material type
  // short is like duration of video/audio, pages of pdf/document
  let tmp = {
    type: material.type,
    id: "",
    short: "",
  };
  switch (material.type) {
    case "quiz_themes":
      tmp = {
        ...tmp,
        type: "quiz",
        id: material.theme_id,
        short: "10 Soal",
      };
      break;
    case "quizzes":
      tmp = {
        ...tmp,
        type: "quiz",
        id: material.quiz_id,
        short: "10 Soal",
      };
      break;
    case "assignments":
      tmp = {
        ...tmp,
        id: material.assignment_id,
        short: "1 File",
      };
      break;
    case "lessons":
      // just a reminder: lesson can be AUDIO, PDF, VIDEO H5P, VIDEO DYNTUBE
      tmp = {
        ...tmp,
        id: material.lesson_id,
        short: moment.utc(material.duration).format("HH:mm"),
      };
      break;
    case "webinars":
      tmp = {
        ...tmp,
        id: material.webinar_id,
        short: moment.utc(material.duration).format("HH:mm"),
      };
      break;
    default:
      tmp = tmp;
      break;
  }
  return tmp;
}

export function slugify(content) {
   return content?.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
}