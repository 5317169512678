import { call, put, takeEvery } from "redux-saga/effects";
import { LOGIN_DATA, LOGIN_DATA_LINK } from "../config/LoginConfig";
import { getLoginDataSuccess, getLoginDataFail } from "../actions/LoginActions";
import { PromiseService } from "../../services/PromiseService";

function* workerLoginData(params) {
  const body = params.value;
  try {
    const response = yield call(
      PromiseService.postData,
      LOGIN_DATA_LINK,
      body,
      true
    );
    if (response.status === 200 && response.data) {
      yield put(getLoginDataSuccess(response.data));
    } else {
      yield put(getLoginDataFail(response));
    }
  } catch (error) {
    yield put(getLoginDataFail(error));
  }
}

export const watcherLogin = [takeEvery(LOGIN_DATA, workerLoginData)];
