import { call, put, takeEvery } from "redux-saga/effects";
import { PromiseService } from "../../services/PromiseService";
import {
  ORDERS_DATA_LINK,
  //get provinces
  ORDERS_PROVINCES,
  //get cities
  ORDERS_CITIES,
  //get subdistricts
  ORDERS_SUBDISTRICTS,
  //get couriers
  ORDERS_COURIERS,
  //get price
  ORDERS_PRICE,
  //post order
  ORDERS_POST,
} from "../config/OrdersConfig";
import {
  //get provinces
  getProvincesDataSuccess,
  getProvincesDataFail,
  //get cities
  getCitiesDataSuccess,
  getCitiesDataFail,
  //get subdistricts
  getSubdistrictsDataSuccess,
  getSubdistrictsDataFail,
  //get couriers
  getCouriersDataSuccess,
  getCouriersDataFail,
  //get price
  getPricesDataSuccess,
  getPricesDataFail,
  //post order
  postOrdersDataSuccess,
  postOrdersDataFail,
} from "../actions/OrdersActions";

function* workerOrderProvinces(data) {
  try {
    const response = yield call(
      PromiseService.getData,
      `${ORDERS_DATA_LINK}/provinces`
    );
    if (response.status === 200 && response.data) {
      yield put(getProvincesDataSuccess(response.data));
    } else {
      yield put(getProvincesDataFail(response));
    }
  } catch (error) {
    yield put(getProvincesDataFail(error));
  }
}

function* workerOrderCities(data) {
  const id = data.value;
  try {
    const response = yield call(
      PromiseService.getData,
      `${ORDERS_DATA_LINK}/cities?province_id=${id}`
    );
    if (response.status === 200 && response.data) {
      yield put(getCitiesDataSuccess(response.data));
    } else {
      yield put(getCitiesDataFail(response));
    }
  } catch (error) {
    yield put(getCitiesDataFail(error));
  }
}

function* workerOrderSubdistricts(data) {
  const id = data.value;
  try {
    const response = yield call(
      PromiseService.getData,
      `${ORDERS_DATA_LINK}/subdistricts?city_id=${id}`
    );
    if (response.status === 200 && response.data) {
      yield put(getSubdistrictsDataSuccess(response.data));
    } else {
      yield put(getSubdistrictsDataFail(response));
    }
  } catch (error) {
    yield put(getSubdistrictsDataFail(error));
  }
}

function* workerOrderCouriers(data) {
  try {
    const response = yield call(
      PromiseService.getData,
      `${ORDERS_DATA_LINK}/couriers`
    );
    if (response.status === 200 && response.data) {
      yield put(getCouriersDataSuccess(response.data));
    } else {
      yield put(getCouriersDataFail(response));
    }
  } catch (error) {
    yield put(getCouriersDataFail(error));
  }
}

function* workerOrderPrice(data) {
  const param = `price?subdistrict_origin_id=2103&subdistrict_destination_id=${
    data.value.subdistrict.split("_")[0]
  }&courier=${data.value.courier}&weight=100`;
  try {
    const response = yield call(
      PromiseService.getData,
      `${ORDERS_DATA_LINK}/${param}`
    );
    if (response.status === 200 && response.data) {
      yield put(
        getPricesDataSuccess(response.data.rajaongkir.results[0].costs)
      );
    } else {
      yield put(getPricesDataFail(response));
    }
  } catch (error) {
    yield put(getPricesDataFail(error));
  }
}

function* workerOrderPost(params) {
  const body = params.value;
  try {
    const response = yield call(
      PromiseService.postData,
      ORDERS_DATA_LINK,
      body
    );
    if (response.status === 201 || (response.status === 200 && response.data)) {
      yield put(postOrdersDataSuccess(response.data));
    } else {
      yield put(postOrdersDataFail(response));
    }
  } catch (error) {
    yield put(postOrdersDataFail(error));
  }
}

export const watcherOrders = [
  takeEvery(ORDERS_PROVINCES, workerOrderProvinces),
  takeEvery(ORDERS_CITIES, workerOrderCities),
  takeEvery(ORDERS_SUBDISTRICTS, workerOrderSubdistricts),
  takeEvery(ORDERS_COURIERS, workerOrderCouriers),
  takeEvery(ORDERS_PRICE, workerOrderPrice),
  takeEvery(ORDERS_POST, workerOrderPost),
];
