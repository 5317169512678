import React from "react";
import { Card } from "react-bootstrap";

function CategoryRegulationList() {
  return (
    <Card className="card-regulation-list">
      <div className="card-regulation-list__title-main">Peraturan Pusat</div>
      <ul className="card-regulation-list__content">
        <li>
          <div className="card-regulation-list__inside">
            <a href="#bidangusaha">Bidang Usaha</a>
          </div>
        </li>
        <li>
          <div className="card-regulation-list__inside">
            <a href="#bidanghukum">Bidang Hukum</a>
          </div>
        </li>
      </ul>
      <div className="card-regulation-list__title-main">Peraturan Daerah</div>
      <div className="dropdown-divider my-3"></div>

      <div className="card-regulation-list__title-main">Putusan</div>
    </Card>
  );
}
export default CategoryRegulationList;
