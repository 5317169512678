import React, { useEffect, useState } from "react";
import { Row, Col, Container, Tabs, Tab } from "react-bootstrap";
import { FaEnvelope } from "react-icons/fa";
import {
  GrInstagram,
  GrLinkedinOption,
  GrFacebookOption,
  GrTwitter,
} from "react-icons/gr";
import { AuthIsLogin, AuthData } from "../../helper/auth";
import { useHistory, Link } from "react-router-dom";
import { getUsersData, putUsersData } from "../../redux/actions/UsersActions";
import { useDispatch, useSelector } from "react-redux";
import { CheckIfEmpty } from "../../helper/functions";
import moment from "moment";
import "../../assets/css/Personal/Profile.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
function Profile() {
  const defaltData = {
    name: "",
    email: "",
    company: "",
    twitter: "",
    facebook: "",
    instagram: "",
    linkedin: "",
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [usersDataId, setUsersDataId] = useState();
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState(defaltData);

  useEffect(() => {
    AuthIsLogin(history);
    dispatch(getUsersData(AuthData().userId));
  }, []);

  useEffect(() => {
    const newData = { ...data };
    if (users.usersDataId.name) newData.name = users.usersDataId.name;
    if (users.usersDataId.email) newData.email = users.usersDataId.email;
    if (users.usersDataId.company) newData.company = users.usersDataId.company;
    setData(newData);
    setUsersDataId(users.usersDataId);
  }, [users]);

  useEffect(() => {
    if (users.usersDataPut === "fail" && trigger) {
      setTrigger(false);
      displayPopup(true, "Terjadi masalah saat edit data", false);
    }
    if (users.usersDataPut === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil mengubah data", true, "Ok");
    }
  }, [users]);

  const onChangeHandler = (e) => {
    const newData = { ...data };
    newData[e.target.id] =
      e.target.id === "price" ? parseInt(e.target.value) : e.target.value;
    setData(newData);
  };
  const editHandler = (id) => {
    console.log(data);
    if (!CheckIfEmpty(data)) {
      setTrigger(true);
      let req = {};
      req.body = data;
      req.id = usersDataId.user_id;
      dispatch(putUsersData(req));
    } else displayPopup(true, "Mohon diisi semua", false);
  };

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (confirm) {
        dispatch(getUsersData(AuthData().userId));
      }
    });
  }

  return (
    <Container className="dashboard_page__container">
      {usersDataId ? (
        <>
          <h2 className="h2 dashboard__section_title">Profile Saya</h2>
          <Row>
            <Col sm={12} md={4}>
              <div className="profile__top_wrapper">
                <div className="profile__name_wrapper">
                  <div className="profile__name">{usersDataId.name}</div>
                  <div className="profile__join_date">
                    Bergabung {moment(usersDataId.created_at).format("MMMM DD")}
                  </div>
                </div>
                <div className="profile__kontak_wrapper">
                  <div className="profile__kontak">Kontak</div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="profile_icon__wrapper satukelas_flex ">
                      <FaEnvelope className="svg-sosmed" />
                    </div>
                    <div className="profile_email">{usersDataId.email}</div>
                  </div>
                  <div className="profile__kontak">Sosial Media</div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="profile_icon__wrapper satukelas_flex mr-0">
                        <GrInstagram className="svg-sosmed" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="profile_icon__wrapper satukelas_flex mr-0">
                        <GrLinkedinOption className="svg-sosmed" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="profile_icon__wrapper satukelas_flex mr-0">
                        <GrFacebookOption className="svg-sosmed" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="profile_icon__wrapper satukelas_flex mr-0">
                        <GrTwitter className="svg-sosmed" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile__my_course__wrapper">
                <h2 className="h2 profile_label_input">Pelatihan Saya</h2>
                {usersDataId.my_courses
                  ? usersDataId.my_courses.splice(0, 3).map((data, index) => {
                      return (
                        <div className="Grey-Pajak" key={index}>
                          <div className="White-Pajak"></div>
                          <div className="Pajak">{data.course}</div>
                        </div>
                      );
                    })
                  : null}
                <Link
                  className="py-3 btn btn-primary w-100"
                  to="/dashboard/course"
                >
                  Lihat selengkapnya
                </Link>
              </div>
              <div className="profile__my_course__wrapper">
                <h2 className="h2 profile_label_input">Path Saya</h2>
                {/*usersDataId.my_courses.map((data, index) => {
                  return (
                    <div className="Grey-Pajak" key={index}>
                      <div className="White-Pajak"></div>
                      <div className="Pajak">{data.course}</div>
                    </div>
                  );
                })*/}
              </div>
            </Col>

            <Col sm={12} md={8}>
              <Tabs
                defaultActiveKey="info"
                transition={false}
                id="noanim-tab-example"
                className=""
              >
                <Tab
                  tabClassName="text-blacks"
                  eventKey="info"
                  title="Informasi Pribadi"
                >
                  <div className="biodata_wrapper">
                    <Row>
                      <Col sm={12} md={6} className="mb-0">
                        <div className="profile_label_input">Nama Lengkap</div>
                        <input
                          className="satukelas_input"
                          id="name"
                          value={data.name}
                          onChange={onChangeHandler}
                        ></input>
                        <div className="profile_label_input">Email</div>
                        <input
                          className="satukelas_input"
                          id="email"
                          value={data.email}
                          onChange={onChangeHandler}
                        ></input>
                      </Col>
                      <Col sm={12} md={6} className="mb-0">
                        <div className="profile_label_input">Nama Kantor</div>
                        <input
                          className="satukelas_input"
                          id="company"
                          value={data.company}
                          onChange={onChangeHandler}
                        ></input>
                        <div className="profile_label_input">Jabatan</div>
                        <input
                          className="satukelas_input"
                          id="pos"
                          value={"Jabatan"}
                          onChange={onChangeHandler}
                        ></input>
                      </Col>
                      <Col sm={12} md={6} className="mb-0">
                        <div className="profile_label_input">No. HP</div>
                        <input
                          className="satukelas_input"
                          id="hp"
                          // value={"Jabatan"}
                          onChange={onChangeHandler}
                        ></input>
                      </Col>
                      <Col sm={12} md={6} className="mb-0">
                        <div className="profile_label_input">Tanggal Lahir</div>
                        <input
                          className="satukelas_input"
                          id="hp"
                          // value={"Jabatan"}
                          onChange={onChangeHandler}
                        ></input>
                      </Col>
                      <Col md={12} className="mb-0">
                        <div className="profile_label_input">Alamat</div>
                        <textarea
                          className="satukelas_input"
                          id="address"
                          rows="3"
                          // value={data.company}
                          // onChange={onChangeHandler}
                        />
                      </Col>
                      <Col sm={12}>
                        <div className="profile_biodata_btn__wrapper d-flex">
                          <div className="profile_cancel__btn align-items-center d-flex mr-5">
                            Batal
                          </div>
                          <div
                            className="profile_biodata_btn profile_edit__btn primary"
                            onClick={editHandler}
                          >
                            Ubah
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Tab>

                <Tab
                  tabClassName=""
                  eventKey="sosialmedia"
                  title="Sosial Media"
                >
                  <div className="biodata_wrapper">
                    <Row className="mt-4">
                      <Col md={6} className="mb-0">
                        <div className="profile_label_input">Instagram</div>
                        <input
                          className="satukelas_input"
                          id="instagram"
                          // value={data.instagram}
                          // onChange={onChangeHandler}
                        ></input>
                      </Col>
                      <Col md={6} className="mb-0">
                        <div className="profile_label_input">Facebook</div>
                        <input
                          className="satukelas_input"
                          id="facebook"
                          // value={data.facebook}
                          // onChange={onChangeHandler}
                        ></input>
                      </Col>
                      <Col md={6} className="mb-0">
                        <div className="profile_label_input">Twitter</div>
                        <input
                          className="satukelas_input"
                          id="twitter"
                          // value={data.twitter}
                          // onChange={onChangeHandler}
                        ></input>
                      </Col>
                      <Col md={6} className="mb-0">
                        <div className="profile_label_input">LinkedIn</div>
                        <input
                          className="satukelas_input"
                          id="linkedin"
                          // value={data.linkedin}
                          // onChange={onChangeHandler}
                        ></input>
                      </Col>
                      <Col sm={12}>
                        <div className="profile_biodata_btn__wrapper d-flex">
                          <div className="profile_cancel__btn align-items-center d-flex mr-5">
                            Batal
                          </div>
                          <div
                            className="profile_biodata_btn profile_edit__btn primary"
                            // onClick={editHandler}
                          >
                            Simpan
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Tab>
              </Tabs>
              <div className="profile_membership__wrapper">
                <h2 className="h2 biodata_title">Membership</h2>
                <div className="membership_card__wrapper">
                  <div className="membership_card__type">Membership 1</div>
                  <div className="membership_card__price">Rp. 1.500.000,-</div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      ) : null}
    </Container>
  );
}

export default Profile;
