import {
  WEBINARS_DATA,
  WEBINARS_DATA_ID_SUCCESS,
  WEBINARS_DATA_FAIL,
} from "../config/WebinarsConfig";

export const getWebinarsData = (value) => ({
  type: WEBINARS_DATA,
  value,
});
export const getWebinarsDataIdSuccess = (value) => ({
  type: WEBINARS_DATA_ID_SUCCESS,
  value,
});
export const getWebinarsDataFail = (value) => ({
  type: WEBINARS_DATA_FAIL,
  value,
});
