import React, { useState, useEffect } from "react";
import { Button, Row, Col, Container, Form, Card } from "react-bootstrap";
import { Clone } from "../../helper/functions";
import "../../assets/css/Account/index.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { postUsersData } from "../../redux/actions/UsersActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getProvincesData,
  getCitiesData,
  getSubdistrictsData,
} from "../../redux/actions/OrdersActions";
import {
  FaUserAlt,
  FaBuilding,
  FaArrowRight,
  FaCalendar,
  FaEnvelope,
  FaLock,
} from "react-icons/fa";
import { SkValidate } from "../../components/common/SkValidate";
const MySwal = withReactContent(Swal);
const sha256 = require("js-sha256");

function Register() {
  const [provinces, setProvinces] = useState([]);
  const [next, setNext] = useState(0);
  const [cities, setCities] = useState([]);
  const [subdistricts, setSubdistricts] = useState([]);
  const [data, setData] = useState({
    company: "",
    email: "",
    name: "",
    password: "",
    repassword: "",
    username: "",
    date: "",
    gender: "",
    know: "",
    terms: false,
    province: "",
    city: "",
    subdistrict: "",
    job: "",
    education: "",
    school: "",
    jurusan: "",
  });
  const [trigger, setTrigger] = useState(false);
  const users = useSelector((state) => state.users);
  const orders = useSelector((state) => state.orders);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getProvincesData());
    console.log(getProvincesData());
  }, []);

  //get city
  useEffect(() => {
    if (data.province) {
      const newDestination = { ...data };
      newDestination.subdistrict = "";
      newDestination.city = "";
      setData(newDestination);
      dispatch(getCitiesData(data.province.split("_")[0]));
    }
  }, [data.province]);

  //get subdistrict
  useEffect(() => {
    if (data.city) {
      const newDestination = { ...data };
      newDestination.subdistrict = "";
      setData(newDestination);
      dispatch(getSubdistrictsData(data.city.split("_")[0]));
    }
  }, [data.city]);

  useEffect(() => {
    if (orders.ordersProvinces) setProvinces(orders.ordersProvinces);
    if (orders.ordersCities) setCities(orders.ordersCities);
    if (orders.ordersSubdistricts) setSubdistricts(orders.ordersSubdistricts);
  }, [orders]);

  useEffect(() => {
    if (users.usersDataPost === "fail" && trigger) {
      setTrigger(false);
      displayPopup(false, "Registrasi Gagal!", "Oke");
    }
    if (users.usersDataPost === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Registrasi Berhasil!", "Oke", "login");
    }
  }, [users.usersDataPost]);
  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };
  const onChangeHandler = (e) => {
    const newData = { ...data };
    const id = e.target.id;
    if (id === "terms") {
      newData[id] = !data.terms;
    } else newData[id] = e.target.value;
    setData(newData);
  };
  const onChangeHandlerGender = (e) => {
    const newData = { ...data };
    const id = e.target.id;
    newData[id] = e.target.value;
    setData(newData);
    console.log(id);
  };
  function onSubmitHandler(e) {
    e.preventDefault();
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      let body = Clone(data);
      delete body.terms;
      delete body.repassword;
      body.password = sha256(data.password);
      body.role = "student";
      dispatch(postUsersData(body));
      setTrigger(true);
    }
  }

  function displayPopup(cancel, title, confirm, nextPage) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      // console.log(nextPage);
      if (nextPage) history.push(`/${nextPage}`);
    });
  }
  const constraints = {
    company: {
      presence: true,
      length: {
        maximum: 100,
      },
    },
    email: {
      presence: true,
      length: {
        maximum: 20,
      },
      email: true,
    },
    name: {
      presence: true,
      length: {
        maximum: 20,
      },
    },
    password: {
      presence: true,
      length: {
        minimum: 6,
        maximum: 20,
      },
    },
    confirm_password: {
      presence: true,
      length: {
        minimum: 6,
        maximum: 20,
      },
      equality: "password",
    },
    username: {
      presence: true,
      length: {
        maximum: 20,
      },
    },
  };
  const handleNext = (id) => {
    setNext(id);
  };
  return (
    <Container className="register-inherit">
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={12} sm={12} md={6} lg={6} className="wrapper-register">
          {/* <div className="text-center">
            <div className="login-title">Daftar</div>
          </div> */}
          <Card className="card-padding__register">
            <Form onSubmit={onSubmitHandler}>
              {/* <div className="register-logo">
                <img
                  src="https://satukelas.com/wp-content/uploads/2021/02/png-satukelas-296x63.png"
                  alt="Satukelas"
                  className="satu-kelas-logo "
                />
              </div> */}
              {next === 0 ? (
                <>
                  <div className="register-step__first-title">
                    Ikut survey dari kami ya!
                  </div>
                  <div className="register-step__first-subtitle">
                    Bantu kami untuk dapat berkembang lebih baik !
                  </div>
                  <Row>
                    <Col>
                      <Form.Group>
                        <div className="login-label">Tahu dari Mana?</div>
                        <div className="position-absolute p-3 d-flex">
                          <FaUserAlt />
                        </div>
                        <Form.Control
                          className="mb-3 input100"
                          type="username"
                          placeholder="Dari mana?"
                          id="know"
                          name="know"
                          value={data.know}
                          onChange={onChangeHandler}
                        />
                        <div className="messages"></div>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className="cart_label">
                          Pekerjaan
                        </Form.Label>
                        <div className="position-absolute p-3 d-flex">
                          <FaUserAlt />
                        </div>
                        <Form.Control
                          className="register-select"
                          required
                          as="select"
                          type="select"
                          name="job"
                          id="job"
                          value={data.job}
                          onChange={handleChange}
                        >
                          <option hidden value key="blankChoice">
                            Pilih Provinsi...
                          </option>
                          <option value="pegawainegeri">Pegawai Negeri</option>
                          <option value="pegawainegeri">Karyawan Swasta</option>
                          <option value="pegawainegeri">Magang</option>
                          <option value="pegawainegeri">Freelance</option>
                          <option value="pegawainegeri">Enterpreneur</option>
                          <option value="pegawainegeri">
                            Mahasiswa /Pelajar
                          </option>
                          <option value="pegawainegeri">Fresh Graduate</option>
                          <option value="pegawainegeri">Lainnya</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group>
                    {" "}
                    <div className="login-label">Perusahaan</div>
                    <div className="position-absolute p-3 d-flex">
                      <FaBuilding />
                    </div>
                    <Form.Control
                      className="mb-3 input100"
                      type="company"
                      placeholder="Masukkan Perusahaan Anda"
                      id="company"
                      name="company"
                      value={data.company}
                      onChange={onChangeHandler}
                    />
                    <div className="messages"></div>
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className="cart_label">
                          Tingkat Pendidikan
                        </Form.Label>
                        <div className="position-absolute p-3 d-flex">
                          <FaUserAlt />
                        </div>
                        <Form.Control
                          className="register-select"
                          required
                          as="select"
                          type="select"
                          name="education"
                          id="education"
                          value={data.education}
                          onChange={handleChange}
                        >
                          <option hidden value key="blankChoice">
                            Pilih Tingkat Pendidikan
                          </option>
                          <option value="dasar">SD</option>
                          <option value="menengahpertama">SMP</option>
                          <option value="menengahatas">SMA/K</option>
                          <option value="diploma">Diploma</option>
                          <option value="sarjana">Sarjana</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <div className="login-label">Nama Univ. / Sekolah</div>
                        <div className="position-absolute p-3 d-flex">
                          <FaUserAlt />
                        </div>
                        <Form.Control
                          className="mb-3 input100"
                          type="username"
                          placeholder="Universitas / Sekolah"
                          id="school"
                          name="school"
                          value={data.school}
                          onChange={onChangeHandler}
                        />
                        <div className="messages"></div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group>
                    <Form.Label className="cart_label">Jurusan apa?</Form.Label>
                    <div className="position-absolute p-3 d-flex">
                      <FaUserAlt />
                    </div>
                    <Form.Control
                      className="register-select"
                      required
                      as="select"
                      type="select"
                      name="jurusan"
                      id="jurusan"
                      value={data.jurusan}
                      onChange={handleChange}
                    >
                      <option hidden value key="blankChoice">
                        Pilih Jurusan
                      </option>
                      <option value="dasar">SD</option>
                      <option value="menengahpertama">SMP</option>
                      <option value="menengahatas">SMA/K</option>
                      <option value="diploma">Diploma</option>
                      <option value="sarjana">Sarjana</option>
                    </Form.Control>
                  </Form.Group>
                  <div className="d-flex justify-content-between">
                    <div className="text-center login-register-text">
                      Sudah punya akun?{" "}
                      <span>
                        <a
                          href="/login"
                          className="text-decoration-none login-register-text__span"
                        >
                          Masuk
                        </a>
                      </span>
                    </div>
                    <Button
                      onClick={() => handleNext(1)}
                      className="register-next__button"
                    >
                      Selanjutnya
                      <FaArrowRight className="arrow-register" />
                    </Button>
                  </div>
                </>
              ) : next === 1 ? (
                <>
                  <div className="register-step__first-title">
                    Biodata Diri Anda
                  </div>
                  <div className="register-step__first-subtitle">
                    Isi lengkap ya biodatanya
                  </div>
                  <Row>
                    <Col>
                      <Form.Group>
                        <div className="login-label">Nama</div>
                        <div className="position-absolute p-3 d-flex">
                          <FaUserAlt />
                        </div>
                        <Form.Control
                          className="mb-3 input100"
                          type="name"
                          placeholder="Nama Lengkap"
                          id="name"
                          name="name"
                          value={data.name}
                          onChange={onChangeHandler}
                        />
                        <div className="messages"></div>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <div className="login-label">Tanggal Lahir</div>
                        <div className="position-absolute p-3 d-flex">
                          <FaCalendar />
                        </div>
                        <Form.Control
                          className="mb-3 input100"
                          type="date"
                          // id="date"
                          // name="date"
                          // value={data.date}
                          // onChange={onChangeHandler}
                        />
                        <div className="messages"></div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="d-flex justify-content-between">
                    <div className="login-label">Jenis Kelamin</div>
                    <div className="d-flex justify-content-end">
                      <Form.Check
                        inline
                        id="laki"
                        value="laki"
                        type="radio"
                        name="gender"
                        label="Laki-Laki"
                        onChange={onChangeHandlerGender}
                      />
                      <Form.Check
                        inline
                        name="gender"
                        id="perempuan"
                        value="perempuan"
                        type="radio"
                        label="Perempuan"
                        onChange={onChangeHandlerGender}
                      />
                    </div>
                    <div className="messages"></div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="cart_label">Negara</Form.Label>
                    <div className="position-absolute p-3 d-flex">
                      <FaUserAlt />
                    </div>
                    <Form.Control
                      className="register-select"
                      required
                      as="select"
                      type="select"
                      name="negara"
                      id="negara"
                      value={data.jurusan}
                      onChange={handleChange}
                    >
                      <option hidden value key="blankChoice">
                        Pilih Negara
                      </option>
                      <option value="dasar">SD</option>
                      <option value="menengahpertama">SMP</option>
                      <option value="menengahatas">SMA/K</option>
                      <option value="diploma">Diploma</option>
                      <option value="sarjana">Sarjana</option>
                    </Form.Control>
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className="cart_label">Provinsi</Form.Label>
                        <Form.Control
                          className="register-select"
                          required
                          as="select"
                          type="select"
                          name="province"
                          id="province"
                          value={data.province}
                          onChange={handleChange}
                          className="border"
                        >
                          <option hidden value key="blankChoice">
                            Pilih Provinsi...
                          </option>
                          <option>Indonesia</option>
                          {/* {provinces.map((item, index) => {
                            return (
                              <option
                                value={`${item.province_id}_${item.province}`}
                                key={index}
                              >
                                {item.province}
                              </option>
                            );
                          })} */}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className="cart_label">
                          Kecamatan
                        </Form.Label>
                        <Form.Control
                          className="register-select"
                          required
                          as="select"
                          type="select"
                          name="subdistrict"
                          id="subdistrict"
                          value={data.subdistrict}
                          onChange={handleChange}
                          className="border"
                        >
                          <option hidden value key="blankChoice">
                            Pilih Kecamatan...
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-between">
                    <Button
                      className="register-prev__button"
                      onClick={() => handleNext(0)}
                    >
                      Sebelumnya
                    </Button>
                    <Button
                      onClick={() => handleNext(2)}
                      className="register-next__button"
                    >
                      Selanjutnya
                      <FaArrowRight className="arrow-register" />
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className="register-step__first-title">Register</div>
                  {/* <div className="register-step__first-subtitle">
              Bantu kami untuk dapat berkembang lebih baik !
            </div> */}
                  <Form.Group>
                    <div className="login-label">Username</div>
                    <div className="position-absolute p-3 d-flex">
                      <FaUserAlt />
                    </div>
                    <Form.Control
                      className="mb-3 input100"
                      type="username"
                      placeholder="Masukkan Username Anda"
                      id="username"
                      name="username"
                      value={data.username}
                      onChange={onChangeHandler}
                    />
                    <div className="messages"></div>
                  </Form.Group>
                  <Form.Group>
                    <div className="login-label">Email</div>
                    <div className="position-absolute p-3 d-flex">
                      <FaEnvelope />
                    </div>
                    <Form.Control
                      className="mb-3 input100"
                      type="email"
                      placeholder="Masukkan Email Anda"
                      id="email"
                      name="email"
                      value={data.email}
                      onChange={onChangeHandler}
                    />
                    <div className="messages"></div>
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group>
                        <div className="login-label">Kata Sandi</div>
                        <div className="position-absolute p-3 d-flex">
                          <FaLock />
                        </div>
                        <Form.Control
                          className="mb-3 input100"
                          type="password"
                          placeholder="Masukkan Kata Sandi Anda"
                          id="password"
                          name="password"
                          value={data.password}
                          onChange={onChangeHandler}
                        />
                        <div className="messages"></div>
                      </Form.Group>{" "}
                    </Col>
                    <Col>
                      <Form.Group>
                        <div className="login-label">Konfirmasi Sandi</div>
                        <div className="position-absolute p-3">
                          <FaLock />
                        </div>
                        <Form.Control
                          className="mb-3 input100"
                          type="password"
                          placeholder="Konfirmasi Kata Sandi"
                          id="repassword"
                          name="confirm_password"
                          value={data.repassword}
                          onChange={onChangeHandler}
                        />
                        <div className="messages"></div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Check
                    className="mb-5"
                    type="checkbox"
                    label="Agree the terms and condition"
                    id="terms"
                    value={data.terms}
                    onChange={onChangeHandler}
                  />
                  <Button type="submit" className="login-button w-100">
                    {" "}
                    <div className="login-text"> Daftar</div>
                  </Button>
                </>
              )}
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Register;
