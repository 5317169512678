import React, { useState } from "react";
import { Button, Row, Col, Container, Form, Card } from "react-bootstrap";
import "../../assets/css/Account/index.css";
import { SkValidate } from "../../components/common/SkValidate";

import { FaLock } from "react-icons/fa";
function NewPassword() {
  const [data, setData] = useState({
    password: "",
    repassword: "",
  });
  function onSubmitHandler(e) {
    e.preventDefault();
    const form = e.target;

    const isValid = SkValidate(form, constraints);
    if (isValid) {
      // dispatch(getLoginData(body));
      // console.log(body);
    }
  }
  const onChangeHandler = (e) => {
    const newData = { ...data };
    newData[e.target.id] =
      e.target.id === "price" ? parseInt(e.target.value) : e.target.value;
    setData(newData);
  };
  const constraints = {
    password: {
      presence: true,
      length: {
        minimum: 6,
        maximum: 20,
      },
    },
    confirm_password: {
      presence: true,
      length: {
        minimum: 6,
        maximum: 20,
      },
      equality: "password",
    },
  };
  return (
    <Container className="height-full">
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={10} sm={10} md={5} lg={5}>
          <div className="text-center">
            <div className="login-title">Password Baru</div>
          </div>{" "}
          <Card className="p-5">
            <Form onSubmit={onSubmitHandler}>
              <Form.Group className="mb-4">
                <div className="login-label">Atur Kata Sandi Baru</div>
                <div className="position-absolute p-3 d-flex">
                  <FaLock />
                </div>
                <Form.Control
                  className="input100"
                  type="password"
                  placeholder="Masukkan Kata Sandi Anda"
                  id="password"
                  name="password"
                  value={data.password}
                  onChange={onChangeHandler}
                />
                <div className="messages"></div>
              </Form.Group>
              <Form.Group className="mb-5">
                <div className="login-label">Konfirmasi Kata Sandi</div>
                <div className="position-absolute p-3">
                  <FaLock />
                </div>
                <Form.Control
                  className="mb-3 input100"
                  type="password"
                  placeholder="Konfirmasi Kata Sandi"
                  id="repassword"
                  name="confirm_password"
                  value={data.repassword}
                  onChange={onChangeHandler}
                />
                <div className="messages"></div>
              </Form.Group>
              <Button type="submit" className="w-100 login-button">
                <div className="login-text">Simpan Password Baru</div>
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default NewPassword;
