import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { Treebeard } from "react-treebeard";
const datum = {
  name: "Hukum",
  toggled: false,
  children: [
    {
      name: "poin1",
      children: [{ name: "poin1.1" }, { name: "poin1.2" }],
    },
    {
      name: "poin2",
      children: [
        {
          name: "poin2 cabang",
          children: [{ name: "poin2.1 cabang" }, { name: "poin2.2 cabang" }],
        },
      ],
    },
  ],
};
function LegalFieldTable() {
  const [cursor, setCursor] = useState(null);
  const [data, setData] = useState(datum);

  const onToggle = (node, toggled) => {
    if (cursor) {
      cursor.active = false;
    }
    node.active = true;
    if (node.children) {
      node.toggled = toggled;
    }
    setCursor(node);
    setData(Object.assign({}, data));
  };

  return (
    <Table>
      <thead>
        <tr className="table-wrapper__head">
          <th className="table-wrapper__content-detail border-radius__left pl-4 w-50">
            Bidang Usaha
          </th>
          <th className="table-wrapper__content-detail ">Jumlah Peraturan</th>
          <th className="table-wrapper__content-detail border-radius__right pr-4">
            Terakhir Diupdate
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="content-info__wrapper">
            <Treebeard
              data={data}
              onToggle={onToggle}
              style={{
                tree: {
                  base: { backgroundColor: "transparent" },
                  node: {
                    activeLink: { color: "brown" },
                  },
                },
              }}
            />
          </td>
          <td className="content-info__wrapper">
            <div className="d-flex">
              <div className="id d-flex mx-1">
                <div className="box-id px-1">ID</div>
                <p className="mx-1">32</p>
              </div>
              <div className="en d-flex mx-1">
                <div className="box-en  px-1">EN</div>

                <p className="mx-1">322</p>
              </div>
            </div>
          </td>

          <td className="content-info__wrapper">21 Desember 2020</td>
        </tr>
      </tbody>
    </Table>
  );
}
export default LegalFieldTable;
