import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Pricelist from "../../components/Pricelist";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";

function Membership() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container className="dashboard_page__container">
      <div className="dashboard__title_wrapper mb-3">
        <h2 className="dashboard__section_title h2">Membership</h2>
      </div>
      <Row>
        {[...Array(3)].map((value, index) => {
          return (
            <Col key={index}>
              <Pricelist value={index} />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

export default Membership;
