import React, { useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FaClock, FaBookOpen, FaCheck } from "react-icons/fa";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/LearningPath/learningpathsingle.css";
import CourseCard from "../../components/CourseCard";

function LearningPathSingle() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container>
      <Row className="my-4">
        <Col md={12}>
          <Card className="card_wrapper">
            <div className="card_title__wrap">
              <h1 className="h1 card_title__title_main mb-4">Perpajakan</h1>
              <div className="card_title__description mb-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Praesent eleifend a nisl a venenatis. Nullam libero eros, porta
                at mauris sed, condimentum tristique dui. Sed sollicitudin
                tempus turpis id tincidunt. Vestibulum ante ipsum primis in
                faucibus orci luctus et ultrices posuere cubilia curae; Vivamus
                mattis pulvinar ultricies.
              </div>
              <div className="d-flex align-items-center">
                <div className="total_course__info ">
                  <FaBookOpen className="mr-2 fill-white " /> 32 Pelajaran
                </div>
                <div className="total_duration_course">
                  <FaClock className="mr-2 fill-white " />
                  02j 35m
                </div>
                <Button className="px-5 py-2 card_button__started">
                  MULAI
                </Button>
              </div>
            </div>
            <div className="card_image"></div>
          </Card>
        </Col>
        <Col md={8}>
          <div className="assesment_skill__wrapper">
            <h2 className="h2 assesment_skill__pengantar">Pengantar</h2>
            <div className="assesment_skill__content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              scelerisque, sem tempus sagittis suscipit, ex nisl interdum massa,
              vitae ornare diam nisl a eros. Pellentesque habitant morbi
              tristique senectus et netus et malesuada fames ac turpis egestas.
              Donec ut interdum augue. Phasellus convallis tortor eu dictum
              gravida. Vivamus aliquam lacus sit amet dictum iaculis.
            </div>
          </div>
        </Col>
        <Col md={4}>
          <Card className="card_padding">
            <h3 className="h3 assesment_skill__pengantar">
              Apa Saja yang Dipelajari
            </h3>
            <div className="assesment_skill__name price_card__check">
              <FaCheck className="mr-2 " />
              Gratis untuk member
            </div>
            <div className="assesment_skill__name price_card__check">
              <FaCheck className="mr-2 " />
              Gratis untuk member
            </div>
            <div className="assesment_skill__name price_card__check">
              <FaCheck className="mr-2 " />
              Gratis untuk member
            </div>
            <div className="assesment_skill__name price_card__check">
              <FaCheck className="mr-2 " />
              Gratis untuk member
            </div>
          </Card>
        </Col>
        <Col md={6}>
          <div className="card_course_wrapper">
            <h2 className="h2 card_course__title_list">Skill Assesment</h2>
            <div className="card_course__subtitle">Ukur Skill Kamu!</div>
          </div>
        </Col>
        <Col md={6} className="d-flex align-items-center justify-content-end">
          <div className="d-flex">
            {[...Array(4)].map((item, i) => {
              return (
                <div key={i} className="skill_assesment mr-2">
                  <div className="skill_assesment__number">{i + 1}</div>
                </div>
              );
            })}
          </div>
        </Col>
        <Col md={12}>
          <div className="card_course_wrapper">
            <h2 className="h2 card_course__title_list">Apa itu Pajak?</h2>
            <div className="card_course__subtitle">8 Pelatihan </div>
          </div>
        </Col>
        {[...Array(4)].map((item, i) => {
          return <CourseCard key={i} />;
        })}
      </Row>
    </Container>
  );
}

export default LearningPathSingle;
