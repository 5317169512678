import React, { useEffect, useState } from "react";
import { Row, Col, Container, Tabs, Tab } from "react-bootstrap";
import { FaList } from "react-icons/fa";
import { RiLayoutGridFill } from "react-icons/ri";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import CertificateCard from "../../components/CertificateCard";
import { useSelector } from "react-redux";
import "../../assets/css/Personal/myCertificate.css";

function MyCert() {
  const [viewCert, setViewCert] = useState("grid");
  const [trigger, setTrigger] = useState(true);
  const [myCert, setMyCert] = useState({
    all: [],
    participant: [],
    competent: [],
    profession: [],
  });
  const history = useHistory();
  const users = useSelector((state) => state.users);

  useEffect(() => {
    const cert = users.usersDataId.my_certificates;
    const myCertNew = { ...myCert };
    if (cert && trigger) {
      for (let i = 0; i < cert.length; i++) {
        if (cert[i].status === "participant") {
          myCertNew.participant.push(cert[i]);
        }
        if (cert[i].status === "competent") {
          myCertNew.competent.push(cert[i]);
        }
        if (cert[i].status === "profession") {
          myCertNew.profession.push(cert[i]);
        }
        myCertNew.all.push(cert[i]);
      }
      setTrigger(false);
      setMyCert(myCertNew);
    }
  }, [users.usersDataId]);

  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  const handleChangeView = (value) => {
    setViewCert(value);
  };
  // console.log(viewCert);
  return (
    <Container className="dashboard_page__container">
      <div className="certification_wrapper">
        <div>
          <h2 className="certification__section_title h2">Sertifikat Saya</h2>
          <div className="certification__subtitle">
            13 Sertifikat di Temukan
          </div>
        </div>
        <div>
          {/* <Form.Control
            size="lg"
            as="select"
            aria-label="Default select example"
          >
            <option>Open this select menu</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Control> */}
          <FaList className="mr-3" onClick={() => handleChangeView("list")} />
          <RiLayoutGridFill onClick={() => handleChangeView("grid")} />
        </div>
      </div>
      <Tabs
        defaultActiveKey="all"
        transition={false}
        id="noanim-tab-example"
        className=""
      >
        <Tab tabClassName="text-blacks" eventKey="all" title="Semua">
          <Row className="mt-4">
            {viewCert === "list"
              ? myCert.all.map((value, index) => {
                  return (
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      key={index}
                      className="course-container"
                    >
                      <CertificateCard viewlist={viewCert} data={value} />
                    </Col>
                  );
                })
              : myCert.all.map((value, index) => {
                  return (
                    <CertificateCard
                      viewlist={viewCert}
                      data={value}
                      key={index}
                    />
                  );
                })}
          </Row>
        </Tab>

        <Tab tabClassName="" eventKey="partisipasi" title="Partisipasi">
          <Row className="mt-4">
            {viewCert === "list"
              ? [...Array(2)].map((value, index) => {
                  return (
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      key={index}
                      className="course-container"
                    >
                      <CertificateCard viewlist={viewCert} />
                    </Col>
                  );
                })
              : [...Array(2)].map((value, index) => {
                  return <CertificateCard viewlist={viewCert} key={index} />;
                })}
          </Row>
        </Tab>
        <Tab
          tabClassName="text-blacks"
          eventKey="kompetensi"
          title="Kompetensi"
        >
          <Row className="mt-4">
            {viewCert === "list"
              ? [...Array(1)].map((value, index) => {
                  return (
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      key={index}
                      className="course-container"
                    >
                      <CertificateCard viewlist={viewCert} />
                    </Col>
                  );
                })
              : [...Array(1)].map((value, index) => {
                  return <CertificateCard viewlist={viewCert} key={index} />;
                })}
          </Row>
        </Tab>
        <Tab tabClassName="text-blacks" eventKey="profesi" title="Profesi">
          <Row className="mt-4">
            {viewCert === "list"
              ? [...Array(1)].map((value, index) => {
                  return (
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      key={index}
                      className="course-container"
                    >
                      <CertificateCard viewlist={viewCert} />
                    </Col>
                  );
                })
              : [...Array(1)].map((value, index) => {
                  return <CertificateCard viewlist={viewCert} key={index} />;
                })}
          </Row>
        </Tab>
      </Tabs>
    </Container>
  );
}

export default MyCert;
