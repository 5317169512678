import {
  LEARNING_PATHS_DATA,
  LEARNING_PATHS_DATA_SUCCESS,
  LEARNING_PATHS_DATA_FAIL,
  LEARNING_PATHS_DATA_ID_SUCCESS,
} from "../config/LearningPathsConfig";

const intialState = {
  learningPathsData: [],
  learningPathsDataId: [],
  isLoading: false,
};

export const LearningPathsReducers = function (state = intialState, action) {
  switch (action.type) {
    case LEARNING_PATHS_DATA:
      return { ...state, isLoading: true };
    case LEARNING_PATHS_DATA_SUCCESS:
      return { ...state, isLoading: false, learningPathsData: action.value };
    case LEARNING_PATHS_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, learningPathsDataId: action.value };
    case LEARNING_PATHS_DATA_FAIL:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
