import React, { useState, useEffect } from "react";
import { Container, Button, Modal, Carousel } from "react-bootstrap";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Tutorial/index.css";
function TutorialModal() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <Container className="dashboard_page__container">
      <Button onClick={handleShow}>Show Tutorial Modal</Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="p-4">
          <Carousel className="d-flex py-3 min-height text-center">
            <Carousel.Item id="1">
              <h4 className="tutorial-modal">Selamat Datang di Satukelas.com</h4>
              <span>Nikmati belajar lebih mudah dengan satukelas.com</span>
            </Carousel.Item>
            <Carousel.Item id="2">
              <h4 className="tutorial-modal">Step 1</h4>
              <span>Lorem ipsum dolor sit amet</span>
            </Carousel.Item>
            <Carousel.Item id="3">
              <h4 className="tutorial-modal">Step 2</h4>
              <span>Lorem ipsum dolor sit amet</span>
            </Carousel.Item>
            <Carousel.Item id="4">
              <h4 className="tutorial-modal">Step 3</h4>
              <span>Lorem ipsum dolor sit amet</span>
            </Carousel.Item>
            <Carousel.Item id="5">
              <h4 className="tutorial-modal">Mulai Belajar Sekarang</h4>
              <span>Lorem ipsum dolor sit amet</span>
            </Carousel.Item>
          </Carousel>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default TutorialModal;

// var slideIndex = 1;
// showSlides(slideIndex);
// function plusSlides(n) {
//   showSlides((slideIndex += n));
// }

// function currentSlide(n) {
//   showSlides((slideIndex = n));
// }
// function showSlides(n) {
//   var i;
//   var slides = document.getElementsByClassName("mySlides");
//   var dots = document.getElementsByClassName("dot");
//   if (n > slides.length) {
//     slideIndex = 1;
//   }
//   if (n < 1) {
//     slideIndex = slides.length;
//   }
//   for (i = 0; i < slides.length; i++) {
//     slides[i].style.display = "none";
//   }
//   for (i = 0; i < dots.length; i++) {
//     dots[i].className = dots[i].className.replace(" active", "");
//   }
//   slides[slideIndex - 1].style.display = "block";
//   dots[slideIndex - 1].className += " active";
// }
