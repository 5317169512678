import React from "react";
import { Row, Col, ProgressBar } from "react-bootstrap";
import "../assets/css/Courses/ProgressCourse.css";

function ProgressCourse({ now }) {
  return (
    <>
      {/* <div className="course_front__kurikulum_title">Progress Anda</div> */}
      {/* <Card className="p-4"> */}
      <Row className="align-items-center g-0 mb-3">
        <Col className="padding-progress">
          <ProgressBar now={now} />
        </Col>{" "}
        <Col md="auto" lg="auto" className="padding-progress">
          <div className="percentage-number">{parseInt(now)}%</div>
        </Col>
      </Row>
      {/* </Card> */}
    </>
  );
}

export default ProgressCourse;
