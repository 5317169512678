import { call, put, takeEvery } from "redux-saga/effects";
import { PromiseService } from "../../services/PromiseService";
import {
  TOPIC_DATA,
  TOPIC_DATA_PUT,
  TOPIC_DATA_POST,
  TOPIC_DATA_DELETE,
  TOPIC_DATA_LINK,
} from "../config/TopicConfig";
import {
  putTopicDataSuccess,
  postTopicDataSuccess,
  deleteTopicDataSuccess,
  getTopicDataSuccess,
  callTopicDataFail,
  callTopicDataFailPut,
  getTopicDataIdSuccess,
} from "../actions/TopicActions";

function* workerTopicDataPost(params) {
  const body = params.value;
  try {
    const response = yield call(PromiseService.postData, TOPIC_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      yield put(postTopicDataSuccess(response.data));
    } else {
      yield put(callTopicDataFail(response));
    }
  } catch (error) {
    yield put(callTopicDataFail(error));
  }
}
function* workerTopicDataDelete(params) {
  const id = params.value;
  try {
    const response = yield call(
      PromiseService.deleteData,
      `${TOPIC_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      yield put(deleteTopicDataSuccess(response.data));
    } else {
      yield put(callTopicDataFail(response));
    }
  } catch (error) {
    yield put(callTopicDataFail(error));
  }
}

function* workerTopicDataPut(params) {
  const body = params.value.body;
  const id = params.value.id;
  try {
    const response = yield call(
      PromiseService.putData,
      `${TOPIC_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      yield put(putTopicDataSuccess(response.data));
    } else {
      yield put(callTopicDataFailPut(response));
    }
  } catch (error) {
    yield put(callTopicDataFailPut(error));
  }
}

function* workerTopicData(params) {
  if (params.value) {
    try {
      const response = yield call(
        PromiseService.getData,
        `${TOPIC_DATA_LINK}/${params.value}`
      );
      if (response.status === 200 && response.data) {
        yield put(getTopicDataIdSuccess(response.data));
      } else {
        yield put(callTopicDataFail(response));
      }
    } catch (error) {
      yield put(callTopicDataFail(error));
    }
  } else {
    try {
      const response = yield call(PromiseService.getData, TOPIC_DATA_LINK);
      if (response.status === 200 && response.data) {
        yield put(getTopicDataSuccess(response.data));
      } else {
        yield put(callTopicDataFail(response));
      }
    } catch (error) {
      yield put(callTopicDataFail(error));
    }
  }
}

export const watcherTopic = [
  takeEvery(TOPIC_DATA_PUT, workerTopicDataPut),
  takeEvery(TOPIC_DATA_POST, workerTopicDataPost),
  takeEvery(TOPIC_DATA_DELETE, workerTopicDataDelete),
  takeEvery(TOPIC_DATA, workerTopicData),
];
