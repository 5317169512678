import React from "react";
import { ListGroup, Card } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import "../assets/css/Courses/CardBenefit.css";

function CardBenefit({ objective }) {
  return (
    <>
      <h2 className="course_front__kurikulum_title h2">Tujuan Pembelajaran</h2>
      <Card className="course_front__benefit_container">
        <ListGroup>
          {objective &&
            objective.map((listing, index) => (
              <div key={index} className="d-flex align-items-start course_front__benefit_item__wrapper">
                <FaCheckCircle className="mr-2 course_front__benefit_icon" />
                <div className="course_front__benefit_item">{listing}</div>
              </div>
            ))}
        </ListGroup>
      </Card>
    </>
  );
}

export default CardBenefit;
