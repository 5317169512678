import React, { useEffect } from "react";
import { Col, Table, Card, Button } from "react-bootstrap";

import { GoKebabVertical } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { getCoursesData } from "../redux/actions/CoursesActions";
import "../assets/css/Quiz/quizresult.css";
import { useParams } from "react-router-dom";
import PrerequisitesCourse from "./PrerequisitesCourse";
import { useLocation } from "react-router-dom";
import CertificateCard from "./CertificateCard";

function QuizResult({
  quizScore,
  displayResult,
  resetFlags,
  getCoursesDataId,
}) {
  let total = 0;
  const { course_id } = useParams();
  const location = useLocation();
  useEffect(() => {
    //to track url changes, so we can re dispatch the redux data if the course_id change
    getCoursesDataId();
  }, [location]);
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courses);
  const course = courses.coursesDataId;
  return (
    <>
      <Col
        xs={11}
        className="quiz-quest-main-col d-flex flex-column justify-content-start align-items-start"
      >
        {displayResult ? (
          <>
            <Button
              disabled
              variant={`${quizScore.result === "Fail" ? "danger" : "success"}`}
              style={{ width: "100%" }}
            >
              {quizScore.result === "Fail" ? "Gagal" : "Lulus"}
            </Button>
            <Card.Title className="quiz-details-title">
              Hasil Percobaan
            </Card.Title>
            <Card.Text className="quiz-details-subtitle">
              Nilai: <b>{quizScore.earned_mark}</b>
            </Card.Text>
            <div
              className="quiz-quest-wrapper-scrollbar"
              style={{ width: "100%" }}
            >
              <Table responsive striped hover style={{ cursor: "default" }}>
                <thead>
                  <tr>
                    {/*<th>Tipe</th>*/}
                    <th>No</th>
                    <th>Pertanyaan</th>
                    <th>Jawaban yang Diberikan</th>
                    <th>Status</th>
                    <th>Related Video</th>
                  </tr>
                </thead>
                <tbody>
                  {quizScore.answers.map((value, index) => {
                    return (
                      <tr key={index}>
                        {/*<td>
                          <div className="text-center ">
                            <FaCheck className="fill-success" />
                          </div>
                        </td>*/}
                        <td>{value.number}</td>
                        <td>{value.question}</td>
                        <td>{value.answer}</td>
                        <td>
                          <Button
                            disabled
                            variant={`${
                              value.value === "Correct" ? "success" : "danger"
                            }`}
                            style={{ width: "100%" }}
                          >
                            {value.value === "Correct" ? "Benar" : "Salah"}
                          </Button>
                        </td>
                        <td>
                          <div>
                            <GoKebabVertical />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ height: "100%", width: "100%" }}
            >
              <Card.Title className="quiz-details-title">
                Ujian Selesai
              </Card.Title>
              <Button onClick={resetFlags}>Kembali</Button>
            </div>
          </>
        ) : (
          ""
        )}
      </Col>
      <Col>
        <PrerequisitesCourse prereq={course.prerequisites} />
      </Col>
      {/* using if user lulus from certificate */}

      {/* <Col lg={12}>
        <CertificateCard />
      </Col> */}
    </>
  );
}

export default QuizResult;
