import React, { useEffect, useState } from "react";
import { Container, Card } from "react-bootstrap";
import { AuthIsLogin } from "../../helper/auth";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "../../assets/css/Personal/Dashboard.css";
import { useSelector } from "react-redux";
import { FaBookOpen, FaClock, FaRegCopy } from "react-icons/fa";

function PaymentStatus() {
  const [order, setOrder] = useState([]);
  const [payment, setPayment] = useState([]);
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  const users = useSelector((state) => state.users);
  useEffect(() => {
    const userData = users.usersDataId.my_orders;
    setOrder(userData);
  }, [users.usersDataId]);
  useEffect(() => {
    const paymentData = users.usersDataId.payments;
    setPayment(paymentData);
  }, [users.usersDataId]);
  return (
    <Container className="dashboard_page__container">
      <div className="dashboard__title_wrapper d-flex justify-content-between align-items-center">
        <h2 className="h2 dashboard__section_title">Riwayat Transaksi</h2>
      </div>
      {payment.map((item, index) => (
        <Card className="topup-item__container mb-3">
          <div className="topup-item__wrapper">
            <div className="topup-item__inside-container">
              <div className="topup-item__info">
                <div className="topup-item__info-top">
                  No. Virtual Akun <span>{item?.status}</span>
                </div>
                <div className="topup-item__info-bottom">
                  <div className="topup-item__virtual-acc mr-2">
                    7382739209301
                  </div>
                  <div className="topup-item__copy-virtual__acc">
                    <FaRegCopy />
                  </div>
                </div>
              </div>
            </div>
            <h3 className="h3 topup-item__price mb-0 text-right">
              <div className="topup-item__info-top text-right">
                Total {item?.description}
              </div>
              Rp. {item?.amount}
            </h3>
          </div>
        </Card>
      ))}

      {order?.map((item, index) => (
        <div key={index} className="dashboard__main_content">
          <div className="payment_bundle__wrapper">
            <div className="d-flex justify-content-between">
              <div className=" mr-3">
                <div className="dashboard__section_number ">
                  No. {item?.order_id}
                </div>
                <div className="dashboard__section_date">
                  Tanggal Order:{" "}
                  {moment(item?.created_at).utc().format("DD-MM-YYYY HH:mm:ss")}
                </div>
              </div>
              {/* <div className="payment_status">
                <FaClock style={{ marginRight: "8px" }} /> {item?.status}
              </div> */}
            </div>
            <div className="payment_item">
              <div className="thumbnail_wrapper"></div>
              <div className="payment_info">
                <div className="payment_info__title">
                  {" "}
                  Tutorial Perpajakan{" "}
                  <span className="card_content__category">Perpajakan</span>
                </div>
                <div className="payment_info__others">
                  <div>
                    <FaBookOpen style={{ marginRight: "8px" }} />
                    12 Lesson
                  </div>
                  <div>
                    <FaClock style={{ marginRight: "8px" }} />
                    03j 45m 03d
                  </div>
                </div>
                <div className="payment_info__price"> 300.000,-</div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Container>
  );
}

export default PaymentStatus;
