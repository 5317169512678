import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Container, Modal } from "react-bootstrap";
import "../assets/css/Personal/MyCalendar.css";
import { useSelector } from "react-redux";

const localizer = momentLocalizer(moment);

function TimelineCalendar() {
  const [events, setEvents] = useState([]);
  const users = useSelector((state) => state.users);
  useEffect(() => {
    const webinar = users.usersDataId.my_webinars;
    setEvents(webinar);
  }, [users.usersDataId]);

  const [show, setShow] = useState(false);
  const [showEvents, setShowEvents] = useState([]);
  const [color, setColor] = useState("red");
  const handleSelect = ({ start, end }) => {
    const title = window.prompt("New Event name");
    const colors = window.prompt("Color");
    setColor(colors);
    if (title) {
      setEvents((events) => [...events, { start, end, title }]);
    }
  };
  const handleShowInfo = (e) => {
    setShow(!show);
    setShowEvents(e);
  };
  return (
    <Container className="dashboard_page__container">
      <h2 className=" h2 dashboard__section_title mb-3">Kalendar Saya</h2>
      <Calendar
        popup
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={events}
        style={{ height: "100vh" }}
        onSelectEvent={handleShowInfo}
        // onSelectSlot={handleSelect}
        // eventPropGetter={(event, start, end, isSelected) => {
        //   let newStyle = {
        //     backgroundColor: `${color}`,
        //   };
        //   if (event.isMine) {
        //     newStyle.backgroundColor = "lightgreen";
        //   }
        //   return {
        //     className: "",
        //     style: newStyle,
        //   };
        // }}
      />
      <Modal centered show={show} onHide={() => setShow(!show)}>
        <div className="event_name p-4">
          <div className="fw-700">Nama Event: {showEvents.title}</div>
          <div className="fw-700">
            Acara Dimulai:{" "}
            {moment(showEvents.start).utc().format("DD-MM-YYYY HH:mm:ss")}
          </div>
          <div className="fw-700">
            Acara Selesai:{" "}
            {moment(showEvents.end).utc().format("DD-MM-YYYY HH:mm:ss")}
          </div>
        </div>
      </Modal>
    </Container>
  );
}
export default TimelineCalendar;

const listEvent = [
  {
    start: "",
    end: "",
    title: "",
  },
];
