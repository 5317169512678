import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Form,
  Tabs,
  Tab,
  Modal,
  FormControl,
} from "react-bootstrap";
import { AuthIsLogin, AuthData } from "../../helper/auth";
import { FormatCurrency, CheckIfEmpty } from "../../helper/functions";
import { useHistory } from "react-router-dom";
import "../../assets/css/Transaction/cardStyling.css";
import { FaTrash, FaClock, FaBookOpen, FaPlus, FaTimes } from "react-icons/fa";
// import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import {
//   getCoursesData,
//   postEnrollCourse,
// } from "../../redux/actions/CoursesActions";
import {
  getProvincesData,
  getCitiesData,
  getSubdistrictsData,
  getCouriersData,
  getPricesData,
  postOrdersData,
} from "../../redux/actions/OrdersActions";
import Topup from "../../components/Topup";
import { postPurchasesData } from "../../redux/actions/PurchasesActions";
import "./../../assets/css/Courses/CourseFront.css";
import { putUsersData, getUsersData } from "../../redux/actions/UsersActions";
import { KeepSpesificKey } from "../../helper/functions";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function Cart() {
  const [add, setAdd] = useState(false);
  const [userStatus, setUserStatus] = useState(0);
  const [userItem, setUserItem] = useState([]);
  const [destination, setDestination] = useState({
    province: "",
    city: "",
    subdistrict: "",
    address: "",
    courier: "",
    price: "",
  });
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [subdistricts, setSubdistricts] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [pricesCert, setPricesCert] = useState();
  const [topup, setTopup] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const history = useHistory();
  const users = useSelector((state) => state.users);
  const orders = useSelector((state) => state.orders);
  const purchases = useSelector((state) => state.purchases);
  const dispatch = useDispatch();
  let prices = 0;
  let countCours = 0;
  let countCert = 0;
  let courseIds = [];

  console.log(destination);
  useEffect(() => {
    AuthIsLogin(history);
    dispatch(getProvincesData());
    dispatch(getCouriersData());
  }, []);

  //get city
  useEffect(() => {
    if (destination.province) {
      const newDestination = { ...destination };
      newDestination.subdistrict = "";
      newDestination.city = "";
      setDestination(newDestination);
      dispatch(getCitiesData(destination.province.split("_")[0]));
    }
  }, [destination.province]);

  //get subdistrict
  useEffect(() => {
    if (destination.city) {
      const newDestination = { ...destination };
      newDestination.subdistrict = "";
      setDestination(newDestination);
      dispatch(getSubdistrictsData(destination.city.split("_")[0]));
    }
  }, [destination.city]);

  //get price
  useEffect(() => {
    const newDestination = { ...destination };
    newDestination.price = "";
    setDestination(newDestination);
    if (destination.subdistrict && destination.courier) {
      dispatch(getPricesData(destination));
    }
  }, [destination.subdistrict, destination.courier]);

  const handleAdd = () => {
    setAdd(!add);
  };

  useEffect(() => {
    if (orders.ordersProvinces) setProvinces(orders.ordersProvinces);
    if (orders.ordersCities) setCities(orders.ordersCities);
    if (orders.ordersSubdistricts) setSubdistricts(orders.ordersSubdistricts);
    if (orders.ordersCouriers) setCouriers(orders.ordersCouriers);
    if (orders.ordersPrice) setPricesCert(orders.ordersPrice);

    if (orders.postOrdersData === "fail" && trigger) {
      setTrigger(false);
      displayPopup(true, "Terjadi masalah saat membayar barang", false);
    }
    if (orders.postOrdersData === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil membayar barang", true, "Oke");
      removeAllTypeFromCart("course");
    }
  }, [orders]);

  useEffect(() => {
    const balance = users.usersDataId.balance;
    const cart = users.usersDataId.cart;
    console.log(balance);
    if (balance || balance === 0) setUserStatus(balance);
    if (cart) setUserItem(cart);
    if (users.usersDataPut === "fail" && trigger) {
      setTrigger(false);
      displayPopup(
        true,
        "Terjadi masalah saat menghapus barang di Keranjang",
        false
      );
    }
    if (users.usersDataPut === "success" && trigger) {
      setTrigger(false);
      dispatch(getUsersData(AuthData().userId));
    }
  }, [users]);

  useEffect(() => {
    if (purchases.purchasesDataPost === "fail" && trigger) {
      setTrigger(false);
      displayPopup(true, "Terjadi masalah saat membayar barang", false);
    }
    if (purchases.purchasesDataPost === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil membayar barang", true, "Oke");
      removeAllTypeFromCart("certificate");
    }
  }, [purchases]);

  function purchaseHandler() {
    const payload = {
      bill_amount: prices,
      course_ids: courseIds,
      expired_at: moment().add(1, "d").format("YYYY-MM-DD HH:mm:ss"),
      user_id: AuthData().userId,
    };
    setTrigger(true);
    dispatch(postPurchasesData(payload));
  }
  function ordersHandler() {
    const payload = {
      courier: destination.courier,
      destination:
        destination.address +
        "," +
        destination.subdistrict.split("_")[1] +
        "," +
        destination.city.split("_")[1] +
        "," +
        destination.province.split("_")[1],
      fee: parseInt(destination.price),
      origin: "Jakarta",
      user_id: AuthData().userId,
    };
    console.log(payload);
    setTrigger(true);
    dispatch(postOrdersData(payload));
  }

  const handleChange = (e) => {
    const newData = { ...destination };
    newData[e.target.id] = e.target.value;
    setDestination(newData);
  };
  const topupHandler = () => {
    setTopup(!topup);
  };
  const removeItemFromCart = (id, type) => {
    let cart = KeepSpesificKey([...users.usersDataId.cart]);
    let req = {};
    const index = cart.indexOf(id);
    if (index > -1) {
      cart.splice(index, 1);
    }
    req.body = { cart: cart };
    req.id = AuthData().userId;
    setTrigger(true);
    dispatch(putUsersData(req));
  };

  const removeAllTypeFromCart = (id) => {
    let cart = users.usersDataId.cart;
    let newCart = [];
    let req = {};
    for (let i = 0; i < cart.length; i++) {
      if (cart[i].item_type === id) newCart.push(cart[i][`${id}_id`]);
    }
    req.body = { cart: newCart };
    req.id = AuthData().userId;
    setTrigger(true);
    dispatch(putUsersData(req));
  };

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {});
  }

  function getOptionData(data, field) {
    return data
      .filter((item) => item[field] !== "")
      .map((item, index) => (
        <option key={index} value={`${item[`${field}_id]`]}_${item[field]}`}>
          {item[field]}
        </option>
      ));
  }

  return (
    <Container className="dashboard_page__container">
      <h2 className="h2 dashboard__section_title mb-4">Keranjang Saya</h2>
      <Tabs
        defaultActiveKey="kursus"
        transition={false}
        id="noanim-tab-example"
        className="mb-3"
      >
        <Tab tabClassName="" eventKey="kursus" title="Keranjang Kursus">
          <Row>
            <Col md={8}>
              {userItem.map((data, index) => {
                if (data.item_type === "course") {
                  prices += parseInt(data.price);
                  countCours++;
                  courseIds.push(data.course_id);
                }
                return (
                  <div key={index}>
                    {data.item_type === "course" ? (
                      <Card className="cart_container">
                        <div className="card_wrapper_instructor">
                          <Form.Check
                            className="mb-4 mr-3"
                            type="checkbox"
                            id="terms"
                          />
                          <div className="card_title_instructor">
                            LMS Instruktur
                          </div>
                        </div>
                        <div className="card_wrapper__info">
                          <div className="card_content__wrapper">
                            <div className="card_content__checkbox">
                              <Form.Check
                                className="mb-4"
                                type="checkbox"
                                id="terms"
                              />
                            </div>
                            <div className="card_content__image"></div>
                            <div className="card_content__title">
                              <div className="card_content__title__main">
                                {data.title}
                                <span className="card_content__category">
                                  Perpajakan
                                </span>
                              </div>
                              <div className="card_content__title__sub">
                                <div className="card_content__course mr-4  text-muted">
                                  <FaBookOpen className="mr-2" />
                                  12 Pelatihan
                                </div>
                                <div className="card_content__time  text-muted">
                                  <FaClock className="mr-2" />{" "}
                                  {moment
                                    .utc(data.total_duration * 1000)
                                    .format("HH") + "j "}
                                  {moment
                                    .utc(data.total_duration * 1000)
                                    .format("mm") + "m "}
                                  {moment
                                    .utc(data.total_duration * 1000)
                                    .format("ss") === "00"
                                    ? ""
                                    : moment
                                        .utc(data.total_duration * 1000)
                                        .format("ss") + "d"}
                                </div>
                              </div>
                              <div className="card_content__title__price">
                                {FormatCurrency(data.price)}
                              </div>
                            </div>
                          </div>

                          <div className="card_content_button">
                            <Button
                              variant="danger"
                              className="card_content__button_danger"
                              onClick={() => {
                                if (data.course_id)
                                  removeItemFromCart(data.course_id);
                                else removeItemFromCart(data.certificate_id);
                              }}
                            >
                              <FaTrash className="card_button__fill__white" />
                            </Button>
                          </div>
                        </div>
                      </Card>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </Col>
            <Col md={4}>
              <Card className="cart_container">
                {/* <div className="card_promo_wrapper">
                  <div className="card_title mb-2">Promo</div>
                  <Form.Control
                    className="satukelas_input py-2"
                    type="text"
                    placeholder="Masukkan Promo"
                  />
                </div> */}

                {/* <div className="card_border"></div> */}
                <div className="card_total_wrapper mb-2">
                  <div className="card_title">Total Pembayaran</div>
                  <div className="card_subtitle mb-2">
                    {countCours} Pelatihan
                  </div>
                  <div className="card_price text-right">
                    {FormatCurrency(prices)}
                  </div>
                </div>
                {userStatus > parseInt(prices) ? (
                  <div className="card_button ">
                    <Button
                      className="w-100 py-2 button-save__address"
                      disabled={!countCours}
                      onClick={purchaseHandler}
                    >
                      Bayar
                    </Button>
                  </div>
                ) : (
                  <div className="card_button ">
                    <Button
                      onClick={topupHandler}
                      className="w-100 py-3 button-save__address"
                    >
                      Topup
                    </Button>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </Tab>
        <Tab tabClassName="" eventKey="sertifikat" title="Keranjang Sertifikat">
          <Row>
            <Col md={8}>
              {userItem.map((data, index) => {
                if (data.item_type === "certificate") {
                  countCert++;
                }
                return (
                  <div key={index}>
                    {data.item_type === "certificate" ? (
                      <Card className="cart_container">
                        <div className="card_wrapper__info">
                          <div className="card_content__wrapper">
                            <div className="card_content__checkbox">
                              <Form.Check
                                className="mb-4"
                                type="checkbox"
                                id="terms"
                              />
                            </div>
                            <div className="card_content__image"></div>
                            <div className="card_content__title">
                              <div className="card_content__title__main">
                                {data.title}
                                <span className="card_content__category">
                                  Perpajakan
                                </span>
                              </div>
                              <div className="card_content__title__sub">
                                <div className="card_content__course mr-4  text-muted">
                                  <FaBookOpen className="mr-2" />
                                  12 Pelatihan
                                </div>
                                <div className="card_content__time  text-muted">
                                  <FaClock className="mr-2" /> 03j 45m 32s
                                </div>
                              </div>
                              <div className="card_content__title__price">
                                Rp. 300.000
                              </div>
                            </div>
                          </div>

                          <div className="card_content_button">
                            <Button
                              variant="danger"
                              className="card_content__button_danger"
                              onClick={() => {
                                if (data.course_id)
                                  removeItemFromCart(data.course_id);
                                else removeItemFromCart(data.certificate_id);
                              }}
                            >
                              <FaTrash className="card_button__fill__white" />
                            </Button>
                          </div>
                        </div>
                      </Card>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </Col>
            <Col md={4}>
              <Card className="cart_container">
                {/* <div className="card_promo_wrapper">
                  <div className="card_title mb-2">Promo</div>
                  <Form.Control
                    className="satukelas_input py-2"
                    type="text"
                    placeholder="Masukkan Promo"
                  />
                </div> */}
                <div className="card_promo_wrapper">
                  <div className="d-flex justify-content-between">
                    <div className="card_title mb-2">Alamat</div>
                    {add ? (
                      <FaTimes onClick={handleAdd} />
                    ) : (
                      <FaPlus
                        onClick={() => {
                          if (countCert) handleAdd();
                        }}
                      />
                    )}
                  </div>
                  {add ? (
                    <Form>
                      <Form.Control
                        className="satukelas_input py-2 mb-3"
                        as="textarea"
                        placeholder="Masukkan Nama Perumahan/Blok"
                        id="address"
                        value={destination.address}
                        onChange={handleChange}
                      />
                      <Form.Group>
                        <Form.Label className="cart_label">Provinsi</Form.Label>
                        <Form.Control
                          required
                          as="select"
                          type="select"
                          name="province"
                          id="province"
                          value={destination.province}
                          onChange={handleChange}
                          className="border"
                          onFocus={() => console.log("focus")}
                          onBlur={() => console.log("onOutFocus")}
                        >
                          <option hidden value key="blankChoice">
                            Pilih Provinsi...
                          </option>
                          {getOptionData(provinces, "province")}
                        </Form.Control>
                      </Form.Group>
                      {destination.province && cities ? (
                        <Form.Group>
                          <Form.Label className="cart_label">Kota</Form.Label>
                          <Form.Control
                            required
                            as="select"
                            type="select"
                            name="city"
                            id="city"
                            value={destination.city}
                            onChange={handleChange}
                            className="border"
                          >
                            <option hidden value key="blankChoice">
                              Pilih Kota/Kabupaten...
                            </option>
                            {cities.map((item, index) => {
                              return (
                                <option
                                  value={`${item.city_id}_${item.type} ${item.city_name}`}
                                  key={index}
                                >
                                  {item.type} {item.city_name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      ) : null}
                      {destination.city && subdistricts ? (
                        <Form.Group>
                          <Form.Label className="cart_label">
                            Kecamatan
                          </Form.Label>
                          <Form.Control
                            required
                            as="select"
                            type="select"
                            name="subdistrict"
                            id="subdistrict"
                            value={destination.subdistrict}
                            onChange={handleChange}
                            className="border"
                          >
                            <option hidden value key="blankChoice">
                              Pilih Kecamatan...
                            </option>
                            {subdistricts.map((item, index) => {
                              return (
                                <option
                                  value={`${item.subdistrict_id}_${item.subdistrict_name}`}
                                  key={index}
                                >
                                  {item.subdistrict_name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      ) : null}
                      <Form.Group>
                        <Form.Label className="cart_label">Kurir</Form.Label>
                        <Form.Control
                          required
                          as="select"
                          type="select"
                          name="courier"
                          id="courier"
                          value={destination.courier}
                          onChange={handleChange}
                          className="border"
                        >
                          <option hidden value key="blankChoice">
                            Pilih Kurir...
                          </option>
                          {couriers.map((item, index) => {
                            return (
                              <option value={item} key={index}>
                                {item.toUpperCase()}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                      {pricesCert ? (
                        <Form.Group>
                          <Form.Label className="cart_label">
                            Metode Pengiriman
                          </Form.Label>
                          <Form.Control
                            required
                            as="select"
                            type="select"
                            name="price"
                            id="price"
                            value={destination.price}
                            onChange={handleChange}
                            className="border"
                          >
                            <option hidden value key="blankChoice">
                              Pilih Metode Pengiriman...
                            </option>
                            {pricesCert.map((item, index) => {
                              return (
                                <option value={item.cost[0].value} key={index}>
                                  {item.service}(
                                  {item.cost[0].etd
                                    ? item.cost[0].etd + " Hari"
                                    : "-"}
                                  /{FormatCurrency(item.cost[0].value)})
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      ) : (
                        ""
                      )}
                      <Button className="float-right mr-0 button-save__address">
                        Simpan
                      </Button>
                    </Form>
                  ) : (
                    ""
                  )}
                </div>
                <div className="card_border"></div>
                <div className="card_total_wrapper mb-2">
                  <div className="card_title">Total Pembayaran</div>
                  <div className="card_subtitle mb-2">
                    {countCert} Sertifikat
                  </div>
                  <div className="card_price text-right">
                    {FormatCurrency(destination.price)}
                  </div>
                </div>
                {userStatus > parseInt(destination.price) ? (
                  <div className="card_button ">
                    <Button
                      className="w-100 py-2 button-save__address"
                      onClick={ordersHandler}
                      disabled={!countCert || CheckIfEmpty(destination)}
                    >
                      Bayar
                    </Button>
                  </div>
                ) : (
                  <div className="card_button ">
                    <Button
                      onClick={topupHandler}
                      className="w-100 py-3 button-save__address"
                    >
                      Topup
                    </Button>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </Tab>
      </Tabs>
      <Modal centered show={topup} onHide={() => setTopup(false)}>
        <Topup setTopup={setTopup} />
      </Modal>
    </Container>
  );
}

export default Cart;
