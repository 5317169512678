import React, { useState, useEffect } from "react";
import { FaShoppingCart } from "react-icons/fa";
import { Button, Card, Modal } from "react-bootstrap";
import { putUsersData, getUsersData } from "../redux/actions/UsersActions";
import { AuthData } from "../helper/auth";
import { KeepSpesificKey } from "../helper/functions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ProgressCourse from "./ProgressCourse";
import "../assets/css/Courses/CardPrice.css";
const MySwal = withReactContent(Swal);

function CardPrice({
  statusUser,
  item,
  enrolled,
  enrollCourse,
  purchased,
  progress,
  continueCourse,
  anotherAttempt,
}) {
  const dispatch = useDispatch();
  const { course_id } = useParams();
  const users = useSelector((state) => state.users);
  const [show, setShow] = useState(false);
  const [trigger, setTrigger] = useState(false);
  // const handleRegistWebinar = () => {
  //   setShow(true);
  // };
  useEffect(() => {}, [users]);
  const addToCart = () => {
    let cart = KeepSpesificKey([...users.usersDataId.cart]);
    let req = {};
    if (!cart) cart = [];
    if (!cart.includes(course_id)) {
      cart.push(course_id);
      req.body = { cart: cart };
      req.id = AuthData().userId;
      setTrigger(true);
      dispatch(putUsersData(req));
    } else displayPopup(true, "Barang sudah di keranjang!", false);
  };

  useEffect(() => {
    if (users.usersDataPut === "fail" && trigger) {
      setTrigger(false);
      displayPopup(
        true,
        "Terjadi masalah saat Menambahkan ke Keranjang",
        false
      );
    }
    if (users.usersDataPut === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil Menambahkan ke Keranjang", true, "Ok");
    }
  }, [users]);

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (confirm) {
        dispatch(getUsersData(AuthData().userId));
      }
    });
  }

  return (
    <>
      <Card className="course_front__price_card__container">
        <div className="price_card__level">{item.level}</div>
        <div className="d-flex justify-content-between">
          <div className="price_card__price">{item.price}</div>
          {statusUser === "active" || purchased ? (
            ""
          ) : (
            <Button className="price_card__add_cart" onClick={addToCart}>
              <FaShoppingCart className="fill-black" />
            </Button>
          )}
        </div>
        <div className="price_card__price_sub">Gratis untuk member</div>
        {enrolled || anotherAttempt ? (
          <>
            <div className="price_card__status">Progress</div>
            <ProgressCourse now={progress} />
          </>
        ) : (
          ""
        )}
        {/* <div className="price_card__status">Status : Lorem ipsum</div> */}
        {/* <div className="price_card__status rating">
        Rating : <span className="font-weight-bold">4,3</span>{" "}
      </div> */}

        {/* <div className="price_card__check">
        <FaCheck className="mr-3 fill-white" />
        Lorem ipsum dolor sit amet, consectetur adipiscing
      </div> */}
        {/* <div className="price_card__check">
        <FaCheck className="mr-2 fill-white" />
        Gratis untuk member
      </div> */}
        {item.course_type === "Webinar" ? (
          <>
            <Button
              href={`${item.course_id}/webinars/${item.topics[0].materials[0].webinar_id}_0_0`}
              block
              className="price_card__btn_primary"
            >
              Join
            </Button>
            {/* <Button
              onClick={handleRegistWebinar}
              block
              className="price_card__btn_disabled"
            >
              Daftar
            </Button> */}
          </>
        ) : (
          item.topics && (
            <>
              {(purchased || statusUser === "active") &&
              statusUser !== "expired" ? (
                <>
                  {enrolled ? (
                    <>
                      {anotherAttempt ? (
                        <Button
                          onClick={continueCourse}
                          block
                          className="price_card__btn_primary"
                        >
                          Lihat Pelatihan
                        </Button>
                      ) : (
                        <Button
                          onClick={continueCourse}
                          block
                          className="price_card__btn_primary"
                        >
                          Lanjutkan Pelatihan
                        </Button>
                      )}
                      {/*<Button
                        block
                        className="price_card__btn_disabled"
                        disabled
                      >
                        Selesai Pelatihan
                      </Button>*/}
                    </>
                  ) : (
                    <Button
                      block
                      className="price_card__btn_primary"
                      onClick={enrollCourse}
                    >
                      Mulai Pelatihan
                    </Button>
                  )}
                </>
              ) : (
                <Button block className="price_card__btn_disabled" disabled>
                  Pelatihan Belum Dibeli
                </Button>
              )}
            </>
          )
        )}
      </Card>
      {show ? (
        <Modal show={show}>
          <Modal.Header>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button variant="primary">Save Changes</Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
}
export default CardPrice;
