import { LoginReducers } from "./reducers/LoginReducers";
import { CoursesReducers } from "./reducers/CoursesReducers";
import { LessonsReducers } from "./reducers/LessonsReducers";
import { QuizReducers } from "./reducers/QuizReducers";
import { UsersReducers } from "./reducers/UsersReducers";
import { CourseTypesReducers } from "./reducers/CourseTypesReducers";
import { LearningPathsReducers } from "./reducers/LearningPathsReducers";
import { TopicReducers } from "./reducers/TopicReducers";
import { OrdersReducers } from "./reducers/OrdersReducers";
import { WebinarsReducers } from "./reducers/WebinarsReducers";
import { AssetsReducers } from "./reducers/AssetsReducers";
import { AssignmentReducers } from "./reducers/AssignmentReducers";
import { PurchasesReducers } from "./reducers/PurchasesReducers";
import { PaymentsReducers } from "./reducers/PaymentsReducers";

export default {
  login: LoginReducers,
  courses: CoursesReducers,
  lessons: LessonsReducers,
  quizzes: QuizReducers,
  users: UsersReducers,
  course_types: CourseTypesReducers,
  learning_paths: LearningPathsReducers,
  topics: TopicReducers,
  orders: OrdersReducers,
  webinars: WebinarsReducers,
  assets: AssetsReducers,
  assignments: AssignmentReducers,
  purchases: PurchasesReducers,
  payments: PaymentsReducers,
};
