import React, { useEffect, useState } from "react";
import {  Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Gallery from "react-grid-gallery";
import { getAssetsData } from "../redux/actions/AssetsActions";
import { Capitalize } from "../helper/functions";
import MediaUploader from "./MediaUploader";
import PDFLoader from "./PDFLoader";

function Library({ libraryHandler }) {
  const [images, setImages] = useState(null);
  const [prevImage, setPrevImage] = useState(null);
  const [selectedPDF, setSelectedPDF] = useState();
  const [bucket, setBucket] = useState("assignments");
  const [type, setType] = useState("image");
  const [page, setPage] = useState("library");

  const dispatch = useDispatch();
  const assets = useSelector((state) => state.assets);
  const assetsData = assets.assetsData;

  useEffect(() => {
    const param = `?bucket=${bucket}&file_type=${type}`;
    dispatch(getAssetsData(param));
  }, [bucket, type]);

  useEffect(() => {
    if (assetsData.items) {
      const data = assetsData.items;
      let results = [];
      for (let i = 0; i < data.length; i++) {
        results.push({
          src: data[i].url,
          thumbnail: data[i].url,
          thumbnailWidth: parseInt(data[i].width),
          thumbnailHeight: parseInt(data[i].height),
          caption: data[i].bucket,
        });
      }
      setImages(results);
    }
  }, [assets]);

  function onSelectedImage(index, image) {
    var imagesNew = images.slice();
    var img = imagesNew[index];
    if (img.hasOwnProperty("isSelected")) img.isSelected = !img.isSelected;
    else img.isSelected = true;
    if (libraryHandler) {
      libraryHandler(img.src);
    }
    if (prevImage) {
      imagesNew[prevImage].isSelected = false;
    }
    setPrevImage(index);
    setImages(imagesNew);
  }

  function onSelectPDF(index, image) {
    if (prevImage === index && selectedPDF !== -1) setSelectedPDF(-1);
    else setSelectedPDF(index);
    if (libraryHandler) {
      libraryHandler(image.src);
    }
    setPrevImage(index);
  }

  function handleChangeBucket(e) {
    setBucket(e.target.value);
  }
  function handleChangeType(e) {
    setType(e.target.value);
  }

  function handlePageChange(e) {
    setPage(e.target.value);
  }

  console.log(images, "<===========================");
  return (
    <StyledWrapperMain>
      <Form>
        <Form.Group className="d-flex flex-row">
          <Form.Control
            required
            as="select"
            type="select"
            name="page"
            id="page"
            value={page}
            onChange={handlePageChange}
          >
            <option hidden value key="blankChoice">
              Pilih Halaman...
            </option>
            {["uploader", "library"].map((item, index) => {
              return (
                <option value={item} key={index}>
                  {Capitalize(item.replace(/_/g, " "))}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
        {page === "library" ? (
          <Form.Group className="d-flex flex-row">
            <Form.Control
              required
              as="select"
              type="select"
              name="bucket"
              id="bucket"
              value={bucket}
              onChange={handleChangeBucket}
            >
              <option hidden value key="blankChoice">
                Pilih Bucket...
              </option>
              {["assignments", "profile_pictures"].map((item, index) => {
                return (
                  <option value={item} key={index}>
                    {Capitalize(item.replace(/_/g, " "))}
                  </option>
                );
              })}
            </Form.Control>
            <Form.Control
              required
              as="select"
              type="select"
              name="type"
              id="type"
              value={type}
              onChange={handleChangeType}
            >
              <option hidden value key="blankChoice">
                Pilih Tipe...
              </option>
              {["image", "pdf"].map((item, index) => {
                return (
                  <option value={item} key={index}>
                    {Capitalize(item.replace(/_/g, " "))}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        ) : null}
      </Form>
      {page === "library" ? (
        <div
          style={{
            display: "block",
            minHeight: "1px",
            width: "100%",
            border: "1px solid #ddd",
            overflow: "auto",
          }}
        >
          {type === "image" ? (
            <>
              {images ? (
                <Gallery
                  images={images}
                  onSelectImage={onSelectedImage}
                  lightboxWidth={1536}
                />
              ) : null}
            </>
          ) : (
            <div className="d-flex flex-wrap justify-content-center">
              {images
                ? images.map((value, index) => {
                    return (
                      <PDFLoader
                        url={value.src}
                        title={value.caption}
                        onSelected={() => onSelectPDF(index, value)}
                        selected={selectedPDF}
                        index={index}
                      />
                    );
                  })
                : null}
            </div>
          )}
        </div>
      ) : (
        <MediaUploader />
      )}
    </StyledWrapperMain>
  );
}

export default Library;

function generateImages(data) {
  let results = [];
  for (let i = 0; i < data.length; i++) {
    results.push({
      src: data.url,
      thumbnail: data.url,
      thumbnailWidth: data.width,
      thumbnailHeight: data.height,
      caption: data.bucket,
    });
  }
}

const StyledWrapperMain = styled.section`
  svg {
    width: 50px;
    height: 50px;
    fill: #28eebf;
  }
`;
