import {
  //get
  USERS_DATA,
  USERS_DATA_ID_SUCCESS,
  USERS_DATA_SUCCESS,
  //post
  USERS_DATA_POST,
  USERS_DATA_POST_SUCCESS,
  //put
  USERS_DATA_PUT,
  USERS_DATA_PUT_SUCCESS,
  //fail
  USERS_DATA_FAIL,
  //remove
  USERS_DATA_ID_REMOVE,
} from "../config/UsersConfig";

const intialState = {
  usersDataId: [],
  usersData: [],
  usersDataPost: "",
  usersDataPut: "",
  isLoading: false,
};

export const UsersReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case USERS_DATA:
      return {
        ...state,
        isLoading: true,
        usersData: [],
        usersDataPost: "",
        usersDataPut: "",
      };
    case USERS_DATA_SUCCESS:
      return { ...state, isLoading: false, usersData: action.value };
    case USERS_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, usersDataId: action.value };

    //post
    case USERS_DATA_POST:
      return { ...state, isLoading: true };
    case USERS_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, usersDataPost: "success" };

    //put
    case USERS_DATA_PUT:
      return { ...state, isLoading: true };
    case USERS_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, usersDataPut: "success" };

    //fail
    case USERS_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        usersDataPost: "fail",
        usersDataPut: "fail",
      };
    //remove user data
    case USERS_DATA_ID_REMOVE:
      return {
        ...state,
        usersDataId: [],
      };
    default:
      return state;
  }
};
