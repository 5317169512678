import React from "react";
import { Button, Card } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import "../assets/css/Personal/priceList.css";
function Pricelist({ value }) {
  return (
    <Card className="bt-6 mb-4 membership_card__wrapper">
      {value === 1 ? (
        <div className="membership_card__active">
          <div className="membership_card__active__selected">Dipilih</div>
        </div>
      ) : (
        ""
      )}
      <h1 className="price-container__title ">
        Rp. 500.000
        <span className="membership_card_span ">/ Bulan</span>
      </h1>
      <h4 className="membership_card__subtitle">
        Untuk memvalidasi semua Framer sebagai alat prototyping profesional.
      </h4>
      <h4 className="membership_card__point mb-2">
        <FaCheck className="mr-2" /> Dilihat kapan saja
      </h4>
      <h4 className="membership_card__point mb-2">
        <FaCheck className="mr-2" /> Semuanya gratis
      </h4>
      <h4 className="membership_card__point mb-4">
        <FaCheck className="mr-2" /> Bagikan link pribadi
      </h4>
      {value === 1 ? (
        <Button className="bg-blue p-2 h-50px">
          <span className=" font-weight-bold">Aktif</span>
        </Button>
      ) : (
        <Button block className="bg-gray p-2 h-50px">
          <span className=" font-weight-bold fn-gray">Berlangganan</span>
        </Button>
      )}
    </Card>
  );
}
export default Pricelist;
