import React, { useEffect, useState } from "react";
import { Row, Container, Tabs, Tab } from "react-bootstrap";
import CourseCard from "../../components/CourseCard";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Personal/myCourse.css";
import { useSelector } from "react-redux";

function MyCourse() {
  const history = useHistory();
  // const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [trigger, setTrigger] = useState(true);
  const [myCourses, setMyCourses] = useState({
    rec_comp: [],
    ongoing: [],
    completed: [],
    rec: [],
  });

  useEffect(() => {
    AuthIsLogin(history);
  }, []);

  useEffect(() => {
    const courses = users.usersDataId.my_courses;
    const myCoursesNew = { ...myCourses };
    if (courses && trigger) {
      for (let i = 0; i < courses.length; i++) {
        if (courses[i].status === "todo") {
          myCoursesNew.todo.push(courses[i]);
        }
        if (courses[i].status === "ongoing") {
          myCoursesNew.ongoing.push(courses[i]);
        }
        if (courses[i].status === "completed") {
          myCoursesNew.completed.push(courses[i]);
        }
      }
      setTrigger(false);
      setMyCourses(myCoursesNew);
    }
  }, [users.usersDataId]);
  console.log("tes", myCourses.ongoing);

  return (
    <Container className="my_course__page">
      <h2 className="h2 my_course__section_title mb-3 ">Pelatihan Saya</h2>
      <Tabs
        defaultActiveKey="progress"
        transition={false}
        id="noanim-tab-example"
      >
        <Tab
          tabClassName="text-blacks"
          eventKey="progress"
          title="Pelatihan Berlangsung"
        >
          <Row className="">
            {myCourses.ongoing.map((value, index) => {
              return (
                <CourseCard
                  key={index}
                  progress={myCourses.ongoing}
                  status={myCourses.ongoing}
                  item={value}
                  id={value.course_id}
                />
              );
            })}
          </Row>
        </Tab>
        <Tab
          tabClassName="text-blacks"
          eventKey="end"
          title="Pelatihan Selesai"
        >
          <Row className="">
            {myCourses.completed.map((value, index) => {
              return (
                <CourseCard
                  key={index}
                  progress={myCourses.completed}
                  status={myCourses.completed}
                  item={value}
                  id={value.course_id}
                />
              );
            })}
          </Row>
        </Tab>
        <Tab
          tabClassName="text-blacks"
          eventKey="rekomendasi"
          title="Rekomendasi Pelatihan"
        >
          <Row className="">
            {[...Array(4)].map((value, index) => {
              return <CourseCard key={index} />;
            })}
          </Row>
        </Tab>
        <Tab
          tabClassName="text-blacks"
          eventKey="perusahaan"
          title="Pelatihan Perusahaan"
        >
          <Row className="">
            {[...Array(4)].map((value, index) => {
              return <CourseCard key={index} />;
            })}
          </Row>
        </Tab>
      </Tabs>
    </Container>
  );
}

export default MyCourse;
