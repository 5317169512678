import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import "../assets/css/Personal/achievementCard.css";

function AchievementCard({ mar, pad, width }) {
  return (
    <>
      <Card
        className="acv-card-body mt-4"
        // style={{ width: width, padding: pad, margin: mar }}
      >
        <Card.Img
          src="https://luma-vue.demo.frontendmatter.com/images/achievements/flinto.png"
          alt="https://luma-vue.demo.frontendmatter.com/images/achievements/flinto.png"
          className="acv-card-img"
        />
        <Card.ImgOverlay>
          <Col
            className="d-flex flex-column justify-content-center"
            style={{ height: "100%" }}
          >
            <Row className="d-flex flex-row justify-content-center mb-2">
              <Col className="d-flex flex-column align-items-end">
                <Card.Title className="text-center acv-title">
                  Pencapaian
                </Card.Title>
                <Card.Text className="acv-sub-text">5 Juni 2021</Card.Text>
              </Col>
              <Col>
                <Card.Img
                  src="https://luma-vue.demo.frontendmatter.com/images/paths/flinto_40x40@2x.png"
                  alt="https://luma-vue.demo.frontendmatter.com/images/paths/flinto_40x40@2x.png"
                  className="acv-card-sub-img"
                />
              </Col>
            </Row>
            <Row className="d-flex flex-row justify-content-center mt-2">
              <Col className="d-flex flex-column align-items-end">
                <Card.Img
                  src="https://luma-vue.demo.frontendmatter.com/images/illustration/achievement/128/white.png"
                  alt="https://luma-vue.demo.frontendmatter.com/images/illustration/achievement/128/white.png"
                  className="acv-card-sub-img"
                />
              </Col>
              <Col className="d-flex flex-column align-items-start">
                <Card.Title className="text-center acv-title">
                  Flinto
                </Card.Title>
                <Card.Text className="acv-sub-text">
                  Pengenalan ke Desain Aplikasi
                </Card.Text>
              </Col>
            </Row>
          </Col>
        </Card.ImgOverlay>
      </Card>
    </>
  );
}
export default AchievementCard;
