import React from "react";
import { BiPlayCircle, BiTime } from "react-icons/bi";
import { FaStar } from "react-icons/fa";
import { Card, Button } from "react-bootstrap";
import "../assets/css/Personal/featuredPathCard.css";
import { Link } from "react-router-dom";

function FeaturedPathCard() {
  return (
    <>
      <Card className="card_container mt-2">
        <div className="card_thumbnail__learning_path">
          {" "}
          <Card.Img
            src="https://www.sketchappsources.com/resources/source-image/3d-dash-illustration-sumit_choudhary.png"
            className=""
            alt="https://www.sketchappsources.com/resources/source-image/3d-dash-illustration-sumit_choudhary.png"
          />
        </div>
        <div className="card_inside__padding ">
          <div className="d-flex mb-3 align-items-center justify-content-between">
            <div className="d-flex">
              <div>
                <div className="fp-card-title">Judul</div>
                <div className="d-flex justify-content-between mt-3">
                  <div className="mr-3">
                    <Card.Text className="fp-card-sub-text fs-12">
                      <BiTime className="mr-1" />
                      50 menit tersisa
                    </Card.Text>
                  </div>
                  <div>
                    <Card.Text className="fp-card-sub-text fs-12">
                      <BiPlayCircle className="mr-1" />
                      12 pelajaran
                    </Card.Text>
                  </div>
                </div>
              </div>
            </div>

            <Card.Text variant="primary" className="fp-card-hrt" size="sm">
              ❤︎
            </Card.Text>
          </div>
          <div>
            <div>
              <Card.Text className="fp-card-text">
                Angular adalah platform untuk membangun web seluler dan desktop
                aplikasi.ing
              </Card.Text>
            </div>
          </div>
          <div className="d-flex justify-content-between my-3"></div>
          <div className="d-flex justify-content-between align-items-center">
            <Link to={`/learning-path/`} style={{ textDecoration: "none" }}>
              <Button className="learning_path__button">Mulai</Button>
            </Link>
            <div className="d-flex align-items-center">
              <Card.Text className="fp-card-text mr-2 mb-0">Rating</Card.Text>
              {[...Array(5)].map((value, index) => {
                return (
                  <div className="d-flex" key={index}>
                    <FaStar className="rating_size " />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}

export default FeaturedPathCard;
