const sha256 = require("js-sha256");

export function AuthData() {
  let a = JSON.parse(localStorage.getItem("auth"));
  return a;
}

export function AuthIsLogin(history, returnValue) {
  let a = JSON.parse(localStorage.getItem("auth"));
  if (!a) {
    if (returnValue) return false;
    else history.push(`/login`);
  } else return true;
}

export function AuthLogin(auth) {
  auth.password = sha256(auth.password);
  localStorage.setItem("auth", JSON.stringify(auth));
}

export function AuthLogout(history) {
  localStorage.removeItem("auth");
  history.push(`/login`);
}
