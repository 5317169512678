import React, {  useState } from "react";
import { FaCopyright } from "react-icons/fa";
import Header from "./Header";

// import { useLocation } from "react-router-dom";
// import { store } from "react-notifications-component";
import { Helmet } from "react-helmet";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "../../assets/css/Layout/main.css";
import "animate.css";

const Main = ({
  handleToggleSidebar,
  handleCollapsedChange,
  InnerComponent,
  isSidebar,
  collapsed,
}) => {
  const [totalNotif, setTotalNotif] = useState(0);

  // let address = useLocation();
  // let splitted = address.pathname.split("/");
  return (
    <main>
      <ReactNotification />
      {totalNotif > 0 ? (
        <Helmet>
          <title>
            {`(${totalNotif}) Satukelas - Belajar Manejemen Terlengkap
            Se-Indonesia!`}
          </title>
        </Helmet>
      ) : (
        <Helmet>
          <title>
            {`Satukelas - Belajar Manejemen Terlengkap
            Se-Indonesia!`}
          </title>
        </Helmet>
      )}
      <header>
        {/* {splitted[1] == "home" ? (
          ""
        ) : ( */}
        <Header
          collapsed={collapsed}
          isSidebar={isSidebar}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        />
        {/* )} */}
      </header>
      <InnerComponent />
      <footer>
        <div className="satukelas_copyright">
          satukelas{" "}
          <span className="black">
            <FaCopyright /> 2021{" "}
          </span>
          powered by -{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://siapkonek.com"
          >
            Koneksi Group
          </a>
        </div>
      </footer>
    </main>
  );
};

export default Main;
