import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import CcChat from "../../components/ConnectyCube/ccChat";
import ConnectyCube from 'connectycube';
import '../../assets/css/ConnectyCube/ccChat.css';
import "../../assets/css/ConnectyCube/ccUserProfile.css";
import moment from "moment";

const CREDENTIALS = {
  // appId: process.env.REACT_APP_CC_APP_ID,
  // authKey: process.env.REACT_APP_CC_AUTH_KEY,
  // authSecret: process.env.REACT_APP_CC_AUTH_SECRET,
  appId: 5433,
  authKey: 'XRTCwBTOC3HaKds',
  authSecret: 'CGdhdRJgfOCTzEF',
};

const CONFIG = {
  debug: { mode: 0 }, // enable DEBUG mode (mode 0 is logs off, mode 1 -> console.log())
};

const userCredentials = {
  // userId: process.env.REACT_APP_CC_UID,
  // password: process.env.REACT_APP_CC_CRED,
  userId: 4957569,
  password: 'student@student.com',
};

ConnectyCube.init(CREDENTIALS, CONFIG);

function ConnectyCubeChat() {
  const [isConnected, setIsConnected] = useState(null);
  const [dialogs, setDialogs] = useState(null);
  const [isError, setIsError] = useState('Connecting..');
  const [messages, setMessages] = useState([])
  // Change this corresponding to logged in user
  const user = { email: "student@student.com", password: "student@student.com", userId: 4957569};

  useEffect(() => {
    // #1 create a user session
    ConnectyCube.createSession(user)
      .then((session) => {
        // session is created
        console.log("session ",session);
      })
      .catch((error) => {
        // session error 
        console.log(error);
      });

    // #2 connect to chat
    ConnectyCube.chat
      .connect(userCredentials)
      .then(() => {
        setIsConnected(ConnectyCube.chat.isConnected)
        setIsError('Loading chat..')
      })
      .catch((error) => {
        setIsConnected(ConnectyCube.chat.isConnected)
        setIsError('Connection error. Please try again later.')
        console.log(error);
      });
  }, [])

  useEffect(() => {
    if (isConnected) {
      const filters = {};
      // #3 load dialogs
      ConnectyCube.chat.dialog
        .list(filters)
        .then((result) => {
          setDialogs(result.items)
          console.log("dialog ",result)
        })
        .catch((error) => {
          setIsError('Loading chat failed. Please try again later.');
          console.log(error);
        });
    }
  }, [isConnected])

  useEffect(() => {
    if (dialogs && dialogs.length > 0) {
      const dialogId = dialogs[0]._id;
      const params = {
        chat_dialog_id: dialogId, 
        sort_asc: "date_sent",
        limit: 100,
        skip: 0,
      };
      
      // #4 load chat history
      ConnectyCube.chat.message
        .list(params)
        .then((messages) => { 
          console.log("messages ",messages);
          setMessages(messages.items);
          setIsError(null)
          messages.items.length > 0 && scrollToBtm()
        })
        .catch((error) => { 
          console.log(error);
          setIsError('Loading chat failed. Please try again later.');
        });
      ConnectyCube.chat.onMessageListener = onMessage;
    }else if(dialogs){
      setIsError(null)
    }
  }, [dialogs])

  function onMessage(userId, message) {
    console.log('[ConnectyCube.chat.onMessageListener] callback:', userId, message)
    // add element
    displayNewMessage('left', message)
  }

  const sendMessage = (par) => {
    const message = {
      type: 'chat',
      body: par,
      extension: {
        save_to_history: 1,
        dialog_id:  dialogs[0]._id,
      },
      markable: 1
    };
    // opponent ID, message
    const opponentID = dialogs[0].occupants_ids[0] === user.userId ? dialogs[0].occupants_ids[1] : dialogs[0].occupants_ids[0]
    console.log('[ConnectyCube.chat.send] callback:', ConnectyCube.chat.send(opponentID, message));
    // add element
    displayNewMessage('right', message)
  }

  const displayNewMessage = (status, message) => {
    const html = `
    <div class="chat-message-layout">
      <div class="chat-message-wrap chat-message-wrap-${status}">
        <div class="chat-message-container-position-${status}">
          <span>${message.body}</span>
            <div class="chat-message-${status}-footer">
              <span>
              ${status === 'left' ? moment.unix(message.extension.date_sent).format("HH:mm") : moment().format("HH:mm")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>`
    const $container = document.querySelector('.chat-body .content');
    $container.insertAdjacentHTML('beforeend', html)
    scrollToBtm();
  }
  
  const scrollToBtm = () => {
    const $container = document.querySelector('.chat-body .content');
    $container.lastElementChild.scrollIntoView();
  }

  return (
    <>
      <Container className="dashboard_page__container" style={{ padding: "8px 0" }}>
        <CcChat callbackSend={sendMessage} isError={isError} messages={messages} user={user}/>
      </Container>
    </>
  );
}

export default ConnectyCubeChat;