import React from "react";
import { Container, Row, Col} from "react-bootstrap";

import CardBenefit from "../../components/CardBenefit";
// import { useDispatch, useSelector } from "react-redux";
import WebinarDetail from "../Courses/WebinarDetail";

function SinglePageWebinar() {
  // const handleOnClick = useCallback(
  //   (param) => {
  //     history.push(`/course/${courseId}/${param}`);
  //   },
  //   [history]
  // );.
  // const dispatch = useDispatch();
  // const lessons = useSelector((state) => state.lessons);
  // const courses = useSelector((state) => state.courses);
  // const course = courses.coursesDataId;
  // const lesson = lessons.lessonsDataId;
  return (
    <div className="middle-sidebar-bottom">
      <Container fluid="xs" className="dashboard_page__container">
        <Row className="no-gutters-xs">
          <Col
            xs={{ span: 12, order: 2 }}
            sm={{ span: 12, order: 2 }}
            md={{ span: 3, order: 1 }}
            lg={3}
          >
            {/* show the learning objective from webinar */}

            <CardBenefit />
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            sm={{ span: 12, order: 1 }}
            md={{ span: 9, order: 2 }}
            lg={9}
          >
            {/* webinar detail*/}
            <WebinarDetail />

            {/* <JamBoard /> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SinglePageWebinar;
