import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "../../assets/css/Courses/index.css";
import CourseCard from "../../components/CourseCard";
import AccordionCourse from "../../components/AccordionCourse";
import PrerequisitesCourse from "../../components/PrerequisitesCourse";
import CourseDetailTitle from "../../components/CourseDetailTitle";
import Sertificate from "../../components/Sertificate";
// import ExploreCorporateMaterial from "../../components/ExploreCorporateMaterial";
import RelatedMaterialSection from "../../components/RelatedMaterialSection";
import RelatedFileSection from "../../components/RelatedFileSection";
import CardPrice from "../../components/CardPrice";
import CardBenefit from "../../components/CardBenefit";
import CardReqruitment from "../../components/CardReqruitment";
import CardAudience from "../../components/CardAudience";
import CardCoveredSkill from "../../components/CardCoveredSkill";
import Instructor from "../../components/Instructor";
import CardDesc from "../../components/CardDesc";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCoursesData,
  postEnrollCourse,
} from "../../redux/actions/CoursesActions";
import { useLocation, useHistory } from "react-router-dom";
import "./../../assets/css/Courses/CourseFront.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getUsersData } from "../../redux/actions/UsersActions";
import { AuthData, AuthIsLogin } from "../../helper/auth";
import { SanitizeMaterialTypeId } from "../../helper/functions";
import ReactGA from "react-ga";

const MySwal = withReactContent(Swal);

function CourseFront() {
  const { course_id } = useParams();
  const [enrolled, setEnrolled] = useState(false);
  const [anotherAttempt, setAnotherAttempt] = useState(false);
  const [enrollAndStart, setEnrollAndStart] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [purchased, setPurchased] = useState(false);
  const [userStatus, setUserStatus] = useState();
  const [progress, setProgress] = useState(0);
  const [enrollPayload, setEnrollPayload] = useState({
    user_id: "",
    course_id: "",
  });
  const courses = useSelector((state) => state.courses);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const course = courses.coursesDataId;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    //to track url changes, so we can re dispatch the redux data if the course_id change
    getCoursesDataId();
    if (AuthIsLogin(history, true)) dispatch(getUsersData(AuthData().userId));
  }, [location]);

  useEffect(() => {
    if (course.price === "Free") {
      setPurchased(true);
    } else {
      if (users.usersDataId?.purchases) {
        if (
          users.usersDataId.purchases.filter((e) => e.course_id === course_id)
            .length > 0
        ) {
          setPurchased(true);
        }
      }
    }
  }, [location, users, course]);

  useEffect(() => {
    if (users.usersDataId?.my_courses) {
      if (
        users.usersDataId.my_courses.filter((e) => e.course_id === course_id)
          .length > 0
      ) {
        setProgress(
          users.usersDataId.my_courses
            .filter((e) => e.course_id === course_id)[0]
            .progress.toFixed(2)
        );
      } else setProgress(0);
    }
  }, [location, users]);

  useEffect(() => {
    if (courses.courseDataEnroll === "success" && trigger) {
      setTrigger(false);
      dispatch(getUsersData(AuthData().userId));
      setEnrollAndStart(true);
    }
    if (courses.courseDataEnroll === "fail" && trigger) {
      setTrigger(false);
      displayPopup(false, "Gagal Memulai Kursus!", "Oke");
    }
  }, [courses]);
  function getCoursesDataId() {
    dispatch(getCoursesData({ type: "id", param: `/${course_id}` }));
  }
  useEffect(() => {
    const myCourses = users.usersDataId.my_courses;
    if (course_id && myCourses) {
      for (let i = 0; i < myCourses.length; i++) {
        if (myCourses[i].course_id === course_id) {
          if (myCourses[i].status === "completed") {
            setAnotherAttempt(true);
            setEnrolled(true);
          } else setEnrolled(true);
          break;
        }
      }
      const payload = {
        user_id: users.usersDataId.user_id,
        course_id: course_id,
      };
      setEnrollPayload(payload);
    }
  }, [users]);

  useEffect(() => {
    if (enrollAndStart) {
      const sanitizedMaterial = SanitizeMaterialTypeId(
        course.topics[0].materials[0]
      );
      history.push(
        `/course/${course_id}/${sanitizedMaterial.type}/${sanitizedMaterial.id}_0_0`
      );
    }
  }, [enrolled]);

  function enrollCourse() {
    setTrigger(true);
    dispatch(postEnrollCourse(enrollPayload));
  }

  function getIndex() {
    const topics = course.topics;
    for (let i = 0; i < topics.length; i++) {
      const materials = topics[i].materials;
      for (let j = 0; j < materials.length; j++) {
        if (materials[j].completed === 0) {
          const data = { index: i, indexL: j };
          return data;
        }
      }
    }
  }
  function continueCourse() {
    if (anotherAttempt) {
      const sanitizedMaterial = SanitizeMaterialTypeId(
        course.topics[0].materials[0]
      );
      history.push(
        `/course/${course_id}/${sanitizedMaterial.type}/${sanitizedMaterial.id}_0_0`
      );
    } else {
      const idxs = getIndex();
      const sanitizedMaterial = SanitizeMaterialTypeId(
        course.topics[idxs.index].materials[idxs.indexL]
      );
      history.push(
        `/course/${course_id}/${sanitizedMaterial.type}/${sanitizedMaterial.id}_${idxs.index}_${idxs.indexL}`
      );
    }
  }

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {});
  }
  useEffect(() => {
    const memberships = users.usersDataId.memberships;
    if (memberships) {
      if (memberships.length > 0) setUserStatus(memberships[0].status);
    }
  }, [users]);
  return (
    <Container className="course_front__container dashboard_page__container">
      <Row>
        <Col md={8}>
          <CourseDetailTitle
            item={course}
            enrolled={enrolled}
            enrollCourse={enrollCourse}
            purchased={purchased}
            statusUser={userStatus}
            continueCourse={continueCourse}
            anotherAttempt={anotherAttempt}
          />
          <CardBenefit objective={course.objectives} />
          <AccordionCourse
            topics={course.topics}
            courseId={course_id}
            enrolled={enrolled}
          />
          <RelatedFileSection handouts={course.handouts} />
          <div className="course_front__deskripsi_requirement__wrapper">
            <CardCoveredSkill skillcover={course.skills_covered} />
            <CardDesc description={course.description} />
            <CardReqruitment reqruitment={course.requirements} />
          </div>
          <CardAudience audience={course.audiences} />

          <PrerequisitesCourse prereq={course.prerequisites} />

          <Instructor item={course} />
          {course.course_type === "Sertifikasi" ? <Sertificate /> : ""}
          <Sertificate />
          {course.course_type === "Sertifikasi" ? null : (
            <div>
              <h2 className="h2 course_front__kurikulum_title">
                Pelatihan Populer Lainnya
              </h2>
              <Row className="course-related__container">
                {[...Array(3)].map((item, i) => {
                  return <CourseCard key={i} />;
                })}
              </Row>
            </div>
          )}
        </Col>
        <Col md={4}>
          {course.topics && (
            <CardPrice
              statusUser={userStatus}
              item={course}
              enrolled={enrolled}
              enrollCourse={enrollCourse}
              purchased={purchased}
              progress={progress}
              continueCourse={continueCourse}
              anotherAttempt={anotherAttempt}
            />
          )}

          {/* <ProgressCourse /> */}
          <RelatedMaterialSection title={`Pelatihan Terkait`} />
          <RelatedMaterialSection title={`Webinar Terkait`} />

          <RelatedMaterialSection title={`Tryout Terkait`} />
          <RelatedMaterialSection title={`Sertifikasi Terkait`} />
          <RelatedMaterialSection title={`Materi Perusahaan`} />

          {/* <ExploreCorporateMaterial />
           */}
        </Col>
      </Row>
    </Container>
  );
}

export default CourseFront;
