import { call, put, takeEvery } from "redux-saga/effects";
import { PromiseService } from "../../services/PromiseService";
import {
  LEARNING_PATHS_DATA,
  LEARNING_PATHS_DATA_LINK,
} from "../config/LearningPathsConfig";
import {
  getLearningPathsDataSuccess,
  getLearningPathsDataIdSuccess,
  getLearningPathsDataFail,
} from "../actions/LearningPathsActions";

function* workerLearningPathsData(data) {
  if (data.value.type === "id") {
    try {
      const response = yield call(
        PromiseService.getData,
        `${LEARNING_PATHS_DATA_LINK}${data.value.param}`
      );
      if (response.status === 200 && response.data) {
        yield put(getLearningPathsDataIdSuccess(response.data));
      } else {
        yield put(getLearningPathsDataFail(response));
      }
    } catch (error) {
      yield put(getLearningPathsDataFail(error));
    }
  } else if (data.value.type === "filter") {
    try {
      const response = yield call(
        PromiseService.getData,
        `${LEARNING_PATHS_DATA_LINK}${data.value.param}`
      );
      if (response.status === 200 && response.data) {
        yield put(getLearningPathsDataSuccess(response.data));
      } else {
        yield put(getLearningPathsDataFail(response));
      }
    } catch (error) {
      yield put(getLearningPathsDataFail(error));
    }
  }
}

export const watcherLearningPaths = [
  takeEvery(LEARNING_PATHS_DATA, workerLearningPathsData),
];
