import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
function QuizQuestion({
  questData,
  count,
  total,
  setCount,
  allAnswer,
  setAllAnswer,
  setDoneQuiz,
  minimizevalue,
  quizKey,
}) {
  const [selected, setSelected] = useState([false, false, false, false]);
  const lastQuestion = count >= total - 1;
  useEffect(() => {
    const myDiv = document.getElementById("containerDiv");
    myDiv.scrollTop = 0;
  }, [count]);

  useEffect(() => {
    if (allAnswer[count]) {
      setSelected(check(allAnswer[count].answer));
    }
  }, [count]);

  function onClickButton() {
    let selectedAnswer = "";
    switch (selected.indexOf(true)) {
      case 0:
        selectedAnswer = "A";
        break;
      case 1:
        selectedAnswer = "B";
        break;
      case 2:
        selectedAnswer = "C";
        break;
      case 3:
        selectedAnswer = "D";
        break;
      default:
        break;
    }
    const answer = {
      answer: selectedAnswer,
      question_id: questData.question_id,
    };
    const newAnswer = allAnswer;
    const existAnswer = newAnswer.findIndex(
      (i) => i.question_id === questData.question_id
    );
    if (existAnswer === -1) newAnswer.push(answer);
    else newAnswer[existAnswer] = answer;
    setAllAnswer(newAnswer);
    localStorage.setItem(`_${quizKey}`, JSON.stringify(newAnswer));
    if (lastQuestion) {
      console.log(allAnswer);
      setDoneQuiz(true);
    } else {
      const newState = count + 1;
      setCount(newState);
      setSelected(check(-1));
    }
  }
  function onClickBackButton() {
    const newState = count - 1;
    setCount(newState);
  }
  function onClickAnswer(param) {
    setSelected(check(param));
  }
  return (
    <Col
      xs={11}
      lg={12}
      className="quiz-quest-main-col d-flex flex-column justify-content-center align-items-start"
    >
      <Card.Text className="quiz-quest-no">
        {questData ? questData.number : ""}. {questData ? questData.title : ""}
      </Card.Text>
      <div className="quiz-quest-wrapper-scrollbar" id="containerDiv">
        <Card.Title className="quiz-quest-question">
          {questData ? questData.question : ""}
        </Card.Title>
      </div>
      <Col className="quiz-quest-ans-col">
        <Row
          className={`quiz-quest-ans-row  ${
            selected[0] ? "quiz-quest-ans-row-selected" : null
          }`}
          onClick={() => onClickAnswer("A")}
        >
          <Button disabled variant={selected[0] ? "primary" : "secondary"}>
            A
          </Button>
          <div className="quiz-quest-ans-wrapper quiz-quest-wrapper-scrollbar">
            <Card.Text className="quiz-quest-ans-text">
              {questData ? questData.choice_a : ""}
            </Card.Text>
          </div>
        </Row>
        <Row
          className={`quiz-quest-ans-row ${
            selected[1] ? "quiz-quest-ans-row-selected" : null
          }`}
          onClick={() => onClickAnswer("B")}
        >
          <Button disabled variant={selected[1] ? "primary" : "secondary"}>
            B
          </Button>
          <div className="quiz-quest-ans-wrapper quiz-quest-wrapper-scrollbar">
            <Card.Text className="quiz-quest-ans-text">
              {questData ? questData.choice_b : ""}
            </Card.Text>
          </div>
        </Row>
        <Row
          className={`quiz-quest-ans-row ${
            selected[2] ? "quiz-quest-ans-row-selected" : null
          }`}
          onClick={() => onClickAnswer("C")}
        >
          <Button disabled variant={selected[2] ? "primary" : "secondary"}>
            C
          </Button>
          <div className="quiz-quest-ans-wrapper quiz-quest-wrapper-scrollbar">
            <Card.Text className="quiz-quest-ans-text">
              {questData ? questData.choice_c : ""}
            </Card.Text>
          </div>
        </Row>
        <Row
          className={`quiz-quest-ans-row ${
            selected[3] ? "quiz-quest-ans-row-selected" : null
          }`}
          onClick={() => onClickAnswer("D")}
        >
          <Button disabled variant={selected[3] ? "primary" : "secondary"}>
            D
          </Button>
          <div className="quiz-quest-ans-wrapper quiz-quest-wrapper-scrollbar">
            <Card.Text className="quiz-quest-ans-text">
              {questData ? questData.choice_d : ""}
            </Card.Text>
          </div>
        </Row>
      </Col>
      <Row className="quiz-next-submit-btn d-flex flex-row justify-content-center">
        <Button onClick={onClickBackButton} disabled={count <= 0}>
          Kembali
        </Button>
        <Button onClick={onClickButton} disabled={!selected.includes(true)}>
          {lastQuestion ? "Submit" : "Lanjutkan"}
        </Button>
      </Row>
    </Col>
  );
}
export default QuizQuestion;

function check(data) {
  switch (data) {
    case "A":
      return [true, false, false, false];
    case "B":
      return [false, true, false, false];
    case "C":
      return [false, false, true, false];
    case "D":
      return [false, false, false, true];
    default:
      return [false, false, false, false];
  }
}
