import { call, put, takeEvery } from "redux-saga/effects";
import { PromiseService } from "../../services/PromiseService";
import {
  //get
  COURSES_DATA,
  COURSES_DATA_LINK,
  //post enroll
  COURSES_DATA_ENROLL,
  //complete material
  COURSES_COMPLETE_MATERIAL,
  //complete course
  COURSES_COMPLETE_COURSE,
} from "../config/CoursesConfig";
import {
  //get
  getCoursesDataSuccess,
  getCoursesDataIdSuccess,
  getCoursesDataFail,
  //post enroll
  postEnrollCourseSuccess,
  postEnrollCourseFail,
  //complete material
  postCompleteMaterialSuccess,
  postCompleteMaterialFail,
  //complete course
  postCompleteCourseSuccess,
  postCompleteCourseFail,
} from "../actions/CoursesActions";

function* workerCoursesData(data) {
  if (data.value.type === "id") {
    try {
      const response = yield call(
        PromiseService.getData,
        `${COURSES_DATA_LINK}${data.value.param}`
      );
      if (response.status === 200 && response.data) {
        yield put(getCoursesDataIdSuccess(response.data));
      } else {
        yield put(getCoursesDataFail(response));
      }
    } catch (error) {
      yield put(getCoursesDataFail(error));
    }
  } else if (data.value.type === "filter") {
    try {
      const response = yield call(
        PromiseService.getData,
        `${COURSES_DATA_LINK}${data.value.param}`
      );
      if (response.status === 200 && response.data) {
        yield put(getCoursesDataSuccess(response.data));
      } else {
        yield put(getCoursesDataFail(response));
      }
    } catch (error) {
      yield put(getCoursesDataFail(error));
    }
  }
}

function* workerEnrollCoursesData(data) {
  const body = data.value;
  try {
    const response = yield call(
      PromiseService.postData,
      `${COURSES_DATA_LINK}/enroll`,
      body
    );
    if ((response.status === 201 || response.status === 200) && response.data) {
      yield put(postEnrollCourseSuccess(response.data));
    } else {
      yield put(postEnrollCourseFail(response));
    }
  } catch (error) {
    yield put(postEnrollCourseFail(error));
  }
}

function* workerCompleteMaterial(data) {
  const body = data.value;
  try {
    const response = yield call(
      PromiseService.postData,
      `${COURSES_DATA_LINK}/complete_material`,
      body
    );
    if ((response.status === 201 || response.status === 200) && response.data) {
      yield put(postCompleteMaterialSuccess(response.data));
    } else {
      yield put(postCompleteMaterialFail(response));
    }
  } catch (error) {
    yield put(postCompleteMaterialFail(error));
  }
}

function* workerCompleteCourse(data) {
  const body = data.value;
  try {
    const response = yield call(
      PromiseService.postData,
      `${COURSES_DATA_LINK}/complete_course`,
      body
    );
    if ((response.status === 201 || response.status === 200) && response.data) {
      yield put(postCompleteCourseSuccess(response.data));
    } else {
      yield put(postCompleteCourseFail(response));
    }
  } catch (error) {
    yield put(postCompleteCourseFail(error));
  }
}

export const watcherCourses = [
  takeEvery(COURSES_DATA, workerCoursesData),
  takeEvery(COURSES_DATA_ENROLL, workerEnrollCoursesData),
  takeEvery(COURSES_COMPLETE_MATERIAL, workerCompleteMaterial),
  takeEvery(COURSES_COMPLETE_COURSE, workerCompleteCourse),
];
