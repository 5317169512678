export const COURSES_DATA_LINK = "courses";
//get
export const COURSES_DATA = "COURSES_DATA";
export const COURSES_DATA_SUCCESS = "COURSES_DATA_SUCCESS";
export const COURSES_DATA_ID_SUCCESS = "COURSES_DATA_ID_SUCCESS";
export const COURSES_DATA_FAIL = "COURSES_DATA_FAIL";
//post enroll
export const COURSES_DATA_ENROLL = "COURSES_DATA_ENROLL";
export const COURSES_DATA_ENROLL_SUCCESS = "COURSES_DATA_ENROLL_SUCCESS";
export const COURSES_DATA_ENROLL_FAIL = "COURSES_DATA_ENROLL_FAIL";
//complete material
export const COURSES_COMPLETE_MATERIAL = "COURSES_COMPLETE_MATERIAL";
export const COURSES_COMPLETE_MATERIAL_SUCCESS =
  "COURSES_COMPLETE_MATERIAL_SUCCESS";
export const COURSES_COMPLETE_MATERIAL_FAIL = "COURSES_COMPLETE_MATERIAL_FAIL";
//complete course
export const COURSES_COMPLETE_COURSE = "COURSES_COMPLETE_COURSE";
export const COURSES_COMPLETE_COURSE_SUCCESS =
  "COURSES_COMPLETE_COURSE_SUCCESS";
export const COURSES_COMPLETE_COURSE_FAIL = "COURSES_COMPLETE_COURSE_FAIL";
//remove complete
export const COURSES_REMOVE_COMPLETE_STATE = "COURSES_REMOVE_COMPLETE_STATE";
