import {
  LOGIN_DATA,
  LOGIN_DATA_SUCCESS,
  LOGIN_DATA_FAIL,
} from "../config/LoginConfig";

const intialState = {
  loginData: null,
  isLoading: false,
};

export const LoginReducers = function (state = intialState, action) {
  switch (action.type) {
    case LOGIN_DATA:
      return { ...state, isLoading: true, loginData: null };
    case LOGIN_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loginData: { data: action.value, status: "success" },
      };
    case LOGIN_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        loginData: {
          data: action.value.error.response.data.detail,
          status: "fail",
        },
      };
    default:
      return state;
  }
};
