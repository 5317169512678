import {
  LOGIN_DATA,
  LOGIN_DATA_SUCCESS,
  LOGIN_DATA_FAIL,
} from "../config/LoginConfig";

export const getLoginData = (value) => ({
  type: LOGIN_DATA,
  value,
});
export const getLoginDataSuccess = (value) => ({
  type: LOGIN_DATA_SUCCESS,
  value,
});
export const getLoginDataFail = (value) => ({
  type: LOGIN_DATA_FAIL,
  value,
});
