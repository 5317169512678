/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "../../assets/css/Layout/header.css";
import styled from "styled-components";
import { Form, Nav, NavDropdown } from "react-bootstrap";
import { FaBell, FaUserAlt, FaBars, FaBuilding } from "react-icons/fa";
import { RiLogoutCircleRLine, RiSettings3Fill } from "react-icons/ri";
import { useHistory, Link } from "react-router-dom";
import { removeUsersDataId } from "../../redux/actions/UsersActions";
import { AuthLogout, AuthIsLogin } from "../../helper/auth";
import Swal from "sweetalert2";

import { useDispatch, useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

function Header({
  isSidebar,
  handleToggleSidebar,
  collapsed,
  handleCollapsedChange,
}) {
  const [name, setName] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLogoutClick = () =>
    MySwal.fire({
      title: "Apakah anda yakin ingin keluar?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Tidak",
      confirmButtonText: "Ya",
    }).then((value) => {
      if (value.isConfirmed) {
        dispatch(removeUsersDataId());
        AuthLogout(history);
      }
    });
  const users = useSelector((state) => state.users);
  useEffect(() => {
    const nameUser = users.usersDataId.username;
    setName(nameUser);
  }, [users]);
  return (
    <StyledWrapperMain>
      <div className="main-content">
        <div
          className="btn-toggle"
          onClick={() => handleCollapsedChange(!collapsed)}
        >
          <FaBars />
        </div>
        <div className="middle-sidebar-header bg-white">
          {!isSidebar ? (
            <div className="d-flex align-items-center header_left__wrapper">
              <Link to="/home">
                <img
                  src="https://satukelas.com/wp-content/uploads/2021/02/png-satukelas-296x63.png"
                  alt="Satukelas"
                  className="satu-kelas-logo"
                />
              </Link>
              {AuthIsLogin("", true) && (
                <Link
                  to="/dashboard/course/"
                  className="header_left__item mr-3"
                >
                  Dashboard
                </Link>
              )}

              <Link className="header_left__item" to="/#">
                Tentang
              </Link>
              <Nav className="mr-auto ">
                <NavDropdown
                  className="catalogue-course"
                  title="Katalog Pelatihan"
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item className="px-3 ">
                    <Link to="/search/courses/" className="dropdown-navigator">
                      Pelatihan
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item className="px-3">
                    <Link to="/webinar" className="dropdown-navigator">
                      Webinar
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              {/* <Button variant="none" className="header_left__item">
                <Link to="/#/">Katalog Pelatihan</Link>
              </Button> */}
              {/* <Button variant="none" className="header_left__item">
                <Link to="/#/">Blog</Link>
              </Button> */}
              {/* <Button variant="none" className="header_left__item">
                <Link to="/#/">Webstore</Link>
              </Button> */}
            </div>
          ) : (
            <div className="d-flex align-items-center header_left__wrapper">
              <div onClick={() => handleCollapsedChange(!collapsed)}>
                <FaBars className="fill-blue expandable-bars mr-2" />
              </div>
              <Link to="/">
                <img
                  src="https://satukelas.com/wp-content/uploads/2021/02/png-satukelas-296x63.png"
                  alt="Satukelas"
                  className="satu-kelas-logo"
                />
              </Link>

              <Link to="/#/" className="header_left__item">
                Tentang
              </Link>
              <Nav className="mr-auto ">
                <NavDropdown
                  className="catalogue-course"
                  title="Katalog Pelatihan"
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item className="px-3 ">
                    <Link to="/search/courses/" className="dropdown-navigator">
                      Pelatihan
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item className="px-3">
                    <Link to="/webinar" className="dropdown-navigator">
                      Webinar
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              {/* <Button variant="none" className="header_left__item">
                <Link to="/#/">Blog</Link>
              </Button> */}
            </div>
          )}

          <ul className="d-flex ml-auto right-menu-icon m-0">
            <li>
              <Form.Control
                className="satukelas_input"
                type="text"
                placeholder="Cari Pelatihan"
              />
            </li>
            {AuthIsLogin("", true) && (
              <li>
                <div>
                  <span className="dot-count bg-warning"></span>
                  <FaBell />
                  <div className="menu-dropdown">
                    <h4 className="notification_text mb-4">Notification</h4>
                    <div className="card box-none bg-transparent-card w-100 border-0 pl-5 mb-3">
                      <img
                        src="https://via.placeholder.com/50x50.png"
                        alt="user"
                        className="w40 position-absolute left-0 rounded-circle"
                      />
                      <h5 className=" name_notification__detail mb-1 mt-0 d-block">
                        Surfiya Zakir
                        <span className="name_notification__subtitle float-right mt-1">
                          1 min
                        </span>
                      </h5>
                      <h6 className="name_notification__body ">
                        Mobile Apps UI Designer is require..
                      </h6>
                    </div>
                    <div className="card box-none bg-transparent-card w-100 border-0 pl-5 mb-3">
                      <img
                        src="https://via.placeholder.com/50x50.png"
                        alt="user"
                        className="w40 position-absolute left-0 rounded-circle"
                      />
                      <h5 className=" name_notification__detail mb-1 mt-0 d-block">
                        Victor Exrixon
                        <span className="name_notification__subtitle float-right mt-1">
                          30 sec
                        </span>
                      </h5>
                      <h6 className="name_notification__body ">
                        Mobile Apps UI Designer is require..
                      </h6>
                    </div>
                    <div className="card box-none bg-transparent-card w-100 border-0 pl-5 mb-3">
                      <Link to="/dashboard/notification">See All</Link>
                    </div>
                  </div>
                </div>
              </li>
            )}
            {AuthIsLogin("", true) ? (
              <li>
                <div>
                  <img
                    src="https://via.placeholder.com/50x50.png"
                    alt="user"
                    className="w40 rounded-circle mt--1"
                  />
                  <div className="menu-dropdown menu-dropdown-profile">
                    <Link className="dropdown-item" to="/dashboard/profile/">
                      <i className="dropdown-icon mdi">
                        <FaUserAlt />
                      </i>
                      Profil {name}
                    </Link>
                    <Link className="dropdown-item" to="/company-profile">
                      <i className="dropdown-icon mdi">
                        <FaBuilding />
                      </i>
                      Perusahaan Saya
                    </Link>
                    <Link className="dropdown-item" to="/dashboard/settings/">
                      <i className="dropdown-icon mdi">
                        <RiSettings3Fill />
                      </i>
                      Pengaturan
                    </Link>

                    <a
                      className="dropdown-item logout-text"
                      onClick={() => handleLogoutClick()}
                    >
                      <i className="dropdown-icon mdi">
                        <RiLogoutCircleRLine />
                      </i>
                      Keluar
                    </a>
                  </div>
                </div>
              </li>
            ) : (
              <Link to="/login" className="btn btn-primary">
                Login
              </Link>
            )}
          </ul>
        </div>
      </div>
    </StyledWrapperMain>
  );
}

export default Header;

const StyledWrapperMain = styled.section`
  svg {
    height: 32px;
    width: 32px;
  }
  .main-content {
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: #00000011;
  }
`;
