import React, { useEffect } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { FaCalendar } from "react-icons/fa";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Personal/notification.css";

function NotificationPage() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container className="dashboard_page__container">
      <Row>
        <Col lg={8}>
          <div>
            <h2 className="h2 dashboard__section_title mb-1">Notifikasi</h2>
            <div className="mb-3">3 Notifikasi Terbaru</div>
          </div>
        </Col>
        <Col lg={4}>
          <Form.Control className="py-0 style1-input " as="select">
            <option className="pl-1" value="ongoing">
              Semua Notifikasi
            </option>
            <option className="pl-1" value="todo">
              Pembelajaran Perusahaan
            </option>
            <option className="pl-1" value="completed">
              Pembelajaran Satukelas
            </option>
            <option className="pl-1" value="completed">
              Transaksi
            </option>
            <option className="pl-1" value="completed">
              Personal
            </option>
          </Form.Control>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="wrapper-notif">
            <div className="related_material__title"></div>
            {[...Array(3)].map((item, i) => {
              return (
                <Card key={i} className="mb-4">
                  <div className="wrapper-padding">
                    <div className="wrapper-image-text">
                      <div className="mr-3 d-flex align-items-center">
                        <div className="img-thumb mr-3"></div>
                        <div clas>
                          <div className="wrapper-subtitle ">
                            Tutorial Perpajakan Telah di Assign Ke Anda!
                          </div>
                          <div className="wrapper-subtitle__2 ">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Donec laoreet ante a sodales pulvinar.
                          </div>
                          <div className="d-flex mt-2">
                            <FaCalendar className="mr-2" />
                            <div className="deadline-subtitle">
                              Deadline 23 Januari 2021
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wrapper-info">Baru Saja</div>
                    </div>
                  </div>
                </Card>
              );
            })}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default NotificationPage;
