import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Card, Modal } from "react-bootstrap";
import Library from "./Library";
import { postAssignmentData } from "../redux/actions/AssignmentActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { AuthData } from "../helper/auth";
import "../assets/css/Courses/fileuploader.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FaFileAlt } from "react-icons/fa";
const MySwal = withReactContent(Swal);

function FileUploader() {
  const [fileInfo, setFileInfo] = useState();
  const [trigger, setTrigger] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [assignment, setAssignment] = useState([]);
  const [selectedLibary, setSelectedLibary] = useState();
  const { id_material } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const assignments = useSelector((state) => state.assignments);
  const users = useSelector((state) => state.users);

  useEffect(() => {
    if (assignments.assignmentDataId)
      setAssignment(assignments.assignmentDataId);

    if (assignments.assignmentDataPost === "error" && trigger) {
      setTrigger(false);
      displayPopup(true, "Terjadi masalah saat submit data", false);
    }
    if (assignments.assignmentDataPost === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil submit data", true, "Ok");
    }
  }, [assignments]);

  useEffect(() => {
    if (
      users.usersDataId.my_tasks?.filter(
        (e) =>
          e.assignment_id === id_material.split("_")[0] &&
          e.status === "completed"
      ).length > 0
    ) {
      setCompleted(true);
    } else setCompleted(false);
  }, [location, users]);

  const libraryHandler = (newValue) => {
    console.log(newValue, "aa");
    setSelectedLibary(newValue);
  };
  const handleClose = () => {
    setModalShow(false);
    setSelectedLibary("");
  };
  const handleThumbnail = () => {
    setFileInfo(selectedLibary);
    setModalShow(false);
  };
  const submitHandler = () => {
    if (fileInfo) {
      setTrigger(true);
      const payload = {
        assignment_id: id_material.split("_")[0],
        student_upload: fileInfo,
        user_id: AuthData().userId,
      };
      dispatch(postAssignmentData(payload));
    } else {
      displayPopup(false, "Tugas tidak boleh kosong!", true, "Ok");
    }
  };
  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {});
  }
  return (
    <div className="file-upload-wrapper">
      {assignment ? (
        <>
          <div className="file-upload-inner">
            <div className="file-upload-h1">Deskripsi</div>
            <div className="file-upload-h1">
              <div
                dangerouslySetInnerHTML={{
                  __html: assignment.description,
                }}
              />
            </div>
          </div>
          <div className="file-upload-inner">
            <div className="file-upload-h1">Attachment</div>
            <a href={assignment.tutor_upload}>
              <div className="related_file__item_wrapper assignment-file">
                <div className="related_file__icon_wrapper">
                  <FaFileAlt className="related_file__icon" />
                </div>
                {assignment.tutor_upload?.split(".com/")[1]}
                <div className="related_file__filename"></div>
              </div>
            </a>
          </div>
          <div className="file-upload-inner">
            <Row>
              <Col>
                <Card>
                  <div>
                    <div className="d-flex justify-content-between">
                      <div className="text-inside-upload wrapper">
                        <div className="title-poin">Poin Total</div>
                        <div className="subtitle-poin">
                          Poin dari assignment
                        </div>
                      </div>

                      <div className="wrapper-number ">
                        <div className="number-inside">
                          {assignment.total_points}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
              {/*
              <Col>
                <Card>
                  <div>
                    <div className="d-flex justify-content-between">
                      <div className="text-inside-upload wrapper">
                        <div className="title-poin">Durasi</div>

                        <div className="subtitle-poin">Durasi Assignment</div>
                      </div>
                      <div className="wrapper-number">
                        <div className="number-inside">
                          {assignment.duration}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>*/}
            </Row>
          </div>
          {completed ? (
            <div className="file-upload-inner">
              <div className="file-upload-h1">Attachment</div>
              <a href={assignment.student_upload}>
                <div className="related_file__item_wrapper assignment-file">
                  <div className="related_file__icon_wrapper">
                    <FaFileAlt className="related_file__icon" />
                  </div>
                  {assignment.student_upload?.split(".com/")[1]}
                  <div className="related_file__filename"></div>
                </div>
              </a>
            </div>
          ) : (
            <div className="file-upload-inner">
              <h1 className="file-upload-h1">Select File</h1>{" "}
              <Form.Control
                className="mb-3"
                type="text"
                id="thumbnail"
                value={fileInfo}
                placeholder="Masukkan Link Tugas Disini..."
                onClick={() => setModalShow(true)}
              />
              <Button onClick={submitHandler}>Kirim Tugas</Button>
            </div>
          )}
        </>
      ) : (
        ""
      )}
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        style={{ overflow: "auto" }}
      >
        <Modal.Header className="px-4">
          <Modal.Title id="contained-modal-title-vcenter">
            Pilih PDF
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Library libraryHandler={libraryHandler} />
        </Modal.Body>
        <Modal.Footer className="px-4">
          <Button variant="danger" onClick={handleClose}>
            Batal
          </Button>
          <Button variant="primary" onClick={handleThumbnail}>
            Pilih
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default FileUploader;
