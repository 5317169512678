import React from "react";
import { Form, Card, Button } from "react-bootstrap";
import "../assets/css/Courses/courseSearch.css";

function FilterCard({
  usersData,
  courseTypesData,
  setInstructorFilter,
  setCourseTypesFilter,
  instructorFilter,
  courseTypesFilter,
  priceHighest,
  priceLowest,
  priceChangeHandler,
  searchBtnHandler,
}) {
  // if (coursesData.items) {
  //   addData(coursesData.items, instructors, "instructors");
  //   addData(coursesData.items, categories, "course_type");
  // }
  // instructors = Array.from(new Set(instructors));
  // categories = Array.from(new Set(categories));

  function radioHandleChange(type, newValue) {
    switch (type) {
      case "instructor":
        setInstructorFilter(newValue);
        break;
      case "course_type":
        setCourseTypesFilter(newValue);
        break;
      default:
        break;
    }
    // console.log(type, newValue);
  }
  return (
    <>
      <Card className="course_search__filter_container">
        <h3 className="course_search__flter_by h3">Filter Pencarian</h3>
        <h4 className="course_search__category_title h4">Instruktur</h4>
        <div className="course_search__category_wrapper">
          <Form.Check
            type="radio"
            name="group1"
            id={"semua"}
            label={"Semua"}
            checked={instructorFilter === "Semua"}
            onChange={() => radioHandleChange("instructor", "Semua")}
          />
          {usersData.items
            ? usersData.items.map((item, i) => {
                return (
                  <>
                    {item.role.toLowerCase() === "instructor" ? (
                      <Form.Check
                        key={item.user_id}
                        type="radio"
                        name="group1"
                        id={item.user_id}
                        label={item.name}
                        checked={instructorFilter === item.user_id}
                        onChange={() =>
                          radioHandleChange("instructor", item.user_id)
                        }
                      />
                    ) : null}
                  </>
                );
              })
            : null}
        </div>
        <h4 className="course_search__category_title h4">Tipe Pelatihan</h4>
        <div className="course_search__category_wrapper">
          <Form.Check
            type="radio"
            name="group2"
            id={"semua"}
            label={"Semua"}
            checked={courseTypesFilter === "Semua"}
            onChange={() => radioHandleChange("course_type", "Semua")}
          />
          {courseTypesData.items
            ? courseTypesData.items.map((item, i) => {
                return (
                  <Form.Check
                    key={item.type_id}
                    type="radio"
                    name="group2"
                    id={item.type_id}
                    label={item.title}
                    checked={courseTypesFilter === item.title}
                    onChange={() =>
                      radioHandleChange("course_type", item.title)
                    }
                  />
                );
              })
            : null}
        </div>
        <h4 className="course_search__category_title h4">Harga</h4>
        <div className="course_search__category_wrapper">
          <Form>
            <Form.Group
              controlId="formBasicRangeMin"
              className="d-flex align-items-end"
            >
              <h6 className="filter-text mr-2 mb-0">L</h6>
              <Form.Control
                type="range"
                min={0}
                max={5000000}
                step={100000}
                value={priceLowest}
                onChange={(e) => priceChangeHandler("top", e.target.value)}
              />
            </Form.Group>
            <Form.Group
              controlId="formBasicRangeMax"
              className="d-flex align-items-end"
            >
              <h6 className="filter-text mr-2 mb-0">H</h6>
              <Form.Control
                type="range"
                min={0}
                max={5000000}
                step={100000}
                value={priceHighest}
                onChange={(e) => priceChangeHandler("bot", e.target.value)}
              />
            </Form.Group>
            <h6 className="filter-info mr-2 mb-0 text-center">
              {priceLowest > 0 ? nFormatter(priceLowest, 1) : "Gratis"}-
              {priceHighest > 0 ? nFormatter(priceHighest, 1) : "Gratis"}
            </h6>
          </Form>
        </div>
        <Button className="filter_button mt-2" onClick={searchBtnHandler}>
          {" "}
          Cari
        </Button>
      </Card>
    </>
  );
}

export default FilterCard;

// function addData(data, newData, key) {
//   for (let i = 0; i < data.length; i++) {
//     const currInstructor = [].concat.apply([], data[i][key].split(";"));
//     for (let j = 0; j < currInstructor.length; j++) {
//       newData.push(currInstructor[j]);
//     }
//   }
// }

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "rb" },
    { value: 1e6, symbol: "jt" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}
