import {
  ASSETS_DATA,
  ASSETS_DATA_POST,
  ASSETS_DATA_SUCCESS,
  ASSETS_DATA_POST_SUCCESS,
  ASSETS_DATA_FAIL,
} from "../config/AssetsConfig";

export const getAssetsData = (value) => ({
  type: ASSETS_DATA,
  value,
});
export const postAssetsData = (value) => ({
  type: ASSETS_DATA_POST,
  value,
});
export const getAssetsDataSuccess = (value) => ({
  type: ASSETS_DATA_SUCCESS,
  value,
});
export const postAssetsDataSuccess = (value) => ({
  type: ASSETS_DATA_POST_SUCCESS,
  value,
});
export const getAssetsDataFail = (value) => ({
  type: ASSETS_DATA_FAIL,
  value,
});
