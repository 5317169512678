import React from "react";
import { ListGroup } from "react-bootstrap";
import { FaUserFriends } from "react-icons/fa";
function CardAudience({ audience }) {
  return (
    <>
      <h2 className="course_front__kurikulum_title h2">Target Audiens</h2>
      <div className="course_front__target_audience__wrapper">
        <ListGroup>
          {audience &&
            audience.map((listing, index) => (
              <ListGroup.Item className="d-flex" key={index}>
                <FaUserFriends className="course_front__target_audience_icon mt-0" />
                <div className="course_front__target_audience">{listing}</div>
              </ListGroup.Item>
            ))}
        </ListGroup>
      </div>
    </>
  );
}

export default CardAudience;
