import Routes from "./helper/routes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import ReduxStore from "./redux/store";
import React, { useEffect } from "react";
import ReactGA from 'react-ga';

window.OneSignal = window.OneSignal || [];
const OneSignal = window.OneSignal;

// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
ReactGA.initialize('UA-208623727-1')

function App() {

  useEffect(() => {
    OneSignal.push(() => {
      OneSignal.init({
        // appId: process.env.REACT_APP_ONESIGNAL_ID
        appId: '20d2e7db-c434-44cf-85cc-2c54b1b98a32'
      });
      OneSignal.on('notificationDisplay', function (e) {
        console.log(e)
      });
    });
  }, []);

  return (
    <Provider store={ReduxStore.store}>
      <PersistGate loading={null} persistor={ReduxStore.persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  );
}

export default App;
