import {
  //get
  QUIZ_DATA,
  QUIZ_DATA_ID_SUCCESS,
  QUIZ_DATA_FAIL,
  //start
  QUIZ_START,
  QUIZ_START_SUCCESS,
  QUIZ_START_FAIL,
  //attempt
  QUIZ_ATTEMPT,
  QUIZ_ATTEMPT_SUCCESS,
  QUIZ_ATTEMPT_FAIL,
  //score
  QUIZ_SCORE,
  QUIZ_SCORE_SUCCESS,
  QUIZ_SCORE_FAIL,
  //remove
  QUIZ_REMOVE_ATTEMPT_SCORE,
} from "../config/QuizConfig";

const intialState = {
  quizDataId: [],
  quizStartData: [],
  quizAttemptData: [],
  quizScoreData: [],
  isLoading: false,
};

export const QuizReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case QUIZ_DATA:
      return { ...state, isLoading: true };
    case QUIZ_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, quizDataId: action.value };
    case QUIZ_DATA_FAIL:
      return { ...state, isLoading: false };
    //start
    case QUIZ_START:
      return { ...state, isLoading: true };
    case QUIZ_START_SUCCESS:
      return { ...state, isLoading: false, quizStartData: action.value };
    case QUIZ_START_FAIL:
      return { ...state, isLoading: false };
    //attempt
    case QUIZ_ATTEMPT:
      return { ...state, isLoading: true };
    case QUIZ_ATTEMPT_SUCCESS:
      return { ...state, isLoading: false, quizAttemptData: action.value };
    case QUIZ_ATTEMPT_FAIL:
      return { ...state, isLoading: false };
    //score
    case QUIZ_SCORE:
      return { ...state, isLoading: true };
    case QUIZ_SCORE_SUCCESS:
      return { ...state, isLoading: false, quizScoreData: action.value };
    case QUIZ_SCORE_FAIL:
      return { ...state, isLoading: false };
    //remove
    case QUIZ_REMOVE_ATTEMPT_SCORE:
      return { ...state, quizScoreData: [], quizAttemptData: [] };
    default:
      return state;
  }
};
