import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Button, Container } from "react-bootstrap";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "../assets/css/Courses/pdfloader.css";

function PDFLoader({ url }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onClickButton(param) {
    if (param === "Prev") {
      let newPageNumber = pageNumber;
      if (newPageNumber <= 1) newPageNumber = numPages;
      else newPageNumber--;
      setPageNumber(newPageNumber);
    } else if (param === "Next") {
      let newPageNumber = pageNumber;
      if (newPageNumber >= numPages) newPageNumber = 1;
      else newPageNumber++;
      setPageNumber(newPageNumber);
    }
  }

  return (
    <Container className="pdf-main-div lesson-container">
      <Document file={{ url: url }} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          pageNumber={pageNumber}
          renderMode="canvas"
          renderAnnotationLayer={false}
        />
      </Document>
      <div className="pdf-control__wrapper">
        <div>
          <Button className="pdf-btn" onClick={() => onClickButton("Prev")}>
            {`<`}
          </Button>
          <Button
            className="pdf-btn next"
            onClick={() => onClickButton("Next")}
          >
            {`>`}
          </Button>
        </div>
        <div className="text-center pdf-number">
          Halaman {pageNumber}/{numPages}
        </div>
      </div>
    </Container>
  );
}
export default PDFLoader;
