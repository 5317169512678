import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
// import CcUserProfile from "./ccUserProfile";
// import CcDialogs from "./ccDialogs";
import { FaUserAlt } from "react-icons/fa";
import moment from "moment";

function CcChat({ callbackSend, isError, messages, user }) {
  const [message, setMessage] = useState('')
  const handleSubmit = (e) => {
    e.preventDefault();
    callbackSend(message);
    setMessage('')
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setMessage(value);
  };

  const isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() === today.getDate() && someDate.getMonth() === today.getMonth() && someDate.getFullYear() === today.getFullYear()
  }

  return (
    <>
      <Row>
        <Col sm={12}>
          <div className="chat-container" >
            <div className="header">
              <div style={{ width: "50px", height: "50px" }}>
                <div className="avatar-as-icon" style={{ backgroundColor: "limeGreen" }}>
                  <FaUserAlt />
                </div>
              </div>
              <h3>Admin PT Hello World!</h3>
            </div>
            {isError
              ? <div className="chat-body"> {isError} </div>
              :
              <>
                <div className="chat-body" id="chat-body">
                  <div className="w-100 content">
                    {messages.length > 0 && messages.map((e, i) => {
                      let status = (user.userId === e.sender_id) ? 'right' : 'left';
                      let timestamp = new Date(e.date_sent * 1000);
                      let format = isToday(timestamp) ? "HH:mm" : "YYYY/MM/DD HH:mm";

                      return (
                        <div className="chat-message-layout" key={i}>
                          <div className={`chat-message-wrap chat-message-wrap-${status}`}>
                            {/* <div className="chat-message-avatar">
                                                            <div className="avatar-as-icon">
                                                                <FaUserAlt />
                                                            </div>
                                                        </div> */}
                            <div className={`chat-message-container-position-${status}`}>
                              <span>{e.message}</span>
                              <div className="chat-message-right-footer">
                                <span>{moment.unix(e.date_sent).format(format)}
                                  {/* <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-double" className="svg-inline--fa fa-check-double fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" color="#27ae60">
                                                                        <path fill="currentColor" d="M505 174.8l-39.6-39.6c-9.4-9.4-24.6-9.4-33.9 0L192 374.7 80.6 263.2c-9.4-9.4-24.6-9.4-33.9 0L7 302.9c-9.4 9.4-9.4 24.6 0 34L175 505c9.4 9.4 24.6 9.4 33.9 0l296-296.2c9.4-9.5 9.4-24.7.1-34zm-324.3 106c6.2 6.3 16.4 6.3 22.6 0l208-208.2c6.2-6.3 6.2-16.4 0-22.6L366.1 4.7c-6.2-6.3-16.4-6.3-22.6 0L192 156.2l-55.4-55.5c-6.2-6.3-16.4-6.3-22.6 0L68.7 146c-6.2 6.3-6.2 16.4 0 22.6l112 112.2z"></path>
                                                                    </svg> */}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="chat-footer">
                  <form onSubmit={handleSubmit}>
                    <input type="text" placeholder="Write your message..." name="search" value={message} onChange={handleChange} />
                    <button>
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="paper-plane" className="svg-inline--fa fa-paper-plane fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" color="white">
                        <path fill="currentColor" d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"></path>
                      </svg>
                    </button>
                  </form>
                </div>
              </>
            }
          </div>
        </Col>
      </Row>
    </>
  );
}

export default CcChat;