import { call, put, takeEvery } from "redux-saga/effects";
import { PromiseService } from "../../services/PromiseService";
import {
  PAYMENTS_DATA_POST,
  PAYMENTS_DATA_LINK,
} from "../config/PaymentsConfig";
import {
  postPaymentsDataSuccess,
  postPaymentsDataFail,
} from "../actions/PaymentsActions";

function* workerPaymentsDataPost(params) {
  const body = params.value;
  try {
    const response = yield call(
      PromiseService.postData,
      PAYMENTS_DATA_LINK,
      body
    );
    if (response.status === 200 && response.data) {
      yield put(postPaymentsDataSuccess(response.data));
    } else {
      yield put(postPaymentsDataFail(response));
    }
  } catch (error) {
    yield put(postPaymentsDataFail(error));
  }
}

export const watcherPayments = [
  takeEvery(PAYMENTS_DATA_POST, workerPaymentsDataPost),
];
