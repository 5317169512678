import {
  LEARNING_PATHS_DATA,
  LEARNING_PATHS_DATA_SUCCESS,
  LEARNING_PATHS_DATA_ID_SUCCESS,
  LEARNING_PATHS_DATA_FAIL,
} from "../config/LearningPathsConfig";

export const getLearningPathsData = (value) => ({
  type: LEARNING_PATHS_DATA,
  value,
});
export const getLearningPathsDataSuccess = (value) => ({
  type: LEARNING_PATHS_DATA_SUCCESS,
  value,
});
export const getLearningPathsDataIdSuccess = (value) => ({
  type: LEARNING_PATHS_DATA_ID_SUCCESS,
  value,
});
export const getLearningPathsDataFail = (value) => ({
  type: LEARNING_PATHS_DATA_FAIL,
  value,
});
