import {
  LESSONS_DATA,
  LESSONS_DATA_ID_SUCCESS,
  LESSONS_DATA_FAIL,
} from "../config/LessonsConfig";

const intialState = {
  lessonsDataId: [],
  isLoading: false,
};

export const LessonsReducers = function (state = intialState, action) {
  switch (action.type) {
    case LESSONS_DATA:
      return { ...state, isLoading: true };
    case LESSONS_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, lessonsDataId: action.value };
    case LESSONS_DATA_FAIL:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
