import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Template from "../components/Layout/Template";
import Login from "../pages/Account/Login";
import Register from "../pages/Account/Register";
import EmailVerified from "../pages/Account/EmailVerified";
import ForgotPassword from "../pages/Account/ForgotPassword";
import NewPassword from "../pages/Account/NewPassword";
import CourseDetail from "../pages/Courses/CourseDetail";
import HomePage from "../pages/Home/HomePage";
import SinglePageWebinar from "../pages/Courses/SinglePageWebinar";
import CourseFront from "../pages/Courses/CourseFront";
import WebinarSearch from "../pages/Courses/WebinarSearch";
import JamBoardDetail from "../pages/Courses/JamBoardDetail";
import CourseSearch from "../pages/Courses/CourseSearch";
import Cart from "../pages/Transaction/Cart";
import Checkout from "../pages/Transaction/Checkout";
import PaymentStatus from "../pages/Transaction/PaymentStatus";
import Webstore from "../pages/Transaction/Webstore";
import Membership from "../pages/Personal/Membership";
import MyCalendar from "../pages/Personal/MyCalendar";
import MyCert from "../pages/Personal/MyCert";
import MyCourse from "../pages/Personal/MyCourse";
import MyPath from "../pages/Personal/MyPath";
import MyPoint from "../pages/Personal/MyPoint";
import MyTask from "../pages/Personal/MyTask";
import Overview from "../pages/Personal/Overview";
import Profile from "../pages/Personal/Profile";
import Setting from "../pages/Personal/Setting";
import ConnectyCubeChat from "../pages/Personal/ConnectyCubeChat";
import ProgressPage from "../pages/Personal/ProgressPage";
import Peraturan from "../pages/Peraturan/Peraturan";
import TutorialModal from "../pages/Tutorial/TutorialModal";
import LeaderboardUser from "../pages/Leaderboard/LeaderboardUser";
import PeraturanDetail from "../pages/Peraturan/PeraturanDetail";
import NotificationPage from "../pages/Personal/NotificationPage";
import NotFound from "../pages/Error/NotFound";
import ScrollToTop from "./ScrollToTop";
import LearningPathSingle from "../pages/LearningPath/LearningPathSingle";
import MyCompanyProfil from "../pages/MyCompany/MyCompanyProfil";
import MyBookmark from "../pages/Personal/MyBookmark";
import MembershipLanding from "../pages/Home/MembershipLanding";

export default function Routes() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        {/* home page */}
        <Route exact path={["/", "/home/"]}>
          <Template
            isSidebar={false}
            isHeader={true}
            InnerComponent={HomePage}
          />
        </Route>
        {/* dashboard starts */}
        <Route exact path={["/dashboard/"]}>
          <Template isSidebar={true} InnerComponent={Overview} />
        </Route>
        <Route exact path={["/dashboard/membership/"]}>
          <Template isSidebar={true} InnerComponent={Membership} />
        </Route>
        <Route exact path={["/dashboard/calendar/"]}>
          <Template isSidebar={true} InnerComponent={MyCalendar} />
        </Route>
        <Route exact path={["/dashboard/certificate/"]}>
          <Template isSidebar={true} InnerComponent={MyCert} />
        </Route>
        <Route exact path={["/dashboard/course/"]}>
          <Template isSidebar={true} InnerComponent={MyCourse} />
        </Route>
        <Route exact path={["/dashboard/my-bookmark"]}>
          <Template isSidebar={true} InnerComponent={MyBookmark} />
        </Route>
        <Route exact path={["/dashboard/path/"]}>
          <Template isSidebar={true} InnerComponent={MyPath} />
        </Route>
        <Route exact path={["/dashboard/point/"]}>
          <Template isSidebar={true} InnerComponent={MyPoint} />
        </Route>
        <Route exact path={["/dashboard/leaderboard"]}>
          <Template isSidebar={true} InnerComponent={LeaderboardUser} />
        </Route>
        <Route exact path={["/dashboard/task/"]}>
          <Template isSidebar={true} InnerComponent={MyTask} />
        </Route>
        <Route exact path={["/tutorial/"]}>
          <Template isSidebar={true} InnerComponent={TutorialModal} />
        </Route>
        <Route exact path={["/dashboard/profile/"]}>
          <Template isSidebar={true} InnerComponent={Profile} />
        </Route>
        <Route exact path={["/dashboard/progress/"]}>
          <Template isSidebar={true} InnerComponent={ProgressPage} />
        </Route>
        <Route exact path={["/jamboard/"]}>
          <Template isSidebar={true} InnerComponent={JamBoardDetail} />
        </Route>
        <Route exact path={["/dashboard/settings/"]}>
          <Template isSidebar={true} InnerComponent={Setting} />
        </Route>
        <Route exact path={["/dashboard/notification/"]}>
          <Template isSidebar={true} InnerComponent={NotificationPage} />
        </Route>
        <Route exact path={["/dashboard/orders/"]}>
          <Template isSidebar={true} InnerComponent={PaymentStatus} />
        </Route>
        {/* dashboard ends */}
        {/* auth starts */}
        <Route exact path={["/login/"]}>
          <Template isHeader={false} InnerComponent={Login} />
        </Route>

        <Route exact path={["/register/"]}>
          <Template isHeader={false} InnerComponent={Register} />
        </Route>
        <Route exact path={["/email-verified/"]}>
          <Template isHeader={false} InnerComponent={EmailVerified} />
        </Route>
        <Route exact path={["/forgot-password/"]}>
          <Template isHeader={false} InnerComponent={ForgotPassword} />
        </Route>
        <Route exact path={["/new-password/"]}>
          <Template isHeader={false} InnerComponent={NewPassword} />
        </Route>
        {/* auth ends */}
        {/* class starts */}
        <Route exact path={["/search"]}>
          <Redirect to="/search/courses/" />
        </Route>
        <Route
          exact
          path={["/search/:data_type/", "/search/:data_type/:filter_param/"]}
        >
          <Template
            isSidebar={false}
            isHeader={true}
            InnerComponent={CourseSearch}
          />
        </Route>
        <Route exact path={["/webinar"]}>
          <Template
            isSidebar={false}
            isHeader={true}
            InnerComponent={WebinarSearch}
          />
        </Route>
        <Route exact path={["/course/:course_id/"]}>
          <Template
            isSidebar={false}
            isHeader={true}
            InnerComponent={CourseFront}
          />
        </Route>

        <Route
          exact
          path={[
            "/course/:course_id/:material_type(lessons|webinars|assignments|quiz)/:id_material/",
          ]}
        >
          <Template
            isSidebar={false}
            isHeader={true}
            InnerComponent={CourseDetail}
          />
        </Route>
        <Route exact path={["/learning-path/"]}>
          <Template
            isSidebar={false}
            isHeader={true}
            InnerComponent={LearningPathSingle}
          />
        </Route>
        <Route exact path={["/webinar-single/"]}>
          <Template
            isSidebar={false}
            isHeader={true}
            InnerComponent={SinglePageWebinar}
          />
        </Route>
        <Route exact path={["/membership/"]}>
          <Template
            isSidebar={false}
            isHeader={true}
            InnerComponent={MembershipLanding}
          />
        </Route>
        {/* class ends  */}
        <Route exact path={["/cart/"]}>
          <Template isSidebar={true} InnerComponent={Cart} />
        </Route>
        <Route exact path={["/company-profile/"]}>
          <Template
            isSidebar={false}
            isHeader={true}
            InnerComponent={MyCompanyProfil}
          />
        </Route>
        {/* <Route exact path={["/webinar-detail/"]}>
          <Template isSidebar={false} isHeader={true} InnerComponent={WebinarDetail} />
        </Route> */}
        <Route exact path={["/checkout/"]}>
          <Template isSidebar={true} InnerComponent={Checkout} />
        </Route>
        <Route exact path={["/store/"]}>
          <Template isSidebar={true} InnerComponent={Webstore} />
        </Route>
        {/* peraturan */}
        <Route exact path={["/peraturan/"]}>
          <Template isSidebar={true} InnerComponent={Peraturan} />
        </Route>
        <Route exact path={["/peraturan/detail"]}>
          <Template isSidebar={true} InnerComponent={PeraturanDetail} />
        </Route>
        <Route exact path={["/dashboard/chat/"]}>
          <Template isSidebar={true} InnerComponent={ConnectyCubeChat} />
        </Route>
        <Route>
          <Template
            isSidebar={false}
            isHeader={true}
            InnerComponent={NotFound}
          />
        </Route>
      </Switch>
    </Router>
  );
}
