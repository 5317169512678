import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  postPaymentsData,
  removePostPaymentsData,
} from "../redux/actions/PaymentsActions";
import { AuthData } from "../helper/auth";
import { FormatCurrency } from "../helper/functions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function Topup({ setTopup }) {
  const dispatch = useDispatch();
  const payments = useSelector((state) => state.payments);

  useEffect(() => {
    if (payments.paymentsDataPost?.order_id) {
      displayPopup(
        false,
        `Silahkan Transfer ${FormatCurrency(
          parseInt(payments.paymentsDataPost.gross_amount).toFixed(0)
        )} Ke Virtual Account <b>${payments.paymentsDataPost.va_number}</b>`,
        true,
        "Oke"
      );
    }
    if (payments.paymentsDataPost === "fail") {
      displayPopup(
        false,
        `Gagal Topup. Silahkan coba lagi setelah beberapa saat!`,
        true,
        "Oke"
      );
    }
  }, [payments]);
  const [data, setData] = useState({
    bank: "bni",
    gross_amount: "20000",
    payment_type: "bank_transfer",
  });
  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  };
  const topupHandler = () => {
    const payload = { ...data };
    payload.user_id = AuthData().userId;
    payload.gross_amount = parseInt(data.gross_amount);
    dispatch(postPaymentsData(payload));
  };

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      setTopup(false);
      dispatch(removePostPaymentsData());
    });
  }

  return (
    <div className="modal-topup__container">
      <Form>
        <Form.Group className="modal-topup__section">
          <Form.Label className="modal-topup__title">
            Pilih Nominal Topup
          </Form.Label>
          <div className="modal-topup__selection">
            <Form.Check
              type="radio"
              id="1"
              value="20000"
              name="gross_amount"
              label="Rp. 20.000"
              onChange={handleChange}
              defaultChecked
            />
            <Form.Check
              type="radio"
              id="2"
              value="50000"
              name="gross_amount"
              label="Rp. 50.000"
              onChange={handleChange}
            />
            <Form.Check
              type="radio"
              id="3"
              name="gross_amount"
              value="100000"
              label="Rp. 100.000"
              onChange={handleChange}
            />
            <Form.Check
              type="radio"
              id="4"
              value="200000"
              name="gross_amount"
              label="Rp. 200.000"
              onChange={handleChange}
            />
            <Form.Check
              id="5"
              type="radio"
              name="gross_amount"
              value="300000"
              label="Rp. 300.000"
              onChange={handleChange}
            />
            <Form.Check
              id="6"
              type="radio"
              name="gross_amount"
              value="500000"
              label="Rp. 500.000"
              onChange={handleChange}
            />
          </div>
          {/* 
          <div className="modal-topup__input">
            <Form.Label className="modal-topup__title">
              Pilih Nominal Topup
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Masukkan Jumlah Topup"
              name="gross_amount"
              value={data.gross_amount}
            />
          </div>*/}
        </Form.Group>
        <Form.Group className="modal-topup__section">
          <Form.Label className="modal-topup__title">
            Pilih Metode Pembayaran
          </Form.Label>
          <Form.Check
            id="7"
            type="radio"
            value="bank_transfer"
            name="payment_type"
            className="modal-topup__input"
            label="Transfer Bank"
            onChange={handleChange}
            defaultChecked
          />
        </Form.Group>
        <Form.Group className="modal-topup__section mb-5">
          <Form.Label className="modal-topup__title">
            Pilih Jenis Bank
          </Form.Label>
          <Form.Check
            id="8"
            type="radio"
            name="bank"
            value="bni"
            className="modal-topup__input"
            label="Bank BNI"
            onChange={handleChange}
            defaultChecked
          />
          <Form.Check
            id="9"
            type="radio"
            name="bank"
            value="bca"
            className="modal-topup__input"
            label="Bank BCA"
            onChange={handleChange}
          />
          <Form.Check
            id="10"
            type="radio"
            name="bank"
            value="bri"
            className="modal-topup__input"
            label="Bank BRI"
            onChange={handleChange}
          />
        </Form.Group>
        <div className="modal-topup__container-button">
          <Button
            className="modal-topup__button-cancel"
            onClick={() => setTopup(false)}
          >
            Batal
          </Button>
          <Button className="modal-topup__button" onClick={topupHandler}>
            Isi Saldo
          </Button>
        </div>
      </Form>
    </div>
  );
}
export default Topup;
