import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { TiMicrophoneOutline } from "react-icons/ti";
import { Button, Image, FormGroup, FormControl, Figure } from "react-bootstrap";
function CardChat() {
  return (
    <>
      <div className="d-none card w-100  chat-body p-0 border-0 shadow-xss rounded-lg mb-3 position-relative">
        <div className="messages-content scroll-bar p-3">
          <div className="message-item">
            <div className="message-user">
              <Figure className="avatar">
                <Image
                  src="http://uitheme.net/elomoas/images/user-9.png"
                  alt="image"
                />
              </Figure>
              <div>
                <h5 className="chat-font__size mt-2">Byrom Guittet</h5>
                <div className="time">01:35 PM</div>
              </div>
            </div>
            <div className="message-wrap shadow-none">Baik, gimana kabarnya? 😃</div>
          </div>

          <div className="message-item">
            <div className="message-user">
              <Figure className="avatar">
                <Image
                  src="http://uitheme.net/elomoas/images/user-1.png"
                  alt="image"
                />
              </Figure>
              <div>
                <h5 className="chat-font__size mt-2">Byrom Guittet</h5>
                <div className="time">
                  01:35 PM<i className="ti-double-check text-info"></i>
                </div>
              </div>
            </div>
            <div className="message-wrap shadow-none">
              Saya mau berkas dari anda. Saya mau anda mengirimkan 1 PDF dan 1 gambar

            </div>
          </div>

          <div className="message-item">
            <div className="message-user">
              <Figure className="avatar">
                <Image
                  src="http://uitheme.net/elomoas/images/user-9.png"
                  alt="image"
                />
              </Figure>
              <div>
                <h5 className="chat-font__size mt-2">Byrom Guittet</h5>
                <div className="time">01:35 PM</div>
              </div>
            </div>
            <div className="message-wrap shadow-none">
              Saya baru menemukan foto keren untuk aplikasi travel kita.
            </div>
          </div>

          <div className="message-item outgoing-message">
            <div className="message-user">
              <Figure className="avatar">
                <Image
                  src="http://uitheme.net/elomoas/images/user-1.png"
                  alt="image"
                />
              </Figure>
              <div>
                <h5>You</h5>
                <div className="time">
                  01:35 PM<i className="ti-double-check text-info"></i>
                </div>
              </div>
            </div>
            <div className="message-wrap">
              Hey ! Apa yang sedang dikerjakan ?</div>
          </div>

          <div className="message-item">
            <div className="message-user">
              <Figure className="avatar">
                <Image
                  src="http://uitheme.net/elomoas/images/user-9.png"
                  alt="image"
                />
              </Figure>
              <div>
                <h5 className="chat-font__size mt-2">Byrom Guittet</h5>
                <div className="time">01:35 PM</div>
              </div>
            </div>
            <div className="message-wrap shadow-none">Baik, gimana kabarnya? 😃</div>
          </div>

          <div className="message-item">
            <div className="message-user">
              <Figure className="avatar">
                <Image
                  src="http://uitheme.net/elomoas/images/user-1.png"
                  alt="image"
                />
              </Figure>
              <div>
                <h5 className="chat-font__size mt-2">Byrom Guittet</h5>
                <div className="time">
                  01:35 PM<i className="ti-double-check text-info"></i>
                </div>
              </div>
            </div>
            <div className="message-wrap shadow-none">
              
            Saya mau berkas dari anda. Saya mau anda mengirimkan 1 PDF dan 1 gambar

            </div>
          </div>

          <div className="message-item">
            <div className="message-user">
              <Figure className="avatar">
                <Image
                  src="http://uitheme.net/elomoas/images/user-9.png"
                  alt="image"
                />
              </Figure>

              <div>
                <h5 className="chat-font__size mt-2">Byrom Guittet</h5>
                <div className="time">01:35 PM</div>
              </div>
            </div>
            <div className="message-wrap shadow-none">
              Saya baru menemukan foto keren untuk aplikasi travel kita.
            </div>
          </div>
        </div>
        <div className="form chat-form position-absolute bottom-0 w-100 left-0 bg-white z-index-1 p-3 shadow-xs theme-dark-bg ">
          <Button className="bg-grey float-left">
            <TiMicrophoneOutline className="text-white" />
          </Button>
          <FormGroup>
            <FormControl
              placeholder="Recipient's username"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
          </FormGroup>
          <Button className="bg-current">
            <FaArrowRight className="text-white" />
          </Button>
        </div>
      </div>

    </>
  );
}
export default CardChat;
