import {
    TOPIC_DATA,
  TOPIC_DATA_SUCCESS,
  TOPIC_DATA_ID_SUCCESS,
  TOPIC_DATA_PUT,
  TOPIC_DATA_PUT_SUCCESS,
  TOPIC_DATA_FAIL_PUT,
  TOPIC_DATA_POST,
  TOPIC_DATA_POST_SUCCESS,
  TOPIC_DATA_DELETE,
  TOPIC_DATA_DELETE_SUCCESS,
  TOPIC_DATA_FAIL,
} from "../config/TopicConfig";

export const getTopicData = (value) => ({
    type: TOPIC_DATA,
    value,
  });
  export const postTopicData = (value) => ({
    type: TOPIC_DATA_POST,
    value,
  });
  export const postTopicDataSuccess = (value) => ({
    type: TOPIC_DATA_POST_SUCCESS,
    value,
  });
  export const putTopicData = (value) => ({
    type: TOPIC_DATA_PUT,
    value,
  });
  export const putTopicDataSuccess = (value) => ({
    type: TOPIC_DATA_PUT_SUCCESS,
    value,
  });
  export const deleteTopicData = (value) => ({
    type: TOPIC_DATA_DELETE,
    value,
  });
  export const deleteTopicDataSuccess = (value) => ({
    type: TOPIC_DATA_DELETE_SUCCESS,
    value,
  });
  export const getTopicDataSuccess = (value) => ({
    type: TOPIC_DATA_SUCCESS,
    value,
  });
  export const callTopicDataFail = (value) => ({
    type: TOPIC_DATA_FAIL,
    value,
  });
  export const callTopicDataFailPut = (value) => ({
    type: TOPIC_DATA_FAIL_PUT,
    value,
  });
  export const getTopicDataIdSuccess = (value) => ({
    type: TOPIC_DATA_ID_SUCCESS,
    value,
  });