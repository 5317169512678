import React, { useState, useEffect } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import "../../assets/css/Courses/index.css";
import CourseCard from "../../components/CourseCard";
import SearchBoxMain from "../../components/SearchBoxMain";
import FilterCard from "../../components/FilterCard";
import LearningPathCard from "../../components/LearningPathCard";
import styled from "styled-components";

import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCoursesData } from "../../redux/actions/CoursesActions";
import { getLearningPathsData } from "../../redux/actions/LearningPathsActions";
import { getUsersData } from "../../redux/actions/UsersActions";
import { getCourseTypesData } from "../../redux/actions/CourseTypesActions";
import ReactGA from "react-ga";

function CourseSearch() {
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState("");
  const [dataType, setDataType] = useState("courses");
  const [keywords, setKeywords] = useState("");
  const [instructorFilter, setInstructorFilter] = useState("All");
  const [courseTypesFilter, setCourseTypesFilter] = useState("All");
  const [priceLowest, setPriceLowest] = useState(0);
  const [priceHighest, setPriceHighest] = useState(0);
  const { data_type, filter_param } = useParams();
  const history = useHistory();
  const handleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    dispatch(getUsersData());
    dispatch(getCourseTypesData());
    setDataType(data_type);
    if (filter_param) {
      const current = getCurrentState(filter_param);
      const newFilter = "?" + filter_param;
      setFilter(newFilter);
      if (current.category) setCourseTypesFilter(current.category);
      if (current.instructor_id) setInstructorFilter(current.instructor_id);
      if (current.price_lowest) setPriceLowest(current.price_lowest);
      if (current.price_highest) setPriceHighest(current.price_highest);
      // if (data_type === "courses")
      //   dispatch(getCoursesData({ type: "filter", param: newFilter }));
      // if (data_type === "learning_paths")
      //   dispatch(getLearningPathsData({ type: "filter", param: "" }));
    } else {
      if (data_type === "courses")
        dispatch(getCoursesData({ type: "filter", param: filter }));
      if (data_type === "learning_paths")
        dispatch(getLearningPathsData({ type: "filter", param: "" }));
    }
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    if (dataType === "courses")
      dispatch(getCoursesData({ type: "filter", param: filter }));
    if (dataType === "learning_paths")
      dispatch(getLearningPathsData({ type: "filter", param: "" }));
  }, [filter]);

  function dataTypeHandler(e) {
    const newDataType = e.target.value;
    setDataType(newDataType);
    setCourseTypesFilter("All");
    setInstructorFilter("All");
    setPriceLowest(0);
    setPriceHighest(0);
    history.push(`/search/${newDataType}/`);
    if (newDataType === "courses")
      dispatch(getCoursesData({ type: "filter", param: "" }));
    if (newDataType === "learning_paths")
      dispatch(getLearningPathsData({ type: "filter", param: "" }));
  }

  function priceChangeHandler(pos, newValue) {
    if (pos === "top" && parseInt(newValue) <= parseInt(priceHighest)) {
      setPriceLowest(newValue);
    }
    if (pos === "bot" && parseInt(newValue) >= parseInt(priceLowest)) {
      setPriceHighest(newValue);
    }
  }

  function searchBtnHandler() {
    let counter = 0;
    let newFilter = "";
    if (instructorFilter !== "All") {
      counter++;
      newFilter += filterTemplate(counter, `instructor_id=${instructorFilter}`);
    }
    if (courseTypesFilter !== "All") {
      counter++;
      newFilter += filterTemplate(counter, `category=${courseTypesFilter}`);
    }
    if (priceLowest !== 0) {
      counter++;
      newFilter += filterTemplate(counter, `price_lowest=${priceLowest}`);
    }
    if (priceHighest !== 0) {
      counter++;
      newFilter += filterTemplate(counter, `price_highest=${priceHighest}`);
    }
    if (keywords && keywords !== "") {
      counter++;
      newFilter += filterTemplate(counter, `keyword=${keywords}`);
    }
    history.push(
      `/search/${dataType}/${newFilter ? newFilter.split("?")[1] : ""}`
    );
    setFilter(newFilter);
  }

  function searchBoxHandler(e) {
    setKeywords(e.target.value);
  }

  const courses = useSelector((state) => state.courses);
  const learningPaths = useSelector((state) => state.learning_paths);
  const users = useSelector((state) => state.users);
  const courseTypes = useSelector((state) => state.course_types);
  const dispatch = useDispatch();
  let coursesData = courses.coursesData;
  let usersData = users.usersData;
  let courseTypesData = courseTypes.courseTypesData;
  let learningPathsData = learningPaths.learningPathsData;

  return (
    <StyledWrapperMain>
      <Container className="dashboard_page__container search">
        <Row>
          <Col sm={12} md={12}>
            <div className="">
              <SearchBoxMain
                titleMain="Pelatihan"
                searchBtnHandler={searchBtnHandler}
                dataType={dataType}
                dataTypeHandler={dataTypeHandler}
                searchBoxHandler={searchBoxHandler}
                keywords={keywords}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-none d-sm-block" md={3}>
            {dataType === "courses" ? (
              <FilterCard
                usersData={usersData}
                courseTypesData={courseTypesData}
                setInstructorFilter={setInstructorFilter}
                setCourseTypesFilter={setCourseTypesFilter}
                instructorFilter={instructorFilter}
                courseTypesFilter={courseTypesFilter}
                priceLowest={priceLowest}
                priceHighest={priceHighest}
                searchBtnHandler={searchBtnHandler}
                priceChangeHandler={priceChangeHandler}
              />
            ) : null}
          </Col>
          {/* <Col lg={2}></Col> */}

          <Col xs={9} sm={9} md={9} lg={9} className="mb-3 align-items-center">
            <Row>
              <Col xs={9} lg={9}>
                <h3 className="h3 subtitle_course__info">
                  {coursesData.items ? coursesData.items.length : "0"} Pelatihan
                  Ditemukan
                </h3>
              </Col>
              <Col xs={3} className="d-md-none">
                <Accordion className="mb-4 text-center">
                  <Accordion.Toggle
                    className="show_filter__accordion btn btn-info"
                    eventKey="0"
                    onClick={handleShow}
                  >
                    Filter
                  </Accordion.Toggle>
                </Accordion>
              </Col>
              {show ? (
                <Col xs={12}>
                  <Accordion.Collapse className="show mb-4" eventKey="0">
                    <FilterCard
                      usersData={usersData}
                      courseTypesData={courseTypesData}
                      setInstructorFilter={setInstructorFilter}
                      setCourseTypesFilter={setCourseTypesFilter}
                      instructorFilter={instructorFilter}
                      courseTypesFilter={courseTypesFilter}
                      priceLowest={priceLowest}
                      priceHighest={priceHighest}
                      priceChangeHandler={priceChangeHandler}
                    />
                  </Accordion.Collapse>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <div className="course-result__container">
              {dataType === "courses"
                ? coursesData?.items &&
                  coursesData.items.map((item, i) => {
                    return (
                      <CourseCard
                        key={i}
                        selectedValue={courseTypesFilter}
                        item={item}
                        id={item.course_id}
                      />
                    );
                  })
                : null}
            </div>
            <Row>
              {dataType === "learning_paths"
                ? learningPathsData.items
                  ? learningPathsData.items.map((item, i) => {
                      return (
                        <Col
                          key={i}
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={4}
                          className="mb-3"
                          style={{ cursor: "pointer" }}
                        >
                          <LearningPathCard item={item} />
                        </Col>
                      );
                    })
                  : null
                : null}
            </Row>
          </Col>
        </Row>
      </Container>
    </StyledWrapperMain>
  );
}

export default CourseSearch;

function filterTemplate(counter, data) {
  return counter <= 0 ? data : counter === 1 ? `?${data}` : `&${data}`;
}
function capitalize(s) {
  return s[0].toUpperCase() + s.slice(1);
}

function getCurrentState(str) {
  const splitted = str.split(/=|&/);
  let newData = {};
  for (let i = 0; i < (splitted.length - 1) / 2; i++) {
    newData[splitted[i * 2]] = splitted[i * 2 + 1];
  }
  return newData;
}

const StyledWrapperMain = styled.section`
  .form-check-label {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
`;
