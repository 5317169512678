import React, { useState } from "react";
import Upload from "rc-upload";
import { postAssetsData } from "../redux/actions/AssetsActions";
import { useDispatch } from "react-redux";
import { Button, Form, Card, Row, Col } from "react-bootstrap";
import "../assets/css/Account/fileuploader.css";
import { Capitalize } from "../helper/functions";

function MediaUploader() {
  const [fileInfo, setFileInfo] = useState();
  const [allItems, setAllItems] = useState();
  const [bucket, setBucket] = useState("assignments");
  const [payload, setPayload] = useState({
    url: "?bucket=assignments",
    body: "",
  });

  const dispatch = useDispatch();
  // const assets = useSelector((state) => state.assets);
  // const assetsDataPost = assets.assetsDataPost;
  const formData = new FormData();

  function handleChange(e) {
    let obj = payload;
    obj.url = `?bucket=${e.target.value}`;
    setPayload(obj);
    setBucket(e.target.value);
  }
  function handleUpload() {
    if (payload.body !== "" && payload.url !== "")
      dispatch(postAssetsData(payload));
  }

  const props = {
    action: (file) => {
      return new Promise((resolve) => {
        setFileInfo(file);
        let obj = payload;
        formData.append("upfile", file);
        obj.body = formData;
        setPayload(obj);
      });
    },
    multiple: false,
    onStart(file) {
      console.log("onStart", file.name);
    },
    onSuccess(ret) {
      console.log("onSuccess", ret);
    },
    onError(err) {
      console.log("onError", err);
    },
    beforeUpload(item, all) {
      setAllItems(all);
    },
  };
  console.log(allItems, "<<<<<<<<<<<<<<<");
  return (
    <Row className="height-inherit justify-content-center align-content-center">
      <Col xs={12}>
        <Card className="card_container py-5">
          <div className="card_inside__padding">
            <div className="title_upload">Unggah Berkas Anda</div>
            <div className="subtitle_upload">Pilih File untuk di unggah</div>
            <Upload {...props} style={{ width: "100%" }}>
              <Button className="file-upload-btn py-3 mt-5 mb-4 btn-primary">
                {fileInfo
                  ? allItems.length > 1
                    ? `${allItems.length} berkas terpilih`
                    : fileInfo.name
                  : "Pilih Berkas Kamu"}
              </Button>
            </Upload>
            <Form>
              <Form.Group className="d-flex align-items-center justify-content-between">
                <Form.Label className="mb-0 label-font">
                  Pilih Pelajaran
                </Form.Label>
                <Form.Control
                  required
                  as="select"
                  type="select"
                  name="bucket"
                  id="bucket"
                  value={bucket}
                  onChange={handleChange}
                >
                  <option hidden value key="blankChoice">
                    Pilih Bucket...
                  </option>
                  {["assignments", "profile_pictures"].map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {Capitalize(item.replace(/_/g, " "))}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
              <div className="d-flex justify-content-end">
                <Button
                  variant="success"
                  className="file-upload-btn my-2 px-3 py-3 w-25"
                  onClick={handleUpload}
                >
                  Unggah
                </Button>
              </div>
            </Form>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
export default MediaUploader;
