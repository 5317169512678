import React from "react";
function Sertificate() {
  return (
    <>
      <h2 className="course_front__kurikulum_title h2">
        Bagian dari Sertifikasi:
      </h2>

      <div className="course_front__sertifikasi__wrapper">
        <div className="course_front__sertifikasi__item">
          <div className="course_front__sertifikasi__img__wrapper"></div>
          <div className="course_front__sertifikasi___course">
            Sertifikat Kompetisi Perpajakan
          </div>
        </div>
      </div>
    </>
  );
}
export default Sertificate;
