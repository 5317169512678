import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FaFileAlt } from "react-icons/fa";
import "../assets/css/Courses/RelatedFileSection.css";

function RelatedMaterialSection({ handouts }) {
  return (
    <div className="related_material__container">
      <h2 className="related_material__title h2">Modul</h2>
      <div className="related_file__wrapper">
        <Row>
          {handouts?.length > 0 ? (
            handouts?.map((items, index) => (
              <Col key={index}>
                <div className="related_file__item_wrapper">
                  <Button
                    href={items}
                    target="_blank"
                    className="related_file__icon_wrapper"
                  >
                    <FaFileAlt className="related_file__icon" />
                  </Button>
                  <div className="related_file__filename">{`Modul ${
                    index + 1
                  }`}</div>
                </div>
              </Col>
            ))
          ) : (
            <Col>Data Tidak di Temukan</Col>
          )}
        </Row>
      </div>
    </div>
  );
}
export default RelatedMaterialSection;
