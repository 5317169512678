import React from "react";
function CardDesc({ description }) {
  return (
    <div className="course_front__deskripsi__wrapper">
      <h2 className="course_front__inner_title h2">Deskripsi</h2>
      <div className="course_front__deskripsi">{description}</div>
    </div>
  );
}
export default CardDesc;
