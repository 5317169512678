import React from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../assets/css/Courses/PrerequisitesCourse.css";

function PrerequisitesCourse({ prereq }) {
  return (
    <>
      <h2 className="course_front__kurikulum_title h2">Prasyarat Pelatihan</h2>
      <div className="course_front__prereq__container">
        <Alert key="0" variant="warning" className="mb-4">
          <div className=" d-flex">
            <RiErrorWarningFill className="course_front__prereq__warning_icon mr-2" />
            Harap dicatat bahwa pelatihan ini memiliki prasyarat berikut yang
            harus diselesaikan sebelum dapat diakses
          </div>
        </Alert>
        <div>
          {prereq &&
            prereq.map((items, index) => (
              <Link
                key={index}
                to={`/course/${items.course_id}`}
                className="course_front__prerequisite_item"
              >
                <div className="course_front__prerequisite_img__wrapper"></div>
                <div className="course_front__prerequisite__course">
                  {items.course}
                </div>
              </Link>
            ))}
          {/* {[...Array(3)].map((item, i) => {
            return (
              <div className="course_front__prerequisite_item">
                <div className="course_front__prerequisite_img__wrapper">

                </div>
                <div className="course_front__prerequisite__course">
                  Sertifikat Kompetisi Perpajakan
                </div>
              </div>
            )
          })} */}
        </div>
      </div>
    </>
  );
}
export default PrerequisitesCourse;
