import React from "react";
import { Row, Card, Button } from "react-bootstrap";
import { FaBookOpen, FaClock, FaPlay, FaInfoCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import ProgressCourse from "./ProgressCourse";

function MyProgressCourse({ progress, status, item, id }) {
  return (
    
    <Card className="cart_container mb-4">
      <div className="card_wrapper__info pb-0">
        <div className="card_content__wrapper">
          <div className="card_content__image">
            <img
              className="course_card__thumbnail "
              src={
                item?.thumbnail
                  ? item.thumbnail
                  : "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/4270648989474070.jpeg"
              }
              alt={
                item?.thumbnail
                  ? item.thumbnail
                  : "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/4270648989474070.jpeg"
              }
              // width="255px"
            />
          </div>
          <div className="card_content__title">
            <div className="card_content__status">{item?.status}</div>
            <div className="card_content__title__main">{item?.course}</div>
            <div className="card_content__title__sub">
              <div className="card_content__course mr-4  text-muted">
                <FaBookOpen className="mr-2" />
                {item ? item.total_materials : 0} Pelatihan
              </div>
              <div className="card_content__time  text-muted">
                <FaClock className="mr-2" /> 03j 45m 32s
              </div>
            </div>
            <div>
              <div className="card_content__course mr-4  text-muted">
                Progress
              </div>
              <Row className="align-items-center g-0 p-0">
                <ProgressCourse now={item?.progress} />
              </Row>
            </div>
          </div>
        </div>
        <div className="card_button">
          <Link
            to={`/course/${item?.course_id}`}
            className="py-2 mb-2 btn btn-primary"
          >
            <FaPlay className="fill-white" />
          </Link>
          <Link to="/#" className="py-2 mb-2 btn btn-primary">
            <FaInfoCircle className="fill-white"/>
          </Link>
        </div>
      </div>
    </Card>
  );
}

export default MyProgressCourse;
