import React from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { FaSearch, FaBox } from "react-icons/fa";

function SearchBoxInside({
  searchBtnHandler,
  dataType,
  dataTypeHandler,
  searchBoxHandler,
  keywords,
}) {
  return (
    <div className="search_box__filter_wrapper">
      <Form>
        <Row className="align-items-center">
          <Col xs={12} sm={5} md={5}>
            <FaSearch className="icon-post" />
            <Form.Control
              className="pl-5 py-0 style1-input border-search search-course__title"
              type="text"
              placeholder="Cari Pelatihan"
              value={keywords}
              onChange={searchBoxHandler}
            />
          </Col>
          <Col xs={12} sm={4} md={4}>
            <FaBox className="icon-post" />
            <Form.Control
              className="pl-5 py-0 style1-input border-search search-course__title"
              as="select"
              value={dataType}
              onChange={dataTypeHandler}
            >
              <option className="pl-1" value="courses">
                Pelatihan
              </option>
              <option className="pl-1" value="courses">
                Webinar
              </option>
              <option className="pl-1" value="courses">
                Sertifikasi
              </option>
              <option className="pl-1" value="courses">
                Try Out
              </option>
              <option className="pl-1" value="courses">
                Tutorial
              </option>
              <option className="pl-1" value="courses">
                Studi Kasus
              </option>
              <option className="pl-1" value="courses">
                Pembahasan Soal
              </option>
              <option className="pl-1" value="learning_paths">
                Learning Paths
              </option>
            </Form.Control>
          </Col>
          <Col xs={12} sm={3} md={3}>
            <Button
              onClick={searchBtnHandler}
              className="d-flex align-items-center justify-content-center search-box__inside width-full text-uppercase search_box__btn_search"
            >
              Cari
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default SearchBoxInside;
