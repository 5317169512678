import React, { useEffect } from "react";
import TimelineCalendar from "../../components/TimelineCalendar";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
function MyCalendar() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <div>
      <TimelineCalendar />
    </div>
  );
}

export default MyCalendar;
