import {
  //get
  COURSES_DATA,
  COURSES_DATA_SUCCESS,
  COURSES_DATA_FAIL,
  COURSES_DATA_ID_SUCCESS,
  //post enroll
  COURSES_DATA_ENROLL,
  COURSES_DATA_ENROLL_SUCCESS,
  COURSES_DATA_ENROLL_FAIL,
  //complete material
  COURSES_COMPLETE_MATERIAL,
  COURSES_COMPLETE_MATERIAL_SUCCESS,
  COURSES_COMPLETE_MATERIAL_FAIL,
  //complete course
  COURSES_COMPLETE_COURSE,
  COURSES_COMPLETE_COURSE_SUCCESS,
  COURSES_COMPLETE_COURSE_FAIL,
  //complete remove
  COURSES_REMOVE_COMPLETE_STATE,
} from "../config/CoursesConfig";

const intialState = {
  coursesData: [],
  coursesDataId: [],
  courseDataEnroll: null,
  completeMaterialData: "",
  completeCourseData: "",
  isLoading: false,
};

export const CoursesReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case COURSES_DATA:
      return { ...state, isLoading: true };
    case COURSES_DATA_SUCCESS:
      return { ...state, isLoading: false, coursesData: action.value };
    case COURSES_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, coursesDataId: action.value };
    case COURSES_DATA_FAIL:
      return { ...state, isLoading: false };
    //post
    case COURSES_DATA_ENROLL:
      return { ...state, isLoading: true };
    case COURSES_DATA_ENROLL_SUCCESS:
      return { ...state, isLoading: false, courseDataEnroll: "success" };
    case COURSES_DATA_ENROLL_FAIL:
      return { ...state, isLoading: false, courseDataEnroll: "fail" };
    //complete material
    case COURSES_COMPLETE_MATERIAL:
      return { ...state, isLoading: true };
    case COURSES_COMPLETE_MATERIAL_SUCCESS:
      return { ...state, isLoading: false, completeMaterialData: "success" };
    case COURSES_COMPLETE_MATERIAL_FAIL:
      return { ...state, isLoading: false, completeMaterialData: "fail" };
    //complete course
    case COURSES_COMPLETE_COURSE:
      return { ...state, isLoading: true };
    case COURSES_COMPLETE_COURSE_SUCCESS:
      return { ...state, isLoading: false, completeCourseData: "success" };
    case COURSES_COMPLETE_COURSE_FAIL:
      return { ...state, isLoading: false, completeCourseData: "fail" };
    case COURSES_REMOVE_COMPLETE_STATE:
      return { ...state, completeCourseData: "", completeMaterialData: "" };
    default:
      return state;
  }
};
