import React, { useState, useEffect } from "react";
import { Col, Card, Button, Accordion } from "react-bootstrap";
import moment from "moment";
import { useInterval } from "react-interval-hook";
function QuizDetails({ quizTime, setDoneQuiz, title, minimizevalue }) {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [days, setDays] = useState(0);
  const [mili, setMili] = useState(quizTime);
  useEffect(() => {
    setMili(moment.duration(quizTime).asMilliseconds());
  }, [quizTime]);
  const { start, stop, isActive } = useInterval(
    () => {
      if (mili <= 0) stop(true);
      const time = moment.utc(mili).format("DD:HH:mm:ss").split(":");
      const newMili = mili - 1000;
      setDays(time[0] - 1);
      setHours(time[1]);
      setMinutes(time[2]);
      setSeconds(time[3]);
      setMili(newMili);
    },
    1000,
    {
      onFinish: () => {
        setDoneQuiz(true);
      },
    }
  );
  // console.log(minimizevalue);
  // const handleMinimize = (minimizevalue) => {
  //   setMinimize(minimizevalue);
  // };
  return (
    <>
      {minimizevalue ? (
        ""
      ) : (
        <Col xs={12} lg={12} className="quiz-details-main-col ">
          <Accordion className="course_detail__quiz_wrapper mb-0">
            <Accordion.Toggle
              as={Button}
              variant="link"
              className="course_detail__quiz_wrapper__title mb-0"
              eventKey="0"
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="course_detail__quiz__title">
                  {title.toUpperCase()}
                </div>
                <span className="d-flex">
                  <Card.Text className="mb-0">{days}</Card.Text>
                  <Card.Text className="mb-0">Hari</Card.Text>

                  <Card.Text className="mb-0">{hours}</Card.Text>
                  <Card.Text className="mb-0">Jam</Card.Text>

                  <Card.Text className="mb-0">{minutes}</Card.Text>
                  <Card.Text className="mb-0">Menit</Card.Text>

                  <Card.Text className="mb-0">{seconds}</Card.Text>
                  <Card.Text className="mb-0">Detik</Card.Text>
                </span>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Text className="quiz-details-desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Card.Text>
              {/* <Row className="quiz-details-tag-row justify-content-center">
                  <Button
                    className="quiz-details-tag"
                    variant="secondary"
                    disabled
                  >
                    Tag1
                  </Button>
                  <Button className="quiz-details-tag" variant="dark" disabled>
                    Tag2
                  </Button>
                  <Button
                    className="quiz-details-tag"
                    variant="primary"
                    disabled
                  >
                    Tag3
                  </Button>
                </Row> */}
            </Accordion.Collapse>
          </Accordion>
        </Col>
      )}
    </>
  );
}
export default QuizDetails;

// function useInterval(callback, delay) {
//   const savedCallback = useRef();

//   // Remember the latest callback.
//   useEffect(() => {
//     savedCallback.current = callback;
//   }, [callback]);

//   // Set up the interval.
//   useEffect(() => {
//     let id = setInterval(() => {
//       savedCallback.current();
//     }, delay);
//     return () => clearInterval(id);
//   }, [delay]);
// }
