import Axios from "axios";
import { ENDPOINT } from "../helper/constant";

const timeout = 60000;

export const HTTP_SERVICE = {
  get(url, request) {
    return new Promise(async (resolve, reject) => {
      const auth = await JSON.parse(localStorage.getItem("auth"));
      let username = "";
      let password = "";
      if (auth) {
        username = auth.username;
        password = auth.password;
      }
      let a = Buffer.from(`${username}:${password}`).toString("base64");
      Axios(ENDPOINT + url, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + a,
        },
        timeout,
      }).then(
        (result) => {
          resolve(result);
        },
        function (error) {
          console.log("error HTTP SERVICE");
          reject(error);
        }
      );
    });
  },
  post(url, request, authType) {
    return new Promise(async (resolve, reject) => {
      let username = "";
      let password = "";
      if (authType) {
        username = request.username;
        password = request.password;
      } else {
        const auth = await JSON.parse(localStorage.getItem("auth"));
        if (auth) {
          username = auth.username;
          password = auth.password;
        }
      }
      let a = Buffer.from(`${username}:${password}`).toString("base64");
      //token
      Axios(ENDPOINT + url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + a,
        },
        data: JSON.stringify(request),
        timeout,
      }).then(
        (result) => {
          resolve(result);
        },
        function (error) {
          console.log("error HTTP SERVICE");
          reject(error);
        }
      );
    });
  },
  postFormData(url, request) {
    return new Promise(async (resolve, reject) => {
      const auth = await JSON.parse(localStorage.getItem("auth"));
      let username = "";
      let password = "";
      if (auth) {
        username = auth.username;
        password = auth.password;
      }
      let a = Buffer.from(`${username}:${password}`).toString("base64");
      //token
      Axios(ENDPOINT + url, {
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Basic " + a,
        },
        data: request,
        timeout,
      }).then(
        (result) => {
          resolve(result);
        },
        function (error) {
          console.log("error HTTP SERVICE");
          reject(error);
        }
      );
    });
  },
  put(url, request) {
    return new Promise(async (resolve, reject) => {
      const auth = await JSON.parse(localStorage.getItem("auth"));
      let username = "";
      let password = "";
      if (auth) {
        username = auth.username;
        password = auth.password;
      }
      let a = Buffer.from(`${username}:${password}`).toString("base64");
      //token
      Axios(ENDPOINT + url, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + a,
        },
        data: JSON.stringify(request),
        timeout,
      }).then(
        (result) => {
          resolve(result);
        },
        function (error) {
          console.log("error HTTP SERVICE");
          reject(error);
        }
      );
    });
  },
};
