import React, { useEffect, useState } from "react";
import { Row, Container, Tabs, Tab } from "react-bootstrap";
import CourseCard from "../../components/CourseCard";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Personal/myCourse.css";
import { useSelector } from "react-redux";

function MyBookmark() {
  const history = useHistory();

  useEffect(() => {
    AuthIsLogin(history);
  }, []);

  return (
    <Container className="my_course__page">
      <h2 className="h2 my_course__section_title mb-5">Wishlist Saya</h2>
      <Row>
        {[...Array(5)].map((item, i) => {
          return <CourseCard />;
        })}
      </Row>
    </Container>
  );
}

export default MyBookmark;
