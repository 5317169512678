import React, { useState, useEffect } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";

import { useSelector } from "react-redux";
import {
  FaUserAlt,
  FaBookOpen,
  FaRegCalendarAlt,
  FaListOl,
  FaClipboardList,
  FaShoppingCart,
  FaCircleNotch,
  FaLayerGroup,
  FaIdCard,
  FaInfo,
  FaShoppingBasket,
  FaRocketchat,
  FaBookmark,
} from "react-icons/fa";
// import sidebarBg from "../../assets/images/bg1.jpg";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
// import logo from "../../assets/images/logo-satukelas.jpeg";

import "../../assets/css/Layout/aside.css";

const Aside = ({ image, collapsed, rtl, toggled, handleToggleSidebar }) => {
  const [name, setName] = useState("");
  let address = useLocation();
  let splitted = address.pathname.split("/");
  const users = useSelector((state) => state.users);
  useEffect(() => {
    const nameUser = users.usersDataId.name;
    setName(nameUser);
  }, [users]);
  console.log(name);
  return (
    <StyledWrapperMain>
      <ProSidebar
        rtl={rtl}
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="lg"
        onToggle={handleToggleSidebar}
        className="satukelas_sidebar__container"
      >
        {collapsed ? (
          ""
        ) : (
          <SidebarHeader>
            <div className="greeting_msg">Halo, {name}</div>
          </SidebarHeader>
        )}

        <SidebarContent className="my-3">
          <Menu iconShape={false}>
            <SubMenu title={"Dashboard"} icon={<FaUserAlt />}>
              {/* <b></b> */}
              {/* <MenuItem
                id="overview"
                className={splitted[2] === "" ? "active" : ""}
              >
                <b></b>
                <b></b>
                <FaInfoCircle />
                Overview
                <Link to="/dashboard/" />
              </MenuItem> */}
              <MenuItem
                id="course"
                className={splitted[2] === "course" ? "active" : ""}
              >
                <b></b>
                <b></b>
                <FaBookOpen />
                Pelatihan Saya
                <Link to="/dashboard/course/" />
              </MenuItem>
              <MenuItem
                id="path"
                className={splitted[2] === "path" ? "active" : ""}
              >
                <b></b>
                <b></b>
                <FaLayerGroup />
                Learning Path Saya
                <Link to="/dashboard/path/" />
              </MenuItem>
              <MenuItem
                id="calendar"
                className={splitted[2] === "calendar" ? "active" : ""}
              >
                <b></b>
                <b></b>
                <FaRegCalendarAlt />
                Kalender Saya
                <Link to="/dashboard/calendar/" />
              </MenuItem>
              <MenuItem
                id="progress"
                className={splitted[2] === "progress" ? "active" : ""}
              >
                <b></b>
                <b></b>
                <FaCircleNotch />
                Progress Saya
                <Link to="/dashboard/progress/" />
              </MenuItem>
              <MenuItem
                id="certificate"
                className={splitted[2] === "certificate" ? "active" : ""}
              >
                <b></b>
                <b></b>
                <FaClipboardList />
                Sertifikat Saya
                <Link to="/dashboard/certificate/" />
              </MenuItem>
              <MenuItem
                id="task"
                className={splitted[2] === "task" ? "active" : ""}
              >
                <b></b>
                <b></b>
                <FaListOl />
                Tugas Saya
                <Link to="/dashboard/task" />
              </MenuItem>{" "}
              <MenuItem
                id="bookmark"
                className={splitted[2] === "my-bookmark" ? "active" : ""}
              >
                <b></b>
                <b></b>
                <FaBookmark />
                Wishlist Saya
                <Link to="/dashboard/my-bookmark/" />
              </MenuItem>
              <MenuItem
                id="chat"
                className={splitted[2] === "chat" ? "active" : ""}
              >
                <b></b>
                <b></b>
                <FaRocketchat />
                Chat
                <Link to="/dashboard/chat/" />
              </MenuItem>
              {/* <MenuItem
                id="leaderboard"
                className={splitted[2] === "leaderboard" ? "active" : ""}
              >
                <b></b>
                <b></b>
                <FaMedal />
                Leaderboard
                <Link to="/dashboard/leaderboard/" />
              </MenuItem> */}
              {/* <MenuItem
                id="point"
                className={splitted[2] === "point" ? "active" : ""}
              >
                <b></b>
                <b></b>
                <FaClipboardList />
                Poin Saya
                <Link to="/dashboard/point/" />
              </MenuItem> */}
              {/* <MenuItem>
                <FaBook />
                Kumpulan Peraturan Perundang-Undangan
                <Link to="/peraturan/" />
              </MenuItem> */}
            </SubMenu>
            <MenuItem
              id="membership"
              className={splitted[2] === "membership" ? "active" : ""}
              icon={<FaIdCard />}
            >
              Membership
              <Link to="/dashboard/membership/" />
            </MenuItem>
            <MenuItem
              id="courses"
              className={splitted[2] === "courses" ? "active" : ""}
              icon={<FaBookOpen />}
            >
              Pelatihan
              <Link to="/search/courses/" />
            </MenuItem>
            <SubMenu title={"Belanja"} icon={<FaShoppingBasket />}>
              <MenuItem icon={<FaShoppingCart />}>
                Keranjang
                <Link to="/cart/" />
              </MenuItem>
              <MenuItem icon={<FaInfo />}>
                Riwayat Transaksi
                <Link to="/dashboard/orders/" />
              </MenuItem>
            </SubMenu>
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </StyledWrapperMain>
  );
};

export default Aside;

const StyledWrapperMain = styled.section`
  .pro-sidebar-inner {
    background: #ffffff;
  }
  .pro-sidebar {
    color: #000;
    font-weight: bold;
  }
  svg {
    fill: #677ca7 !important;
  }
  .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background-color: #fafafa;
  }
  .pro-sidebar {
    border-style: solid;
    border-width: 0px 1px 0px 0px;
    border-color: #00000011;
  }
`;
