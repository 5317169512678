import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import OverviewGraphRadar from "../../components/OverviewGraphRadar";
import LineGraphCard from "../../components/LineGraphCard";
import AchievementCard from "../../components/AchievementCard";
import LearningPathCard from "../../components/LearningPathCard";
import CourseCard from "../../components/CourseCard";
import DiscussionCard from "../../components/DiscussionCard";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";

function Overview() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container className="my-4">
      <div className="dashboard__title_wrapper mb-3">
        <h2 className="dashboard__section_title h2">Overview</h2>
      </div>
      <Row className="d-flex justify-content-center align-items-center">
        <Col md={6}>
          <OverviewGraphRadar />
        </Col>
        <Col md={6}>
          <LineGraphCard />
          <AchievementCard mar={"16px 0 16px 0"} pad={0} />
        </Col>
      </Row>
      <div>
        <div className="separator">Learning Paths</div>
        <Row>
          {[...Array(6)].map((value, index) => {
            return (
              <Col
                key={index}
                xs={1}
                sm={2}
                md={4}
                className="course-container mb-3"
              >
                <LearningPathCard key={index} />
              </Col>
            );
          })}
        </Row>
      </div>
      <div>
        <div className="separator">Courses</div>
        <Row
          xs={1}
          md={1}
          className="d-flex justify-content-center align-items-center mt-3"
        >
          <Row>
            {[...Array(4)].map((value, index) => {
              return <CourseCard key={index} />;
            })}
          </Row>
        </Row>
      </div>
      <div>
        <div className="separator mb-4">Discussions</div>
        <DiscussionCard />
      </div>
    </Container>
  );
}

export default Overview;
