import React from "react";
import ReactEChart from "echarts-for-react";
import { Row, Col, Card, Dropdown } from "react-bootstrap";
import "../assets/css/Personal/lineGraphCard.css";

function LineGraphCard() {
  return (
    <Card className="lgc-card">
      <Card.Body className="lgc-card-body">
        <Row className="lgc-head">
          <Col sm={1} md={2} className="pr-0 d-flex align-items-center">
            <Card.Title variant="primary" className="lgc-card-title">
              432
            </Card.Title>
          </Col>
          <Col className="align-items-center mb-1" md={3} sm={5}>
            <Card.Text variant="primary" className="lgc-card-text">
              Experience IQ
            </Card.Text>
            <Card.Text variant="primary" className="lgc-card-text">
              4 days streak this week
            </Card.Text>
          </Col>
          <Col className="d-flex align-items-center justify-content-end" md={7} xs={6}>
            <Dropdown>
              <Dropdown.Toggle variant="outline-dark" size="sm">
                Popular Topics
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Popular Topics</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Web Design</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <div className="lgc-graph-div">
          <ReactEChart option={option()} style={{ height: "100%" }} />
        </div>
      </Card.Body>
    </Card>
  );
}
export default LineGraphCard;

function option() {
  return {
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        tooltip: {
          trigger: "item",
        },
        data: [213, 74, 188, 132, 19, 186, 187],
        type: "line",
        smooth: true,
      },
    ],
  };
}
