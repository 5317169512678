import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BusinessFieldTable from "../../components/BusinessFieldTable";
import LegalFieldTable from "../../components/LegalFieldTable";
import CategoryRegulationList from "../../components/CategoryRegulationList";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Personal/peraturanPage.css";
function Peraturan() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container>
      <Row className="my-4">
        <Col xs={12} md={12}>
          <h2 className="h2 category-content">Kategori Dokumen</h2>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4}>
          <CategoryRegulationList />
        </Col>
        <Col xs={12} sm={12} md={8} lg={8}>
          <BusinessFieldTable />
          <LegalFieldTable />
        </Col>
      </Row>
    </Container>
  );
}

export default Peraturan;
