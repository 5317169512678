import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Tabs,
  Tab,
  Table,
} from "react-bootstrap";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/MyCompany/mycompanyprofil.css";
import CourseCard from "../../components/CourseCard";

function MyCompanyProfil() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container>
      <Row className="my-5">
        <Col md={9}>
          <div className="company-profil__container">
            <h2 className="h2 company-profil__title">Wajib Untuk di Ikuti</h2>
            <div className="company-prodil__mandatory-course">
              {[...Array(4)].map((item, i) => {
                return <CourseCard key={i} />;
              })}
            </div>
          </div>
          <div className="company-profil__wrapper-division">
            <h3 className="h3 company-profil__division-title">
              Divisi Perusahaan
            </h3>
            <div className="company-profil__table">
              <Tabs
                defaultActiveKey="divisi1"
                transition={false}
                id="noanim-tab-example"
              >
                <Tab
                  id="tab-unique"
                  tabClassName=""
                  eventKey="divisi1"
                  title="Keuangan"
                  className="mb-2 p-4 tab-style"
                >
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Nama</th>
                        <th>Email</th>
                        <th>Aktif Terakhir</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Mark</td>
                        <td>Otto</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Jacob</td>
                        <td>Thornton</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Larry the Bird</td>
                        <td>@twitter</td>
                      </tr>
                    </tbody>
                  </Table>
                </Tab>

                <Tab
                  id="tab-unique"
                  tabClassName="text-blacks"
                  eventKey="divisi2"
                  title="Pariwisata"
                  className="mb-2 p-4 tab-style"
                >
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Username</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Mark</td>
                        <td>Otto</td>
                        <td>@mdo</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td colSpan="2">Larry the Bird</td>
                        <td>@twitter</td>
                      </tr>
                    </tbody>
                  </Table>
                </Tab>
              </Tabs>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div className="company-profil__container">
            <div className="company-profil__wrapper-company">
              <div className="company-profil__image-profil"></div>
              <div className="company-profil__company-name">
                PT. Maju Jaya Bersama
              </div>
            </div>
            <Card className="company-profil__membership-card">
              <div className="company-profil__membership-title">Membership</div>
              <div className="company-profil__membership-wrapper__list">
                <div className="company-profil__membership-image"></div>
                <div className="company-profil__membership-info__wrapper">
                  <div className="company-profil__membership-title__detail">
                    Paket 1 Tahun{" "}
                    <span className="company-profil__membership-badge">
                      Aktif
                    </span>
                  </div>
                  <div className="company-profil__membership-subtitle__detail">
                    Hingga 23 Januari 2021
                  </div>
                </div>
              </div>
            </Card>
            <div className="company-profil__wrapper-leaderboard">
              <div className="company-profil__wrapper-title">
                <div className="company-profil__container-leaderboard">
                  <div className="company-profil__leaderboard-title">
                    Leaderboard
                  </div>
                  <div className="company-profil__leaderboard-subtitle">
                    Waktu Aktif Terbanyak
                  </div>
                </div>
                <Button className="company-profil__filter-button">
                  Filter
                </Button>
              </div>
              {[...Array(5)].map((item, i) => {
                return (
                  <Card
                    key={i}
                    className="company-profil__leaderboard-container"
                  >
                    <div className="company-profil__wrapper-leaderboard-list">
                      <div className="company-profil__list-number">{i + 1}</div>
                      <div className="company-profil__list-wrap">
                        <div className="company-profil__name-list">
                          Muhammad Fitrah
                        </div>
                        <div className="company-profil__subtitle-list">
                          02j 04m 21d
                        </div>
                      </div>
                    </div>
                  </Card>
                );
              })}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default MyCompanyProfil;
